import React, {useEffect, useState} from 'react'
import styles from './DashboardPage.module.scss'
import DashboardToolbar from '../DashboardToolbar/DashboardToolbar'
import DashboardBody from '../DashboardBody/DashboardBody'
import {useDashboardStore} from '../../../app/store/DashboardStore'
import CreateWidgetPage from '../CreateWidgetPage/CreateWidgetPage'

const DashboardPage = () => {
    const {
        dashboardViews,
        fetchViews,
        editingDashboardWidget
    } = useDashboardStore((state) => state)

    const [currentView, setCurrentView] = useState(dashboardViews[0] || null)

    useEffect(() => {
        fetchViews()
    }, []);

    useEffect(() => {
        if (dashboardViews.length > 0 && !currentView) {
            setCurrentView(dashboardViews[0])
        }
        if (dashboardViews.length > 0 && currentView) {
            const updatedView = dashboardViews.find(view => view.id === currentView.id)
            if (updatedView) {
                setCurrentView(updatedView)
            }
        }
    }, [dashboardViews])

    return (
        <>
            {!editingDashboardWidget && <div className={styles.wrapper}>
                <DashboardToolbar
                    currentView={currentView}
                    dashboardViews={dashboardViews}
                    onChangeCurrentView={(newView)=>{setCurrentView(newView)}}/>
                <DashboardBody
                    currentView={currentView}/>
            </div>}
            {editingDashboardWidget && <CreateWidgetPage currentView={currentView}/>}
        </>
    )
}

export default DashboardPage
