import React, {useState} from 'react'
import styles from './DashboardToolbar.module.scss'
import PlusIcon from '../../../shared/assets/icons/Plus'
import {Button, IconButton, Menu, MenuItem, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {clsx} from 'clsx'
import MoreIcon from '../../../shared/assets/icons/More'
import PenIcon from '../../../shared/assets/icons/Pen'
import TrashIcon from '../../../shared/assets/icons/Trash'
import AgreeModal from '../../../shared/ui/AgreeModal'
import {useDashboardStore} from '../../../app/store/DashboardStore'
import CreateViewModal from '../CreateViewModal/CreateViewModal'
import CopyIcon from "../../../shared/assets/icons/Copy";

const DashboardToolbar = ({currentView, dashboardViews, onChangeCurrentView}) => {
    const {
        editingDashboardView,
        toggleDuplicationView,
        toggleEditingDashboardView,
        toggleEditingDashboardWidget,
        deleteDashboardView,
    } = useDashboardStore((state) => state)

    const [anchorEl, setAnchorEl] = useState(null)
    const [menuView, setMenuView] = useState(null)
    const isOpen = Boolean(anchorEl)

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(null)

    const handleClick = (event, view) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setMenuView(view)
    }

    const handleClose = () => (event) => {
        event.stopPropagation()
        setAnchorEl(null)
        setMenuView(null)
    }

    const handleDeleteDashboardView = (view) => {
        setIsOpenDeleteModal(view)
    }

    const deleteView = (agree) => {
        if (agree) {
            deleteDashboardView(isOpenDeleteModal.id)
            onChangeCurrentView(null)
        }
        setIsOpenDeleteModal(null)
        setAnchorEl(null)
    }

    const handleAddWidget = () => {
        toggleEditingDashboardWidget({})
    }

    const handleCreateView = () => {
        toggleEditingDashboardView({})
    }

    const handleEditView = (viewInfo) =>{
        toggleDuplicationView(false)
        toggleEditingDashboardView(viewInfo)
    }

    const handleCopyView = (viewInfo) => {
        toggleDuplicationView(true)
        toggleEditingDashboardView(viewInfo)
    }

    const handleClickView = (view) => {
        onChangeCurrentView(view)
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.upperString}>
                    <h3 className={styles.title}>
                        Dashboard
                    </h3>

                    <Button
                        startIcon={<PlusIcon/>}
                        onClick={handleAddWidget}
                        disabled={!currentView}
                    >
                        New widget
                    </Button>
                </div>

                <div className={styles.lowerString}>
                    <Button
                        startIcon={<PlusIcon size={'small'}/>}
                        className={styles.addViewButton}
                        variant={'text'}
                        color={'primary'}
                        onClick={handleCreateView}
                    >
                        Add view
                    </Button>

                    <div className={styles.scrollContainer}>
                        <ToggleButtonGroup className={styles.menuWrapper} value={currentView} exclusive size="small">
                            {dashboardViews
                                .sort((a, b) => a.id - b.id)
                                .map((view) => (
                                <div key={view.id}>
                                    <ToggleButton
                                        className={clsx(styles.menuItem, {
                                            [styles.menuItemSelected]: currentView?.name === view.name,
                                        })}
                                        value={view}
                                        size="small"
                                        onClick={() => handleClickView(view)}
                                    >
                                        {view.name}
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleClick(e, view)}
                                        >
                                            <MoreIcon size="small"/>
                                        </IconButton>
                                    </ToggleButton>
                                    <Menu
                                        id={`menu-${view.id}`}
                                        anchorEl={anchorEl}
                                        open={isOpen && menuView?.id === view.id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onClose={handleClose('')}
                                        MenuListProps={{"aria-labelledby": "basic-button"}}
                                    >
                                        <MenuItem onClick={() => handleEditView(view)} key="edit">
                                            <PenIcon size="small"/>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => handleCopyView(view)} key="copy">
                                            <CopyIcon size="small"/>
                                            Copy
                                        </MenuItem>
                                        <MenuItem onClick={() => handleDeleteDashboardView(view)} key="delete"
                                                  color="error">
                                            <TrashIcon size="small"/>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </div>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>

            {isOpenDeleteModal && <AgreeModal
                data={{
                    message: <>
                        Are you sure you want to delete
                        {' '}
                        <span className={styles.deleteTitle}>
                            {isOpenDeleteModal.name}
                        </span>{' '}view?
                    </>,
                    title: 'Delete view',
                    agreeMsg: 'Delete',
                    mode: 'deleting',
                    agreeFunc: deleteView,
                }}
            />}

            {editingDashboardView && <CreateViewModal saveFunc={() => {
                setAnchorEl(null)
                toggleEditingDashboardView(null)
            }} editingDashboardView={editingDashboardView}/>}
        </>
    )
}

export default DashboardToolbar
