import React, {useState} from 'react'
import {Box, Button, Dialog, IconButton} from '@mui/material'
import styles from './CreateViewModal.module.scss'
import CloseIcon from '../../../shared/assets/icons/Close'
import TextField from '@mui/material/TextField'
import {clsx} from 'clsx'
import {useDashboardStore} from "../../../app/store/DashboardStore";

const CreateViewModal = ({saveFunc, editingDashboardView}) => {

    const {
        isDuplicatingView,
        createView,
        editView,
    } = useDashboardStore((state) => state)

    const getInitialTitle = () => {
        if (!editingDashboardView?.name) return ""
        return isDuplicatingView ? `${editingDashboardView.name} copy` : editingDashboardView.name
    }

    const [title, setTitle] = useState(getInitialTitle)

    const handleChangeTitle = (e) =>{
        setTitle(e.target.value)
    }

    const handleSave = () => {
        saveFunc()

        if(!editingDashboardView) return

        if(isDuplicatingView){
            createView({...editingDashboardView, name: title })
        }
        else {
            if(editingDashboardView.id){
                editView(editingDashboardView.id, title)
            }
            else{
                createView({name: title})
            }
        }
    }

    return (
        <Dialog
            className='timeline-modal'
            open={true}
            onClose={() => {
                saveFunc(false)
            }}
        >
            <Box className={styles.containerWrapper}>
                <Box className={styles.headerWrapper}>
                    <Box className={styles.headerTitle}>
                        {(isDuplicatingView || !editingDashboardView.id) ? "Add" : "Edit"} view
                    </Box>
                    <IconButton
                        variant={'outlined'}
                        onClick={() => {
                            saveFunc(false)
                        }}
                        sx={{marginLeft: 'auto', padding: '4px'}}
                    >
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </Box>
                <Box className={styles.contentWrapper}>
                    <Box
                        className='modal-section-name'
                        sx={{width: '100%'}}
                    >
                        Title
                    </Box>
                    <TextField
                        style={{width: '100%'}}
                        placeholder='Please enter title'
                        value={title}
                        onChange={handleChangeTitle}
                    />
                </Box>
                <Box className='row'>
                    <Button
                        fullWidth
                        className={clsx('spacer', styles.button)}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={() => {
                            saveFunc(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        fullWidth
                        className={clsx('spacer', styles.button)}
                        onClick={handleSave}
                        disabled={title.length < 1}
                    >
                        Save view
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default CreateViewModal
