import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IconButton} from "@mui/material";
import {useMapStore} from "../../../../app/store/MapStore";
import RulerIcon from "../../../../shared/assets/icons/RulerIcon";
import maplibregl from "maplibre-gl";
import './DistanceTool.scss'
import * as turf from "@turf/turf";

export default function DistanceTool({
                                         activeTool, setActiveTool = () => {
    }
                                     }) {
    const map = useMapStore(state => state.map)

    const [isActive, setIsActive] = useState(false);
    const isActiveRef = useRef(false);
    const markers = useRef([])


    useEffect(() => {
        if (isActive) {
            setActiveTool()
            map?.on?.('click', handleClickOnMap)
        } else {
            map?.off?.('click', handleClickOnMap)
        }
        if (!isActive) {
            clearAll()
        }
    }, [isActive]);

    useEffect(() => {
        if (activeTool !== "DistanceTool") {
            setIsActive(false)
            clearAll()
        }
    }, [activeTool]);

    const handleClickOnButton = () => {
        setIsActive(prevState => {
            const newIsActive = !prevState
            isActiveRef.current = newIsActive

            return newIsActive
        });
        setActiveTool()
    };

    const handleClickOnMap = useCallback((event = null) => {
        if (!isActiveRef.current) {
            return
        }

        const coordinates = getCoordinates(event);
        createPoint(coordinates);
    }, [map])

    const getCoordinates = (clickEvent) => {
        return clickEvent ? clickEvent.lngLat : map.getCenter();
    }

    const createPoint = (coordinates) => {
        if (map.getSource('line')) {
            removeLine();
        }

        if (map.getSource('circle')) {
            removeCircle();
        }

        if (markers.current.length > 1) {
            removeMarkers();
        }

        let draggable = false;
        if (markers.current.length === 1) {
            draggable = true;
        }

        const marker = new maplibregl.Marker({draggable: draggable})
            .setLngLat(coordinates)
            .addTo(map);
        marker.getElement().classList.add('customMarker');
        marker.getElement().setAttribute('data-cy', 'distance-tool-point')

        const point = document.createElement('div');
        point.className = 'point';

        marker.getElement().appendChild(point);
        markers.current.push(marker);
        const point1 = turf.point([markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat]);
        if (markers.current.length > 1) {
            const point2 = turf.point([markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]);
            markers.current[0].setDraggable(false);
            const newDistance = turf.distance(point1, point2, {units: 'miles'}).toFixed(1) + " mi";
            createLine(newDistance);
            createCircle();
        }

        marker.on('drag', () => {
            updateLineAndDistance();
            updateCircle();
        });
    }

    const createLine = (newDistance) => {
        if (markers.current.length === 2) {
            const coordinates = [
                [markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat],
                [markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]
            ];

            map.addSource('line', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: coordinates
                    }
                }
            });

            map.addLayer({
                id: 'line',
                type: 'line',
                source: 'line',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#09638C',
                    'line-width': 5
                }
            });

            map.addLayer({
                id: 'line-label',
                type: 'symbol',
                source: 'line',
                layout: {
                    'text-field': newDistance,
                    'text-font': ['Roboto Bold'],
                    'text-size': 25,
                    'text-offset': [2.5, -1.5],
                    'text-anchor': 'top',
                },
                paint: {
                    'text-color': 'white',
                    'text-halo-color': "#09638C",
                    'text-halo-width': 2
                }
            });
        }
    }

    const createCircle = () => {
        if (markers.current.length === 2) {
            const coordinates = [
                [markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat],
                [markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]
            ];

            const center = turf.point(coordinates[0]);
            const circleRadiusInMiles = turf.distance(turf.point(coordinates[0]), turf.point(coordinates[1]), {units: 'miles'});
            const circle = turf.circle(center, circleRadiusInMiles, {steps: 256, units: 'miles'});

            map.addSource('circle', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: circle.geometry,
                }
            });

            map.addLayer({
                id: 'circle',
                type: 'fill',
                source: 'circle',
                paint: {
                    'fill-color': '#09638C',
                    'fill-opacity': 0.15
                }
            });

            map.addLayer({
                id: 'outline-circle',
                type: 'line',
                source: 'circle',
                paint: {
                    'line-color': '#09638C',
                    'line-width': 2
                }
            });
        }
    }

    const updateLineAndDistance = () => {
        if (markers.current.length < 2) return
        const point1 = turf.point([markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat]);
        const point2 = turf.point([markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]);
        const newDistance = turf.distance(point1, point2, {units: 'miles'}).toFixed(1) + " mi";

        map.getSource('line').setData({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: [
                    [markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat],
                    [markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]
                ]
            }
        });

        map.setLayoutProperty('line-label', 'text-field', newDistance);
    }

    const updateCircle = () => {
        if (markers.current.length === 2) {
            const newCoordinates = [
                [markers.current[0]._lngLat.lng, markers.current[0]._lngLat.lat],
                [markers.current[1]._lngLat.lng, markers.current[1]._lngLat.lat]
            ];

            const newCenter = turf.point(newCoordinates[0]);
            const newCircleRadiusInMiles = turf.distance(turf.point(newCoordinates[0]), turf.point(newCoordinates[1]), {units: 'miles'});
            const newCircle = turf.circle(newCenter, newCircleRadiusInMiles, {steps: 256, units: 'miles'});

            map.getSource('circle').setData({
                type: 'Feature',
                geometry: newCircle.geometry,
            });
        }
    }

    const removeMarkers = () => {
        markers.current.forEach(marker => {
            marker.remove();
        });
        markers.current = []
    }

    const removeLine = () => {
        if (!map) {
            return
        }

        const lineSource = map.getSource?.('line');
        if (lineSource) {
            map.removeLayer?.('line-label');
            map.removeLayer?.('line');
            map.removeSource?.('line');
        }
    }

    const removeCircle = () => {
        if (!map) {
            return
        }

        const circleSource = map.getSource?.('circle');
        if (circleSource) {
            map.removeLayer?.('circle');
            map.removeLayer?.('outline-circle');
            map.removeSource?.('circle');
        }
    }

    const clearAll = () => {
        removeMarkers();
        removeLine();
        removeCircle();
    }

    if (!map) {
        return 'Map is null'
    }

    return (
        <IconButton
            data-cy={'distance-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={"small"}
            className={`productpqswitcher ${isActive ? 'active' : ''}`}
        >
            <RulerIcon size={"small"}/>
        </IconButton>
    );
}
