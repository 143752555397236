import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import './CurrentConditions.css'
import FormControl from '@mui/material/FormControl'
import React, {useEffect, useState} from 'react'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'

const CurrentConditionsMode = {
    off: '',
    basic: 'basic',
    advanced: 'advanced',
}

const CurrentConditions = ({product, selectedProducts}) => {
    const toggleProduct = useMapStore((state) => state.toggleProduct)
    const currentConditions = useMapStore((state) => state.currentConditions)
    const mapMode = useMapStore((state) => state.mapMode)
    const selectedTime = useMapStore((state) => state.selectedDateTime)

    const [currentMode, setCurrentMode] = useState(CurrentConditionsMode.off)

    useEffect(() => {
        if (!currentConditions) {
            return
        }

        if (isProductSelected(product)) {
            setCurrentMode(CurrentConditionsMode.advanced)
            currentConditions.switchMode(CurrentConditionsMode.advanced)
        }
    }, [currentConditions])

    useEffect(() => {
        if (mapMode !== MapMode.historical || currentMode === CurrentConditionsMode.off || !isProductSelected(product)) {
            return
        }

        currentConditions?.enable()
    }, [mapMode, selectedTime])

    useEffect(() => {
        if (!selectedProducts.length) {
            currentConditions?.switchMode(CurrentConditionsMode.off)
            setCurrentMode(CurrentConditionsMode.off)
            return
        }

        if (currentConditions && isProductSelected(product) && currentMode === CurrentConditionsMode.off) {
            setCurrentMode(CurrentConditionsMode.advanced)
            currentConditions.switchMode(CurrentConditionsMode.advanced)
        }
    }, [currentConditions, selectedProducts])

    const isProductSelected = (product) => selectedProducts.some((selectedProduct) => selectedProduct.id === product.id)

    const handleRadioClick = (newMode) => {
        if (currentMode === newMode) {
            newMode = CurrentConditionsMode.off

            if (isProductSelected(product)) {
                toggleProduct(product)
            }
        } else {
            if (!isProductSelected(product)) {
                toggleProduct(product)
            }
        }

        setCurrentMode(newMode)
        currentConditions.switchMode(newMode)
    }

    return (
        <FormControl className={'productListCurrentConditionWrapper'}>
            <div className={'productListCurrentConditionLabel'}>
                Current conditions
            </div>
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={currentMode}
            >
                <FormControlLabel
                    value="basic"
                    label="Basic"
                    control={<Radio
                        size={'small'}
                        onClick={() => handleRadioClick('basic')}
                    />}
                />
                <FormControlLabel
                    value="advanced"
                    label="Advanced"
                    control={<Radio
                        size={'small'}
                        onClick={() => handleRadioClick('advanced')}
                    />}
                />
            </RadioGroup>
        </FormControl>
    )
}

export default CurrentConditions
