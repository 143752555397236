import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useStoreWithEqualityFn} from 'zustand/traditional'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'
import {CheckBox} from '../../../../shared/ui/CheckBox'
import BaronStormTracks from './BaronStormTracks'

const BaronStormTracksGroup = ({ groupName, group}) => {
    const baronStormTracks = useMapStore((state) => state.baronStormTracks)

    const mapMode = useMapStore((state) => state.mapMode)
    const selectedTime = useMapStore((state) => state.selectedDateTime)
    const toggleProduct = useMapStore((state) => state.toggleProduct)
    const selectedProducts = useStoreWithEqualityFn(
        useMapStore,
        (state) => state.selectedProducts,
        (prev, next) => {
            const isPrevGroup = prev.some((product) => product.product_group === groupName)
            const isNextGroup = next.some((product) => product.product_group === groupName)

            return !(isPrevGroup || isNextGroup)
        },
    )

    useEffect(() => {
        if (!baronStormTracks) {
            return
        }

        const selectedBaronStormTracksProducts = selectedProducts.filter((product) => BaronStormTracks.ids.includes(product.id))
        if (selectedBaronStormTracksProducts.length) {
            baronStormTracks.enable(selectedProducts.map((p) => p.name))
        }
    }, [baronStormTracks])

    useEffect(() => {
        const selectedBaronStormTracksProducts = selectedProducts.filter((product) => BaronStormTracks.ids.includes(product.id))
        if (!selectedBaronStormTracksProducts.length) {
            baronStormTracks?.disable()
        }
    }, [selectedProducts])

    useEffect(() => {
        if (mapMode !== MapMode.historical) {
            return
        }

        baronStormTracks?.enable(selectedProducts.map((p) => p.name))
    }, [mapMode, selectedTime])

    const isProductSelected = (product) => !!selectedProducts.find((selectedProduct) => selectedProduct.id === product.id)

    const handleToggleProduct = (product) => {
        // when 'All' clicked
        if (product.id === BaronStormTracks.products.all.id) {
            const {toggled} = toggleProduct(product, (state) => {
                const selectedProducts = state.selectedProducts.filter((enabledProduct) => {
                    const isSameProduct = enabledProduct.id === product.id
                    const isNotBaronStormTracksProduct = !BaronStormTracks.ids.includes(enabledProduct.id)

                    return isSameProduct || isNotBaronStormTracksProduct
                })

                return {...state, selectedProducts}
            })

            if (toggled) {
                baronStormTracks.enable(['All'])
            }
        } else {
            const {selectedProducts} = toggleProduct(product, (state) => {
                const selectedProducts = state.selectedProducts.filter((enabledProduct) => {
                    const isNotAllProductId = enabledProduct.id !== BaronStormTracks.products.all.id

                    return isNotAllProductId
                })

                return {...state, selectedProducts}
            })

            baronStormTracks.enable(selectedProducts.map((p) => p.name))
        }
    }

    return (
        <Accordion
            variant={'dark'}
            defaultExpanded
            data-cy={'Baron Storm Tracks'}
        >
            <AccordionSummary
                variant={'dark'}
            >
                <Typography>
                    {groupName}
                    {' '}
                    (
                    {group.length}
                    )
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                variant={'dark'}
                style={{display: 'flex', gap: '4px'}}
            >
                {Object.values(BaronStormTracks.products).map((product) =>
                    (<Box
                        key={product.name}
                        className={'row gap4 fullWidth'}
                        sx={{paddingLeft: '24px', paddingRight: '8px'}}
                    >
                        <FormControlLabel
                            label={product.name}
                            control={
                                <CheckBox
                                    checked={isProductSelected(product)}
                                    onChange={() => handleToggleProduct(product)}
                                />
                            }
                        />
                    </Box>),
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default BaronStormTracksGroup
