import {AbstractTextProduct} from "../AbstractTextProduct";
import dayjs from "dayjs";
import {MapMode, useMapStore} from "../../../../app/store/MapStore";
import { textProductsRequester } from 'shared/libs/mapProducts/TextProducts';
import {LSRIcons} from './LocalStormIcons';

const KeysIcons = Object.keys(LSRIcons);

export default class LocalStormReport extends AbstractTextProduct {
    constructor(opts) {
        opts = Object.assign({name: "lsr"}, opts);
        super(opts);
        for (let key in LSRIcons) {
            this.loadImage(key, LSRIcons[key]);
        }
    }

    _getSource(cb) {
        const {mapMode, nowDateTime, selectedDateTime} = useMapStore.getState();
        const isHistorical = mapMode === MapMode.historical;
        const isSelectedDateTimeNotFuture = selectedDateTime <= nowDateTime;
        const isArchive = (isHistorical && isSelectedDateTimeNotFuture);
        const path = isArchive ? 'reports/archive/lsr/all.json' : 'reports/lsr/all.json';
        const time_end = dayjs(selectedDateTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const time_start = dayjs(selectedDateTime).add(-1, 'day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        const params = {time_start, time_end};

        textProductsRequester({path, signature: this.options.signature, params})
            .then(response=>cb(this._responseDataToGeoJson(response)))
            .catch(console.error);
    }

    _responseDataToGeoJson(data) {
        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: data? this._convertToFeatures(data) : []
            }
        }
    }

    _convertToFeatures(reports) {
        reports = reports.filter(({location}) => (location && location.coordinates));
        return reports.map(({ issuetime, location, report}) => {
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: location.coordinates
                },
                properties: {   
                    type: report.type,
                    icon: KeysIcons.includes(report.type) ? report.type : 'DefaultLSR',
                    displayPopupInfo: {
                        type: report.type,
                        text: report.text,
                        city: location.city,
                        state: location.state,
                        county: location.county,
                        time: issuetime,
                    }
                }
            };
        });
    }

    createLayers() {
        return [
            this.createSingleLayer({
                type: 'symbol',
                layout: {
                    'icon-image': ['get', 'icon'],
                    'icon-size': 0.4,
                    'icon-allow-overlap': true
                },
            })
        ]
    }

}