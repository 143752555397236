import dayjs from 'dayjs';
import maplibregl from "maplibre-gl";
import {TextProductsConverter} from 'shared/libs/TextProductsConverter'
import './CurrentConditions.css'
import 'shared/assets/weatherConditions/WeatherConditions.css'
import {
    getFlightCategory,
    getVisibleSensors,
    createPointObjectCityState,
    getSkyConditionText,
    getVisibilityText,
    detectColorTemperature, getWeatherConditionIconClass,
} from "shared/libs/MapUtils";
import {MapMode, useMapStore} from "../../../../app/store/MapStore";
import {AbstractTextProduct} from "../AbstractTextProduct";
import {fetchTextProductData} from 'pages/alerts/api/FetchAlerts'


const mapper = TextProductsConverter.getFunctionOfMappingObject({
    temp: 'temperature.value',
    dew: 'temperature.dew_point',
    cloudcover: 'cloud_cover.value',
    speed: 'wind.speed',
    winddir: 'wind.dir',
    code: 'weather_code.value',
    isDay: 'daylight',
    name: 'station.name',
    visibility: 'visibility.value',
    humidity: 'relative_humidity.value',
    windChill: 'temperature.wind_chill',
    heatIndex: 'temperature.heat_index',
    id: 'station.id',
    time: 'issuetime',
    raw: 'raw_metar'
});


export default class CurrentConditions extends AbstractTextProduct {
    constructor(options) {
        options = Object.assign({
            name: 'metars',
            events: ['dragend', 'zoomend'],
            params: {}
        }, options)
        super(options)
        this._drawSensors = this._drawSensors.bind(this);
        this._drawProduct = this._drawProduct.bind(this);
        this._request = this._request.bind(this);
        this.sensors = {};

        this.mode = ''
    }

    switchMode(mode) {
        this.mode = mode

        if (!this.mode) { //WL-630. Fix bug with not deleting popups
            this.disable()

            return
        }

        this.enable()
    }

    enable() {
        super.enable()
        this._removeFromMap()
        this.options.map.on('moveend', this._drawSensors);
        this._drawSensors();
    }

    disable() {
        super.disable()
        this._removeFromMap();
    }

    _removeFromMap() {
        this.options.map.off('moveend', this._drawSensors);
        for (let key in this.sensors) {
            this.sensors[key].remove();
            delete this.sensors[key];
        }
    }

    _drawSensors() {
        let sensors = getVisibleSensors(this.options.map);
        this._currentSensors = sensors.map(e => e[0].toLowerCase());

        let shouldBeKeepList = [];
        for (let key in this.sensors) {
            if (sensors.some(sensor => sensor[0].toLowerCase() === key)) {
                shouldBeKeepList.push(key);
            } else {
                this.sensors[key].remove();
                delete this.sensors[key];
            }
        }
        sensors.forEach(sensor => {
            if (shouldBeKeepList.indexOf(sensor[0].toLowerCase()) < 0) {
                this._request(sensor);
            }
        });
    }

    _request(id) {
        const {mapMode,nowDateTime, selectedDateTime} = useMapStore.getState()
        const isHistorical = mapMode === MapMode.historical
        const isSelectedDateTimeNotFuture = selectedDateTime <= nowDateTime

        if (isHistorical && isSelectedDateTimeNotFuture) {
            fetchTextProductData(
                `metar/station/${id[0].toLowerCase()}.json`,
                {
                    from: dayjs(selectedDateTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                },
                {
                    isHistorical,
                }
            ).then(response => {
                this._drawProduct(response.data.metars_archive.data)
            })
        } else {
            fetchTextProductData(
                `metar/station/${id[0].toLowerCase()}.json`,
            ).then(response => {
                this._drawProduct(response.data.metars.data)
            })
        }
    }

    _drawProduct(data) {
        if (!this.options.isEnabled) {//WL-630. Fix bug with not deleting popups when zooming
            return;
        }
        if (this.mode === 'basic') {
            this._drawBasicProduct(data)
        } else if (this.mode === 'advanced') {
            this._drawAdvancedProduct(data)
        }
    }

    _drawAdvancedProduct(data) {
        let mapped = mapper(data);
        mapped.id = mapped.id?.toLowerCase();
        mapped.temp = TextProductsConverter.temperature(mapped.temp, true)
        mapped.dew = TextProductsConverter.temperature(mapped.dew, true)

        if (!this.options.map || (this._currentSensors.indexOf(mapped.id) < 0) || this.sensors[mapped.id]) return;
        this.AdvancedMarkerCreator.init()

        const isAbsolute = (dayjs().diff(dayjs(mapped.time)) > 90 * 60 * 1000)
        const windSpeed = (data.wind && !isNaN(data.wind.speed)) ? Math.round(TextProductsConverter.speedMetersPerSecondToKnots(mapped.speed)) : undefined
        let dir = data.wind ? (this.mode === 'advanced' ? data.wind.dir : TextProductsConverter.windDirection(data.wind.dir)) : undefined

        let dewPoint = (mapped.dew) + '&deg',
            temperature = (mapped.temp + '&deg') || '--';
        const stName = createPointObjectCityState(mapped.name);
        const windShort = (mapped.speed ? TextProductsConverter.speed(mapped.speed) + ' ' + TextProductsConverter.windDirection(dir) : '').trim();
        const visibility = mapped.visibility ? getVisibilityText(mapped.visibility) : '';
        const relative_humidity = mapped.humidity;
        const feelslike = (mapped.windChill || mapped.heatIndex) ? TextProductsConverter.temperature(mapped.windChill || mapped.heatIndex) : undefined;
        const additionalLabel = stName.name.toLowerCase();
        let skyCond = getSkyConditionText(data);
        const rawMetar = mapped.raw

        const flightCategory = getFlightCategory(data);
        const cloudCover = (data.cloud_cover && (!isNaN(data.cloud_cover.value))) ? data.cloud_cover.value : flightCategory[3];
        const station = data.station.id.split('_')[0].substring(1);
        const html = this.AdvancedMarkerCreator.getBarbHtmlElem(mapped.id, flightCategory, cloudCover, station, mapped.temp, mapped.dew, windSpeed, dir, isAbsolute)
        let el = document.createElement('div');
        el.classList.add('advanced-metar-marker')
        el.innerHTML = `
            <div class="metar-marker">
                ${html}
            </div>
        `

        let marker = new maplibregl.Marker({
            element: el,
            anchor: 'top'
        }).setLngLat(data.station.coordinates).addTo(this.options.map);
        this.sensors[mapped.id] = marker
        const helpBodyElem = `
            <div class="metars-legends">
                <div class="help-stations">
                    <div><div class="metar-help-station vfr"></div><span>VFR</span></div>
                    <div><div class="metar-help-station mvfr"></div><span>MVFR</span></div>
                    <div><div class="metar-help-station ifr"></div><span>IFR</span></div>
                    <div><div class="metar-help-station lifr"></div><span>LIFR</span></div>
                </div>
                <div class="help-stations">
                    <div><div class="metar-help-station fullst"></div><span>Clear</span></div>
                    <div><div class="metar-help-station fullst"><div class="small-few"></div></div><span>Few</span></div>
                    <div><div class="metar-help-station emptyst"><div class="small-half"></div></div><span>Scattered</span></div>
                    <div><div class="metar-help-station emptyst"><div class="small-broken"></div></div><span>Broken</span></div>
                    <div><div class="metar-help-station emptyst"></div><span>Overcast</span></div>
                </div>
                <div class="help-stations">
                    <div><div class="wind-help"></div><span>&lt;5 kts</span></div>
                    <div><div class="wind-help wind-help-2"></div><span>5 kts</span></div>
                    <div><div class="wind-help wind-help-3"></div><span>10 kts</span></div>
                    <div><div class="wind-help wind-help-4"></div><span>50 kts</span></div>
                </div>
            </div>`
        
        let popupContent = `
        <div class="marker-body">
            <div class="marker-header">
                <div class="metar-short-info">
                    ${additionalLabel}
                </div>
            </div>
            <div class="column gap0">
        `

        if (temperature) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Temperature: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${temperature}
                    </div>
                </div>`
        }

        if (feelslike) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Feels Like: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${feelslike}
                    </div>
                </div>`
        }

        if (dewPoint) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Dew Point: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${dewPoint}
                    </div>
                </div>`
        }

        if (windShort) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Wind: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${windShort}
                    </div>
                </div>`
        }

        if (relative_humidity) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Relative Humidity: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${relative_humidity}%
                    </div>
                </div>`
        }

        popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Sky Conditions: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${skyCond ? skyCond : 'Unknown'}
                    </div>
                </div>`

        if (visibility) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Visibility: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${visibility}
                    </div>
                </div>`
        }

        if (rawMetar) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Raw Metar: 
                    </span>
                    <div class="currentConditionStringBold">
                        ${rawMetar}
                    </div>
                </div>`
        }

        if (isAbsolute) {
            popupContent += `
                <div class="currentConditionString">
                    <span class="currentConditionTitleAdvanced">
                        Report Date: 
                    </span>
                    <div style="color: var(--palette-error-main);">
                        ${dayjs.utc(mapped.time).format('h:mm A M/D/YY')}
                    </div>
                </div>`
        }
        
        popupContent += `
            </div>
            <div class="legendsString">
                Legends: 
                <span>
                    <div class="legendsSwitcher"/>
                </span>
            </div>
            ${helpBodyElem}
            </div>
        `


        let popup = new maplibregl.Popup({closeOnClick: false})
            .setHTML(popupContent)
            .on('open', () => {
                const helpButton = popup.getElement('.helpbuttonadv');
                if (helpButton) {
                    helpButton.addEventListener('click', () => {
                        const legends = helpButton.querySelector('.metars-legends')
                        legends?.classList.toggle('visible')
                        const legendsButton = helpButton.querySelector('.legendsSwitcher')
                        legendsButton?.classList.toggle('visible')
                    });
                }
            })
        marker.setPopup(popup)

        el.addEventListener('click', (e) => {
            e.stopPropagation()

            const isOverThreeHoursOld = dayjs(mapped.time).isBefore(dayjs().subtract(3, 'hour'))
            const isLiveMapMode = useMapStore.getState().mapMode === MapMode.live

            if (isOverThreeHoursOld && isLiveMapMode) {
                return
            }

            // close other popups
            for (let markerId of Object.keys(this.sensors)) {
                const m = this.sensors[markerId]
                if (m.getPopup().isOpen())
                    m.togglePopup()
            }
            marker.togglePopup()
        })
    }

    _drawBasicProduct(data) {
        let mapped = mapper(data);
        if (!mapped.id) return;
        mapped.id = mapped.id.toLowerCase();
        if (!this.options.map || (this._currentSensors.indexOf(mapped.id) < 0) || this.sensors[mapped.id]) return;

        const isAbsolute = (dayjs().diff(dayjs(mapped.time)) > 90 * 60 * 1000)
        const dir = TextProductsConverter.windDirection(mapped.winddir) || ''
        const dewPoint = TextProductsConverter.temperature(mapped.dew)
        const temperature = TextProductsConverter.temperature(mapped.temp) || '--'
        const stName = createPointObjectCityState(mapped.name);
        const windShort = (mapped.speed ? TextProductsConverter.speed(mapped.speed) + ' ' + dir : 'Calm').trim();
        const tempColor = detectColorTemperature(mapped.temp);
        const visibility = mapped.visibility ? getVisibilityText(mapped.visibility) : '';
        const relative_humidity = mapped.humidity;
        const feelsLike = TextProductsConverter.temperature(mapped.windChill || mapped.heatIndex)
        const additionalLabel = stName.name.toLowerCase();
        let weatherConditionIconClass = getWeatherConditionIconClass(mapped.code, mapped.isDay).slice(18);

        if (weatherConditionIconClass.endsWith('-day')) {
            weatherConditionIconClass = weatherConditionIconClass.slice(0, -4);
        }
        let skyCond = getSkyConditionText(data);

        let el = document.createElement('div');
        el.className = 'basicsensorwrapper impmarker-wrapper';
        el.innerHTML = `
            <div class="impmarker basicsensor impmarker-noclose">
                <div class="impmarker-header">
                    <div class="metarcondheader ${(windShort ? 'withwind' : '')}">
                        <div class="metar-short-info currentConditionHeaderWrapper">
                            <div class="currentConditionHeader">
                                ${stName.state}
                            </div>
                            <div class="currentConditionSubHeader">
                                ${additionalLabel}
                            </div>
                       </div>
                        <div class="temp-and-metar-wind-short">
                            <div class="temp-and-icon">
                                <div style="${(tempColor ? 'color:' + tempColor : '')}" class="metar-temperature">${temperature}</div>
                                ${weatherConditionIconClass ? `<img alt="forecast_icon" src="/forecastIcons/${weatherConditionIconClass}.svg"/>` : ''}
                            </div>
                            <div class="metarswitcher"></div>
                        </div>
                    </div>
                </div>
                <div class="impmarker-body">
                    ${feelsLike ? `<div class="currentConditionString"><span class="currentConditionTitle">Feels Like: </span><div class="currentConditionStringBold">${feelsLike}</div></div>` : ''}
                    ${dewPoint ? `<div class="currentConditionString"><span class="currentConditionTitle">Dew Point: </span><div class="currentConditionStringBold">${dewPoint}</div></div>` : ''}
                    ${windShort ? `<div class="currentConditionString"><span class="currentConditionTitle">Wind: </span><div class="currentConditionStringBold">${windShort}</div></div>` : ''}
                    ${relative_humidity ? `<div class="currentConditionString"><span class="currentConditionTitle">Relative Humidity: </span><div class="currentConditionStringBold">${relative_humidity}%</div></div>` : ''}
                    ${`<div class="currentConditionString"><span class="currentConditionTitle">Sky Conditions: </span><div class="currentConditionStringBold">${skyCond ? skyCond : 'Unknown'}</div></div>`}
                    ${visibility ? `<div class="currentConditionString"><span class="currentConditionTitle">Visibility: </span><div class="currentConditionStringBold">${visibility}</div></div>` : ''}
                    ${isAbsolute ? '<div class="currentConditionString"><span class="currentConditionTitle">Report Date: </span><div class="currentConditionStringBold"><div style="color: var(--palette-error-main); display:inline-block">' + dayjs.utc(mapped.time).local().format('h:mm A M/D/YY') + '</div></div>' : ''}
                </div>
            </div>`;
        this.sensors[mapped.id] = new maplibregl.Marker({
            element: el,
            anchor: 'top'
        }).setLngLat(data.station.coordinates).addTo(this.options.map);

        el.addEventListener('click', event => {
            event.stopPropagation()

            const isOverThreeHoursOld = dayjs(mapped.time).isBefore(dayjs().subtract(3, 'hour'))
            const isLiveMapMode = useMapStore.getState().mapMode === MapMode.live

            if (isOverThreeHoursOld && isLiveMapMode) {
                return
            }

            const isWillBeActive = el.classList.contains('opened');
            const listOpened = document.querySelectorAll('.basicsensorwrapper.opened');
            for (let i = 0; i < listOpened.length; i++) {
                listOpened[i].classList.remove('opened');
            }
            if (!isWillBeActive) {
                el.classList.add('opened');
            }
        });

        el.addEventListener('dblclick', event => { //WL-678
            event.preventDefault();
            event.stopPropagation();
        });
    }

    AdvancedMarkerCreator = {
        init() {
            this.stemHtml = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            this.stemHtml.setAttribute('x', "79");
            this.stemHtml.setAttribute('y', "90");
            this.stemHtml.setAttribute('width', "2");
            this.stemHtml.setAttribute('height', "30");
            this.stemHtml.setAttribute('fill', "#f41d1d");
            this.stemHtml.setAttribute('stroke', "#000000");
            this.stemHtml.setAttribute('stroke-width', "null");
            this.stemHtml.setAttribute('stroke-dasharray', "null");

            this.s5Html = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            this.s5Html.setAttribute('d', 'm69.11327,7.82208l9.88673,-4.82208l0.87674,1.79759l-9.88673,4.82208l-0.87674,-1.79759z');
            this.s5Html.setAttribute('stroke-dasharray', "null");
            this.s5Html.setAttribute('stroke-width', "null");
            this.s5Html.setAttribute('stroke', "#000000");
            this.s5Html.setAttribute('fill', "#f41d1d");

            this.s10Html = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            this.s10Html.setAttribute('d', 'm63.7205,10.45231l15.2795,-7.45231l0.87674,1.79759l-15.2795,7.45231l-0.87674,-1.79759z');
            this.s10Html.setAttribute('stroke-dasharray', "null");
            this.s10Html.setAttribute('stroke-width', "null");
            this.s10Html.setAttribute('stroke', "#000000");
            this.s10Html.setAttribute('fill', "#f41d1d");

            this.s50Html = document.createElementNS('http://www.w3.org/2000/svg', 'path')
            this.s50Html.setAttribute('d', 'm79.0,2l0,11l-20,5l20,-16z');
            this.s50Html.setAttribute('fill', "#f41d1d");
            this.s50Html.setAttribute('stroke', "#000000");
            this.s50Html.setAttribute('stroke-width', "null");
            this.s50Html.setAttribute('stroke-dasharray', "null");

            this.barbHtml = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this.barbHtml.setAttribute('width', "160");
            this.barbHtml.setAttribute('height', "160");
            this.barbHtml.setAttribute('style', "position: absolute; top: -80px; left: -79px;");
            this.barbHtml.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink");
            this.barbHtml.setAttribute('xmlns', "http://www.w3.org/2000/svg");

            const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            g.setAttribute('transform', "rotate(0 80 80)");
            this.barbHtml.appendChild(g);
        },

        movePathElement(el, x, y) {
            const dArr = el.getAttribute("d").split('l');
            const p = dArr[0].substring(1).split(',');
            const px = parseFloat(p[0]) + x;
            const py = parseFloat(p[1]) + y;
            dArr[0] = `m${px},${py}`;
            el.setAttribute("d", dArr.join('l'));
        },

        // Function to get barb HTML element
        getBarbHtmlElem(id, fc, cc, sn, tp, dp, s, dir, o) {
            let text = "round";

            if (cc < 25) {
                text = "";
            } else if (cc < 50) {
                text = "1_4";
            } else if (cc < 75) {
                text = "1_2";
            } else if (cc < 100) {
                text = "3_4";
            }

            const classCircle = `metar-barb-circle${cc === undefined ? " isobsolete" : ""}`;
            const styleCircle = text !== "" ? 'style="background-image:url(' + `/img/${fc[1]}_${text}.png` + ')"' : '';
            const circleIcon = text !== "" ? `/img/${fc[1]}_circle.png` : '/img/white_circle.png'
            const elemTemperature = !isNaN(tp) ? `<div class="metar-barb-temperature">${tp}</div>` : '';
            const elemDewPoint = !isNaN(dp) ? `<div class="metar-barb-dew">${dp}</div>` : '';
            const div = document.createElement('div');
            div.innerHTML = `
                <div>
                    <img 
                        id="${id}"
                        class="${classCircle}"
                        alt=""
                        src="${circleIcon}"
                        ${styleCircle}>
                    <div class="metar-barb-name">${sn}</div>${elemTemperature} ${elemDewPoint}
                </div>
    `;

            if (isNaN(dir)) { // no barb
                if (o) {
                    div.querySelectorAll('*')?.classList?.add("isobsolete");
                }
                return div.outerHTML;
            } else {
                const barbClone = this.barbHtml.cloneNode(true);
                div.appendChild(barbClone);

                const barb = div.querySelector('svg g');
                barb.setAttribute("transform", `rotate(${dir - 180} 80 80)`);
                barb.parentNode.style.zIndex = '-1';

                this.createBarbContent(barb, s);

                const allElements = div.querySelectorAll('*');
                for (const element of allElements) {
                    element.style.fill = fc[2];
                }

                const isLiveMapMode = useMapStore.getState().mapMode === MapMode.live

                if (o && isLiveMapMode) {
                    div.querySelectorAll('*').forEach(element => element.style.opacity = "0.5");
                }
                if (cc === undefined) {
                    div.querySelector('svg').style.opacity = "0.5";
                }

                return div.outerHTML;
            }
        },

        // Function to create barb content
        createBarbContent(barb, s) {
            if (s < 10) {
                if (s < 5) {
                    barb.appendChild(this.stemHtml.cloneNode(true));
                } else {
                    const s0 = this.s5Html.cloneNode(true);
                    this.movePathElement(s0, 0, 105);
                    barb.appendChild(s0)
                    barb.appendChild(this.stemHtml.cloneNode(true));
                }
            } else {
                // Long barb calculations
                const s50 = Math.floor(s * 0.02);
                const r10 = s - 50 * s50;
                const s10 = Math.floor(r10 * 0.1);
                const r5 = r10 - 10 * s10;
                const s5 = Math.floor(r5 * 0.2);
                const barb_len = (s5 + s10) * 5 + s50 * 13;
                let stem_len = 30;
                let spos = 30 - barb_len;

                if (barb_len > 15) {
                    stem_len = 15 + barb_len;
                    spos = 15;
                }
                if (s50 === 0 && s10 === 0) {
                    spos = 25;
                }
                spos += 90;

                const incFunc = function (initHtml, inc) {
                    const st = initHtml.cloneNode(true);
                    this.movePathElement(st, 0, spos);
                    barb.appendChild(st);
                    spos += inc;
                };

                if (s5 !== 0) {
                    incFunc.call(this, this.s5Html, 5);
                }
                for (let i = 0; i < s10; i++) {
                    incFunc.call(this, this.s10Html, 5);
                }
                for (let i = 0; i < s50; i++) {
                    incFunc.call(this, this.s50Html, 13);
                }
                let node = this.stemHtml.cloneNode(true)
                node.setAttribute('height', stem_len)
                barb.appendChild(node)
            }
            return barb;
        }
    };
}
