import {Box} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel.css'
import AlertConfigurationStepper from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/AlertConfigurationStepper'
import StepperScreens from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/StepperScreens'
import {fetchConfigurationGroup} from 'pages/alerts/api/FetchAlerts'
import AgreeModal from '../../../../shared/ui/AgreeModal'
import {useActionItemsStore} from "../../../../app/store/ActionItemsStore";

function ManageAlertConfigurationPanel({defaultTab = 'Locations', args = {}, fromTimeLine=false, fromSettings=false}) {

    const {
        toggleDuplication,
        toggleEditingActionItem,
    } = useActionItemsStore((state) => state)

    const [currentTab, setCurrentTab] = useState(defaultTab)
    const [configGroup, setConfigGroup] = useState(null)
    const [alertConfigurationValid, setAlertConfigurationValid] = useState({
        isAnyDefinitionSelected: false,
        isAnyLocationSelected: false,
        isDisableCreationLocationMode: false, // mode of creating location. Should be true to make isValid.
        isDisableCreationActionItemMode: false,
        isValid: false,
    })

    const [isAlertStepperVisible, setIsAlertStepperVisible] = useState(true)
    const [isPressedSaveButton, setIsPressedSaveButton] = useState(false) // Listener of save button press

    const [query] = useSearchParams()
    const editArg = query.get('edit')
    const copyArg = query.get('copy')
    const wdArg = query.get('wd')

    const [modalData, setModalData] = useState(null)

    const openCustomModal = (href) => {
        setModalData({
            title: 'Discard weather alert',
            message: 'Are you sure you want to discard your changes?',
            agreeMsg: 'Discard',
            disAgreeMsg: 'Go back',
            agreeFunc: (isConfirmed) => handleModalAction(isConfirmed, href),
        })
    }

    const closeModal = () => setModalData(null)

    const handleModalAction = (isConfirmed, href) => {
        setModalData(null)
        if (isConfirmed && href) {
            window.location.href = href // continue only when user is agreed to leave from page
        }
    }

    useEffect(() => {
        const handleLinkClick = (event) => {
            const target = event.target.closest('a')
            if (target && target.hostname === location.hostname) {
                event.preventDefault()
                openCustomModal(target.href) // open popup with HREF
            }
        }

        const handlePopState = () => closeModal()

        document.addEventListener('click', handleLinkClick)
        window.addEventListener('popstate', handlePopState)

        return () => {
            document.removeEventListener('click', handleLinkClick)
            window.removeEventListener('popstate', handlePopState)
        }
    }, [])

    useEffect(() => {
        toggleDuplication(false)
        toggleEditingActionItem(null)

        if (Object.keys(args).length) setConfigGroup(args)
        else if (editArg) {
            fetchConfigurationGroup(editArg).then((data) => {
                data.id = editArg
                setConfigGroup(data)
            })
        } else if (copyArg) {
            fetchConfigurationGroup(copyArg).then((data) => {
                setConfigGroup(data)
            })
        } else if (wdArg) {
            setConfigGroup({definition: parseInt(wdArg), locations: {}})
        } else setConfigGroup({})
    }, [])

    if (!configGroup) return (<></>)

    const handleSaveButton = () => {
        setIsPressedSaveButton(true)
    }

    return (
        <Box
            className="newWeatherAlertsWrapper"
            sx={{overflow: 'hidden'}}
        >
            {modalData && (
                <AgreeModal
                    width="500px"
                    wait={false}
                    data={modalData}
                />
            )}

            <div className='bodyNWA fullWidth'>
                {isAlertStepperVisible && <AlertConfigurationStepper
                    currentTab={currentTab}
                    onChange={setCurrentTab}
                    alertConfigurationValid={alertConfigurationValid}
                    isPressedSaveButtonFunc={() => handleSaveButton()}
                />}
                <StepperScreens
                    currentStep={currentTab}
                    alertConfigurationValid={alertConfigurationValid}
                    onChange={setAlertConfigurationValid}
                    configGroup={configGroup}
                    showSidebar={isAlertStepperVisible}
                    setShowSidebar={() => setIsAlertStepperVisible(!isAlertStepperVisible)}
                    isPressedSaveButton={isPressedSaveButton}
                    fromTimeLine={fromTimeLine}
                    fromSettings={fromSettings}
                />
            </div>
        </Box>
    )
}

export default ManageAlertConfigurationPanel
