import React from "react";
import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import {ReactComponent  as Background} from "shared/assets/emptyStates/Background.svg";
import {ReactComponent  as WidgetsRectangle1} from "shared/assets/emptyStates/WidgetsRectangle1.svg";
import {ReactComponent  as WidgetsRectangle2} from "shared/assets/emptyStates/WidgetsRectangle2.svg";
import {ReactComponent  as WidgetsRectangle3} from "shared/assets/emptyStates/WidgetsRectangle3.svg";
import {ReactComponent  as WidgetsRectangle4} from "shared/assets/emptyStates/WidgetsRectangle4.svg";
import {ReactComponent  as WidgetsIcon} from "shared/assets/emptyStates/WidgetsIcon.svg";

export default function EmptyStateWidgets({title, text}) {

		return (
				<Box sx={{height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
						<Box sx={{display:"flex", flexDirection:"column", justifyContent:"end", width:"600px", height:"360px", borderRadius:"12px", padding:"40px", position:"relative", overflow:"hidden", backgroundColor:"white"}}>
								<Box sx={{position:"absolute", right:"10px", bottom:"-7px"}}>
										<Background/>
								</Box>
								<Box sx={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
										<Box sx={{position:"absolute", right:"150px", top:"5px"}}>
												<WidgetsRectangle1/>
										</Box>
										<Box sx={{position:"absolute", right:"25px", top:"55px"}}>
												<WidgetsRectangle2/>
										</Box>
										<Box sx={{position:"absolute", right:"150px", top:"105px"}}>
												<WidgetsRectangle3/>
										</Box>
										<Box sx={{position:"absolute", right:"0px", bottom:"-10px"}}>
												<WidgetsRectangle4/>
										</Box>
										<Box sx={{position:"absolute", right:"205px", bottom:"65px"}}>
												<WidgetsIcon/>
										</Box>
								</Box>
								<Box sx={{display:"flex", alignItems:"start", flexDirection:"column", textAlign: "start", gap:"12px"}}>
										<Typography sx={{fontSize:"24px", fontWeight:"500", width:"200px", lineHeight:"32px", height:"64px", letterSpacing:"0.25px"}}>{title}</Typography>
										<Typography sx={{fontSize:"14px", fontWeight:"400", width:"245px", lineHeight:"20px", height:"40px", color:"var(--palette-grey-500)", letterSpacing:"0.25px"}}>{text}</Typography>
								</Box>
						</Box>
				</Box>
		)
}