import {TimeField as MuiTimeField} from '@mui/x-date-pickers/TimeField'
import theme from 'app/Theme'
import ClockIcon from 'shared/assets/icons/Clock'

export function TimeField({onChange, disableIcon= false, ...other}) {
    return (
        <div style={{
            display: 'grid',
            alignItems: 'center',
            width: '100%',
        }}
        >
            {!disableIcon &&
            <ClockIcon sx={{
                    gridColumn: 1,
                    gridRow: 1,
                    marginLeft: '12px',
                }}
            />}
            <MuiTimeField
                onChange={onChange}
                className={'TimeField'}
                format={'hh:mm'}
                sx={{
                    gridColumn: 1,
                    gridRow: 1,
                    '& .MuiOutlinedInput-root': {
                        padding: disableIcon ? '0' : '10px 10px 10px 28px',
                        borderStyle: 'stroke',
                        borderWidth: '1px',
                    },
                    '& input': {
                        color: 'var(--palette-grey-900)',
                        '&::placeholder': {
                            fontSize: '16px',
                        },
                    },
                }}
                {...other}
            />
        </div>
    )
}
