import maplibregl from "maplibre-gl";
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {CurrentConditions, Nexrad} from "../../features/textProducts";
import BaronStormTracks from "../../features/textProducts/ui/baronStormTracks/BaronStormTracks";
import {editDefaultMapView, fetchMapProducts} from "../../pages/alerts/api/FetchAlerts";
import {prepApiAlert} from "../../pages/alerts/ui/AlertsPage/AlertsPage";
import ADDITIONAL_PRODUCTS from "../../pages/map/model/Tile/AdditionalProducts";
import {
    HistoricalProductCodes,
    ProductsController,
    TEXT_PRODUCTS_CODES
} from "../../pages/map/model/Tile/ProductsController";
import {transformer} from "../../pages/map/model/utils/urltransformer";
import {NON_STANDARD_PROJECTION} from "../../pages/map/ui/MapPage/MapPage";
import {productNamesByApiType} from "../../shared/libs/AlertMapping";
import {Signature} from "../../shared/libs/requester3/baron-signature";
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker'
import {percentToDateTimeMs} from "../../shared/libs/Utils";
import {useLocationsStore} from "./LocationsStore";
import {useViewsStore} from "./ViewsStore";

maplibregl.workerClass = maplibreglWorker

const signature = new Signature()

export const MapMode = {
    live: 'Live',
    historical: 'Historical',
}

export const useMapStore = create(devtools((set, get) => {
    const currentDateInMs = new Date().getTime()

    return {
        map: null,
        mapMode: MapMode.live,
        productsController: null,

        products: [],
        selectedProducts: [],
        locations: [],
        enabledLocations: [],

        selectedDateTime: currentDateInMs,
        nowDateTime: currentDateInMs,
        dateTimeRange: {
            startDate: currentDateInMs - 3600000,
            endDate: currentDateInMs,
        },
        timelinePosition: 50,

        singleSiteRadar: null,
        currentConditions: null,
        baronStormTracks: null,

        initialize(containerRef) {
            const state = get()
            const map = new maplibregl.Map({
                container: containerRef.current,
                style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
                hash: true,
                transformRequest: transformer(window.requester),
                minZoom: 2,
                dragRotate: false,
                touchZoomRotate: false,
            })
            window.map = map // remove later. Use map store instead

            map.on('movestart', async () => {
                const state = get()
                state.productsController.setTime(state.selectedDateTime)
                state.productsController.setTimeRange(state.dateTimeRange)
                state.productsController.switchToTmsMode()
            })
            map.on('zoomend', async () => {
                const state = get()
                state.productsController.setTime(state.selectedDateTime)
                state.productsController.setTimeRange(state.dateTimeRange)
                state.productsController.switchToTmsMode()
            })

            map.addControl(new maplibregl.ScaleControl(), 'bottom-right')
            map.addControl(new maplibregl.ScaleControl({
                unit: 'imperial',
            }), 'bottom-right')
            map.addControl(new maplibregl.AttributionControl({
                customAttribution: ['Lightning data provided by ENTLN Lightning',
                    '<a href="https://baronweather.com/" target="_blank">Baron Weather</a>'],
            }))
            map.addControl(new maplibregl.FullscreenControl())

            const productsController = new ProductsController({
                map,
                signature,
                time: currentDateInMs,
                startDate: state.dateTimeRange.startDate,
                endDate: state.dateTimeRange.endDate,
            })

            const baronStormTracks = new BaronStormTracks({map, signature})
            const currentConditions = new CurrentConditions({map, signature})
            const singleSiteRadar = new Nexrad({map, signature})

            const newState = {
                map,
                productsController,
                currentConditions,
                baronStormTracks,
                singleSiteRadar,
            }
            set(newState)

            return get()
        },

        destroy() {
            const state = get()

            state.currentConditions.destroy()
            state.baronStormTracks.destroy()
            state.singleSiteRadar.destroy()
            state.productsController.destroy()

            map.remove()

            set({
                map: null,
                currentConditions: null,
                baronStormTracks: null,
                singleSiteRadar: null,
                productsController: null,
                enabledLocations: [],
            })
        },

        setProducts(update) {
            set((state) => {
                const newProducts = typeof update === 'function'
                    ? update(state.products)
                    : update

                return {products: newProducts}
            });
        },

        async fetchProducts() {
            const products = await fetchMapProducts()
            products.forEach(product => {
                product.isHistorical = HistoricalProductCodes.includes(product.api_product_code)
            })

            ADDITIONAL_PRODUCTS.forEach((p) => products.push(p))

            set({products})

            return products
        },

        setSelectedProducts(update) {
            set((state) => {
                let newSelectedProducts = typeof update === 'function'
                    ? update(state.selectedProducts)
                    : update

                state.productsController.setProducts2(newSelectedProducts)
                state.updateDefaultMapView()

                return {selectedProducts: newSelectedProducts}
            });

        },

        toggleProduct(product, beforeToggle = (state) => state) {
            const {
                selectedProducts,
                productsController,
                updateDefaultMapView,
                mapMode,
            } = beforeToggle(get())
            let newSelectedProducts = []

            const isProductAlreadyEnabled = selectedProducts.some(
                (enabledProduct) => {
                    return enabledProduct.id === product.id
                }
            )

            if (isProductAlreadyEnabled) {
                newSelectedProducts = selectedProducts.filter(enabledProduct => enabledProduct.id !== product.id)
                const newState = {selectedProducts: newSelectedProducts}
                if (mapMode === MapMode.live) {
                    const productCodes = getProductCodes(newState.selectedProducts)
                    const newRange = productsController.calculateTimeRange(productCodes)
                    newState.dateTimeRange = newRange
                    productsController.setTimeRange(newRange)
                }
                productsController.setProducts2(newSelectedProducts)
                productsController.switchToTmsMode(true)
                set(newState)
                updateDefaultMapView()

                return {toggled: false, selectedProducts: newSelectedProducts}
            }

            if (selectedProducts.length + 1 > 5) {
                return {toggled: false, selectedProducts}
            }

            newSelectedProducts = [...selectedProducts, product]
            const newState = {selectedProducts: newSelectedProducts}
            if (mapMode === MapMode.live) {
                const productCodes = getProductCodes(newState.selectedProducts)
                const newRange = productsController.calculateTimeRange(productCodes)
                newState.dateTimeRange = newRange
                productsController.setTimeRange(newRange)
            }
            productsController.setProducts2(newSelectedProducts)
            productsController.switchToTmsMode(true)
            set(newState)
            updateDefaultMapView()

            return {toggled: true, selectedProducts: newSelectedProducts}
        },

        toggleLocation(location) {
            const {enabledLocations, map, updateDefaultMapView} = get()
            const isLocationSelected = enabledLocations.some(selectedLocation => selectedLocation.id === location.id)
            let newSelectedLocations = []

            if (isLocationSelected) {
                newSelectedLocations = enabledLocations.filter((selectedLocation) => selectedLocation.id !== location.id)
                removeMarkers([location])
            } else {
                newSelectedLocations = [...enabledLocations, location]
                addLocationToMap(location, map)
            }

            set({
                enabledLocations: newSelectedLocations
            })
            updateDefaultMapView()
        },

        toggleLocationGroup(group) {
            const {enabledLocations, map, updateDefaultMapView} = get()
            const isEveryLocationSelectedInGroup = group.every((location) => enabledLocations.some(({id}) => id === location.id))
            const isSomeLocationSelectedInGroup = isEveryLocationSelectedInGroup || group.some((location) => enabledLocations.some(({id}) => id === location.id))
            let newSelectedLocations = []
            let locationsToAddToMap = []

            if (isEveryLocationSelectedInGroup) {
                removeMarkers(group)
                newSelectedLocations = enabledLocations.filter((location) =>
                    !group.some(({id}) => id === location.id)
                )
                locationsToAddToMap = []
            } else if (isSomeLocationSelectedInGroup) {
                locationsToAddToMap = group.filter((location) =>
                    !enabledLocations.some(({id}) => id === location.id)
                )
                newSelectedLocations = [...enabledLocations, ...locationsToAddToMap]
            } else {
                newSelectedLocations = [...enabledLocations, ...group]
                locationsToAddToMap = group
            }

            set({enabledLocations: newSelectedLocations})
            addLocationsToMap(locationsToAddToMap, map)
            updateDefaultMapView(newSelectedLocations)
        },

        setEnabledLocations(update) {
            set((state) => {
                let newEnabledLocations = typeof update === 'function'
                    ? update(state.selectedProducts)
                    : update

                return {enabledLocations: newEnabledLocations}
            });
        },

        loadView(view) {
            if (!view?.selectedProducts) {
                return
            }

            const newState = {}

            if (view.mapMode) {
                newState.mapMode = view.mapMode
            }

            const {products, productsController} = get()
            if (view.mapMode === MapMode.historical && view.dateTimeRange) {
                newState.dateTimeRange = view.dateTimeRange
                productsController.setTimeRange(newState.dateTimeRange)

                if (view.selectedDateTime) {
                    newState.selectedDateTime = view.selectedDateTime
                } else {
                    newState.selectedDateTime = view.dateTimeRange.endDate
                }
                productsController.setTime(newState.selectedDateTime)
            }

            if (view.selectedProducts) {
                const selectedProducts = products.filter((product) =>
                    product.available
                    && view.selectedProducts.some(p => p.id === product.id)
                )

                newState.selectedProducts = selectedProducts

                if (view.mapMode === MapMode.live) {
                    const productCodes = getProductCodes(newState.selectedProducts)
                    const newRange = productsController.calculateTimeRange(productCodes)
                    newState.dateTimeRange = newRange
                    productsController.setTimeRange(newRange)
                }

                productsController.setProducts2(selectedProducts)
            }

            if (view.enabledLocations) {
                const {map} = get()
                const {locations} = useLocationsStore.getState()
                const newSelectedLocations = []

                for (const selectedLocationId of view.enabledLocations) {
                    const location = locations.find((location) => location.id === selectedLocationId)
                    if (location) {
                        newSelectedLocations.push(location)
                    }
                }

                newState.enabledLocations = newSelectedLocations
                removeMarkers(locations)
                addLocationsToMap(newSelectedLocations, map)
            }

            if (view.mapPosition) {
                window.location.hash = view.mapPosition
            }

            newState.mapMode = view.mapMode ?? MapMode.live

            set(newState)
        },

        loadViewFromAlert(alert, aid) {
            if (!alert) {
                return
            }

            const alert_idx = aid.indexOf('_') > 0 ? parseInt(aid.split('_')[1]) : 0
            alert = prepApiAlert(alert, true)[alert_idx]

            if (!alert) {
                return
            }

            const {products, productsController, map} = get()
            const {locations} = useLocationsStore.getState()
            const locationId = alert.location_id
            const enabledLocations = locations.filter(({id}) => id === locationId)
            // TODO: looks like dates should be saved to dateTimeRange
            const dates = {
                startDate: (new Date(alert.start_date)).valueOf(),
                endDate: (new Date(alert.end_date)).valueOf(),
            }
            const productNames = mapProducts(alert.api_alert)
            const selectedProducts = productNames
                .map((name) => products.find((p) => p.name === name))
                .filter((prod) => !!prod)

            set({
                enabledLocations,
                selectedProducts,
            })
            productsController.setProducts2(selectedProducts)
            addLocationsToMap(enabledLocations, map)

            return dates
        },

        setDateTimeRange(dateTimeRange) {
            const {updateDefaultMapView, selectedProducts, productsController, timelinePosition} = get()

            const startMs = dateTimeRange[0].valueOf()
            const endMs = dateTimeRange[1].valueOf()
            const newDateTimeRange = {
                startDate: startMs,
                endDate: endMs,
            }
            const newSelectedProducts = filterNonHistoricalProducts(selectedProducts)
            const newSelectedDateTime = percentToDateTimeMs(timelinePosition, startMs, endMs)

            // switch to historical mode
            set({
                mapMode: MapMode.historical,
                selectedDateTime: newSelectedDateTime,
                selectedProducts: newSelectedProducts,
                dateTimeRange: newDateTimeRange,
            })

            productsController.setTime(newSelectedDateTime)
            productsController.setTimeRange(newDateTimeRange)
            productsController.setProducts2(newSelectedProducts)
            productsController.switchToWmsMode()
            productsController.switchToTmsMode()
            updateDefaultMapView()
        },

        updateDefaultMapView() {
            const {map, enabledLocations, mapMode, dateTimeRange, selectedProducts, selectedDateTime} = get()
            if (!map) {
                return
            }

            // control where we get to the map
            const urlParams = new URLSearchParams(window.location.search)
            const alertId = urlParams.get('alert_id')
            if (alertId) {
                return;
            }

            const {createDefaultMapView} = useViewsStore.getState()
            createDefaultMapView({
                name: 'defaultmapview',
                order: 1,
                data_json: {
                    mapMode,
                    dateTimeRange,
                    selectedDateTime,
                    activeProducts: selectedProducts,
                    activeLocations: enabledLocations.map(location => location.id),
                    locationHash: window.location.hash,
                },
            })
        },
    }
}))

function filterNonHistoricalProducts(products) {
    return products.filter(product => {
        return HistoricalProductCodes.includes(product.api_product_code)
    })
}

function mapProducts(data) {
    if (data && data.alert_type !== 'usereventsalert') {
        return productNamesByApiType[data.alert_type] || []
    } else {
        try {
            const rules = data.weather_definition.rules
            if (!rules) return []
            const productNames = new Set()
            rules.forEach((rule) => {
                if (rule.lines) {
                    rule.lines.forEach((l) => {
                        if (l.product_name !== 'Weekdays' && l.product_name !== 'Months' && l.product_name !== 'Time Range') {
                            productNames.add(l.product_name)
                        }
                    })
                }
            })
            return Array.from(productNames)
        } catch (err) {
            console.log(err)
            return []
        }
    }
}

function addLocationsToMap(locations, map) {
    for (const location of locations) {
        addLocationToMap(location, map)
    }
}

function addLocationToMap(location, map) {
    if (location.marker) {
        location.marker.addTo(map)
        return
    }

    const marker = new maplibregl.Marker({
        color: 'var(--palette-primaryRed-light)',
        scale: 0.5,
    })
        .setLngLat(location.coordinates)
        .addTo(map)
    marker.getElement().setAttribute('title', location.label)
    location.marker = marker
}

function removeMarkers(locations) {
    for (const location of locations) {
        location.marker?.remove?.()
    }
}

function getProductCodes(products) {
    const productCodes = []

    products.forEach((p) => {
        const prod = products.find((pr) => (pr.id === p.id))
        if (prod) {
            const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                ? prod.api_product_code
                : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
            productCodes.push(productCode)
        }
    })
    return productCodes
}