import {DateCalendar as MuiDateCalendar} from "@mui/x-date-pickers/DateCalendar/DateCalendar";
import React, {useEffect, useState} from "react";
import CalendarToolbar from "../CalendarToolbar/CalendarToolbar";
import dayjs from "dayjs";
import {PickersDay} from "../PickersDay";
import {TimeField} from "../../TimeField";
import {MenuItem, Select} from "@mui/material";
import styles from "./DateTimeRangeCalendar.module.scss";


export const CalendarView = {
    day: 'day',
    month: 'month',
    year: 'year',
}

export default function DateTimeRangeCalendar({
                                                  value = [null, null],
                                                  onChange = () => {},
                                                  showDaysOutsideCurrentMonth = true,
                                                  defaultValue = null,
                                              }) {
    const [view, setView] = useState(CalendarView.day)
    const [visibleDate, setVisibleDate] = useState(
        defaultValue ? dayjs(defaultValue) : value[0] ? dayjs(value[0]) : dayjs()
    )
    const [range, setRange] = useState(
        Array.isArray(value) ? value.map((v) => (v ? dayjs(v) : null)) : [null, null]
    )

    const [startTime, setStartTime] = useState(range[0] ? range[0].format('HH:mm') : null)
    const [endTime, setEndTime] = useState(range[1] ? range[1].format('HH:mm') : null)
    const [startAmPm, setStartAmPm] = useState(range[0] ? (range[0].hour() >= 12 ? 'PM' : 'AM') : 'AM')
    const [endAmPm, setEndAmPm] = useState(range[1] ? (range[1].hour() >= 12 ? 'PM' : 'AM') : 'PM')

    useEffect(() => {
        const [start, end] = value.map((v) => (v ? dayjs(v) : null))
        setRange([start, end])
        setStartTime(start ? start.format('HH:mm') : null)
        setEndTime(end ? end.format('HH:mm') : null)
        setStartAmPm(start ? (start.hour() >= 12 ? 'PM' : 'AM') : 'AM')
        setEndAmPm(end ? (end.hour() >= 12 ? 'PM' : 'AM') : 'PM')
    }, [value])

    useEffect(() => {
        setRange(value.map((v) => (v ? dayjs(v) : null)))
    }, [value])

    const handleMonthChange = (date) => {
        setVisibleDate(dayjs(date))
        setView(CalendarView.day)
    }

    const handleYearChange = (date) => {
        setVisibleDate(dayjs(date))
        setView(CalendarView.day)
    }

    const handleChange = (newValue) => {
        const newValueDayjs = dayjs(newValue)
        const [start, end] = range || [null, null]

        let newRange
        if (!start || (start && end)) {
            newRange = [newValueDayjs, null]
            setStartTime(newValueDayjs.format('HH:mm'))
            setStartAmPm(newValueDayjs.hour() >= 12 ? 'PM' : 'AM')
            setEndTime(null)
            setEndAmPm('PM')
        } else if (start && !end) {
            const endDate = newValueDayjs.endOf('day')
            newRange = newValueDayjs.isBefore(start)
                ? [endDate, start]
                : [start, endDate]
            setEndTime('23:59')
            setEndAmPm('PM')
        }

        setRange(newRange)
        onChange(newRange)
    }

    const handleTimeChange = (index) => (newValue) => {
        if (!newValue || !newValue.isValid()) return

        const hours = newValue.hour()
        const minutes = newValue.minute()
        const amPm = index === 0 ? startAmPm : endAmPm

        let adjustedHours = hours;
        if (amPm === 'PM' && hours < 12) adjustedHours += 12
        if (amPm === 'AM' && hours === 12) adjustedHours = 0

        const formattedTime = `${adjustedHours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}`
        if (index === 0) setStartTime(formattedTime)
        else setEndTime(formattedTime)

        updateRangeTime(index, adjustedHours, minutes)
    }

    const handleAmPmChange = (index) => (event) => {
        const selectedAmPm = event.target.value
        const time = index === 0 ? startTime : endTime
        if (!time) return

        let [hours, minutes] = time.split(':').map(Number)

        if (selectedAmPm === 'PM' && hours < 12) hours += 12
        else if (selectedAmPm === 'AM' && hours >= 12) hours -= 12

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        if (index === 0) {
            setStartTime(formattedTime)
            setStartAmPm(selectedAmPm)
        } else {
            setEndTime(formattedTime)
            setEndAmPm(selectedAmPm)
        }

        updateRangeTime(index, hours, minutes)
    }

    const updateRangeTime = (index, hours, minutes) => {
        const [start, end] = range
        let newRange = [...range]
        if (index === 0 && start) {
            newRange[0] = start.set('hour', hours).set('minute', minutes)
        } else if (index === 1 && end) {
            newRange[1] = end.set('hour', hours).set('minute', minutes)
        }
        setRange(newRange)
        onChange(newRange)
    }

    const renderDay = (props) => {
        const { day, ...other } = props
        const dayDayjs = dayjs(day)
        const [start, end] = range || [null, null]

        const isInRange =
            start &&
            end &&
            (dayDayjs.isAfter(start, 'day') || dayDayjs.isSame(start, 'day')) &&
            (dayDayjs.isBefore(end, 'day') || dayDayjs.isSame(end, 'day'))
        const isStart = start && dayDayjs.isSame(start, 'day')
        const isEnd = end && dayDayjs.isSame(end, 'day')

        return (
            <PickersDay
                {...other}
                day={day}
                style={{
                    backgroundColor: isInRange ? 'var(--palette-grey-100)' : 'transparent',
                    ...(isStart || isEnd ? { backgroundColor: 'var(--palette-primary-dark)', color: 'white' } : {}),
                }}
            />
        )
    }

    const currentYear = dayjs().year();
    const isDecember = dayjs().month() === 11;
    const minDate = dayjs('2000-01-01');
    const maxDate = isDecember
        ? dayjs().year(currentYear + 1).endOf('year') //if it's December now, then the maximum year is next
        : dayjs().endOf('year');

    return (
        <div>
            <CalendarToolbar
                view={view}
                value={visibleDate}
                slotProps={{
                    month: {
                        button: {
                            onClick: () => {
                                const newView = view === CalendarView.month ? CalendarView.day : CalendarView.month;
                                setView(newView);
                            },
                        },
                    },
                    year: {
                        button: {
                            onClick: () => {
                                const newView = view === CalendarView.year ? CalendarView.day : CalendarView.year;
                                setView(newView);
                            },
                        },
                    },
                }}
            />
            <MuiDateCalendar
                defaultValue={visibleDate}
                value={range[0]}
                view={view}
                onChange={handleChange}
                onMonthChange={handleMonthChange}
                onYearChange={handleYearChange}
                showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
                slots={{ day: renderDay }}
                minDate={minDate}
                maxDate={maxDate}
            />
            <div className={styles.bottomWrapper}>
                <div className={styles.timeWrapper}>
                    <span>Start Time: </span>
                    <div className={styles.timeContent}>
                        <TimeField
                            format="hh:mm"
                            disableIcon={true}
                            value={startTime ? dayjs(startTime, 'HH:mm') : null}
                            onChange={handleTimeChange(0)}
                        />
                        <Select value={startAmPm} onChange={handleAmPmChange(0)}>
                            <MenuItem value="AM">AM</MenuItem>
                            <MenuItem value="PM">PM</MenuItem>
                        </Select>
                    </div>
                </div>
                {endTime &&
                    <div className={styles.timeWrapper}>
                        <span>End Time: </span>
                        <div className={styles.timeContent}>
                            <TimeField
                                format="hh:mm"
                                disableIcon={true}
                                value={endTime ? dayjs(endTime, 'HH:mm') : null}
                                onChange={handleTimeChange(1)}
                            />
                            <Select value={endAmPm} onChange={handleAmPmChange(1)}>
                                <MenuItem value="AM">AM</MenuItem>
                                <MenuItem value="PM">PM</MenuItem>
                            </Select>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}