const MAX_RANGE_TOLERANCE = 8; // delta*this number = not actual

export class WmsLayer {

    constructor(options) {
        /*
            layer: layer as TileLayer, from Tilev2
            steps - array of times (frames)
            onReady - callback after loading all images
        */
        this.layer = options.layer;
        this.sourceName = 'WMSAnimationSource'+this.layer.id;
        this.layerId = 'WMSLayer'+this.layer.id;
        this.lastFrame = null;
        this.opacity = this.layer.opacity;
        this.onReady = options.onReady;
        this.steps = options.steps;
        this._timeKeys = {};
        this._wmsParams = this._prepareBbboxParams();
        this.isInitialized = false;
        this.time = this.layer.time;
        this._init();
    }

    _init(){
        this._load(()=>{
            this.isInitialized = true;
            this.setTime(this.time);
            this.onReady();
        })
    }

    _load(clbk){
        const urls = this._getUrlsToTimes();
        for (let timeKey in urls) {
            this._loadLayer(urls[timeKey], timeKey);
        }
        const onReady = () => {
            this.layer.map.off('idle', onReady)
            clbk();
        }
        this.layer.map.on('idle', onReady);
    }

    _getUrlsToTimes(){
        const bboxString = this._wmsParams.alignedBboxInMeters.join(',');
        const frameTimes = this.steps.map(t => this.layer._getClosestTime(t));
        const basicParams = {
            width: this._wmsParams.width,
            height: this._wmsParams.height,
            bbox: bboxString,
            service: 'WMS',
            request: 'GetMap',
            version: '1.3.0',
            format: 'image%2Fpng',
            transparent: 'true',
            crs: 'EPSG%3A3857'
        };
        const path = `wms/${this.layer.isSSRLayer ? this.layer.id.replace('{SSR}', this.layer.SSR): this.layer.id}`;
        const urls = {};
        frameTimes.forEach(t => {
            if (!t) return;
            urls[t] = this.layer.signature.makeUrl({
                path,
                params: Object.assign({
                    layers: this.layer.meta.forecastTime ? this.layer.meta.forecastTime : t,
                    time : this.layer.meta.forecastTime ? t : undefined
                }, basicParams)
            });
        });
        return urls;
    }

    _loadLayer(src, timeKey) {
        const {topLeftLngLat, rightBottomLngLat} = this._wmsParams;
        const coordinates = [
            [topLeftLngLat.lng, rightBottomLngLat.lat],
            [rightBottomLngLat.lng, rightBottomLngLat.lat],
            [rightBottomLngLat.lng, topLeftLngLat.lat],
            [topLeftLngLat.lng, topLeftLngLat.lat]
        ];
        this.layer.map.addSource(this.sourceName + '_' + timeKey, {
            type: 'image',
            url: src,
            coordinates
        });
        this.layer.map.addLayer({
            id: this.layerId + '_' + timeKey,
            type: 'raster',
            source: this.sourceName+ '_' + timeKey,
            paint: {
                'raster-fade-duration': 0,
                'raster-opacity': this.opacity,
            },
        }, this.layer.firstLabelLayerId);
        this.layer.map.setLayoutProperty(this.layerId + '_' + timeKey, 'visibility', 'none');
        this._timeKeys[timeKey] = true;
    }

    _prepareBbboxParams() {
        const box = this.layer.map.getBounds().toArray();
        const zoom = Math.floor(this.layer.map.getZoom());
        const countTilesInHalfMapByWidth = Math.pow(2, zoom);
        const b0Px = this.layer.map.project(box[0]);
        const b1Px = this.layer.map.project(box[1]);
        const origin = this.layer.map.project([0, 0]);
        const maxXOrigin = this.layer.map.project([180, 0]);

        const widthOfOneTileOnCurrentZoom = (maxXOrigin.x - origin.x) / countTilesInHalfMapByWidth;


        //TODO: Math simplify
        const minX = - origin.x;
        const maxX = b1Px.x - origin.x;
        const minY = origin.y - b0Px.y;
        const maxY = origin.y;

        const alignedMinX = Math.floor(minX / widthOfOneTileOnCurrentZoom) * widthOfOneTileOnCurrentZoom;
        const alignedMaxX = Math.ceil(maxX / widthOfOneTileOnCurrentZoom) * widthOfOneTileOnCurrentZoom;
        const alignedMinY = Math.floor(minY / widthOfOneTileOnCurrentZoom) * widthOfOneTileOnCurrentZoom;
        const alignedMaxY = Math.ceil(maxY / widthOfOneTileOnCurrentZoom) * widthOfOneTileOnCurrentZoom;

        const diffTop = alignedMaxY - maxY;
        const diffLeft = alignedMinX - minX;
        const diffBottom = alignedMinY - minY;
        const diffRight = alignedMaxX - maxX;

        const topLeft = { x: diffLeft, y: b0Px.y - diffBottom };
        const rightBottom = { x: b1Px.x + diffRight, y: -diffTop };
        const topLeftLngLat = this.layer.map.unproject(topLeft);
        const rightBottomLngLat = this.layer.map.unproject(rightBottom);

        const b0 = this._projectMeters([topLeftLngLat.lng, topLeftLngLat.lat]);
        const b1 = this._projectMeters([rightBottomLngLat.lng, rightBottomLngLat.lat]);
        return {
            //xMin, yMin, xMax, yMax
            alignedBboxInMeters: [b0[0], b0[1], b1[0], b1[1]],
            width: Math.round((rightBottom.x - topLeft.x)/widthOfOneTileOnCurrentZoom)*256,
            height: Math.round((topLeft.y - rightBottom.y)/widthOfOneTileOnCurrentZoom)*256,
            topLeftLngLat,
            rightBottomLngLat
        }
    }

    _projectMeters(lngLatArr) {
        var d = 0.017453292519943295,
            max = 85.0511287798,
            lat = Math.max(Math.min(max, lngLatArr[1]), -max),
            x = lngLatArr[0] * d,
            y = lat * d;

        y = Math.log(Math.tan((Math.PI / 4) + (y / 2)));
        return [Math.round(x * 6378137), Math.round(y * 6378137)];
    }

    _clearLayers() {
        for (let timeKey in this._timeKeys)
            this.layer.map.removeLayer(this.layerId + '_' + timeKey);
    }

    _clearSources() {
        for (let timeKey in this._timeKeys)
            this.layer.map.removeSource(this.sourceName + '_' + timeKey);
    }

    _getClosestImageTime(time){
        // a bit difference for layer. because step of images may be more huge than layers.
        time = new Date(time).getTime();
        const meta = this.layer.meta;
        const tolerance = (this.layer.endDate - this.layer.startDate)/99; // 100 is step counts on timeline
        const actualDate = new Date().getTime();
        if (meta.forecastTime) {
            if (actualDate > time + tolerance) {
                return; // not forecast date for forecast product
            }
        } else {
            if (actualDate < time - tolerance) {
                return; // not current date for current product
            }
        }
        let delta = Number.MAX_VALUE;
        let selectedTime;
        for (let timeKey in this._timeKeys){  // todo do it once for whole timeline an keep
            const t = new Date(timeKey).getTime();
            const currentDelta = Math.abs(t - time);
            if (currentDelta < delta) {
                delta = currentDelta;
                selectedTime = timeKey;
            }
        }
        const t1 = new Date(meta.times[meta.times.length - 1]).getTime();
        const t2 = new Date(meta.times[0]).getTime();
        const mediumDelta = MAX_RANGE_TOLERANCE * Math.round(Math.abs((t1 - t2) / (meta.times.length - 1)));
        if (mediumDelta < Math.abs(new Date(selectedTime).getTime() - time)) {
            return; // not actual match
        }
        return selectedTime;
    }

    setOpacity(opacity) {
        this.opacity = opacity;
        for (let timeKey in this._timeKeys)
            this.layer.map.setPaintProperty(this.layerId + '_' + timeKey, 'raster-opacity', this.opacity);
    }

    setLayerSource (source) {
        const oldLayers = map.getStyle().layers;
        const layerIndex = oldLayers.findIndex(l => l.id === this.layerId);
        const layerDef = oldLayers[layerIndex];
        const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
        layerDef.source = source;
        this.layer.map.removeLayer(this.layerId);
        this.layer.map.addLayer(layerDef, before);
    }

    setTime(time){
        this.time = time;
        const frameTime = this._getClosestImageTime(time);
        if (!frameTime) {
            this.lastFrame && this.lastFrame !== frameTime && this.layer.map.setLayoutProperty(this.layerId + '_' + this.lastFrame, 'visibility', 'none');
            this.lastFrame = undefined;
            if (this.layerId.includes('{SSR}')) {
                this.layer.map.fire('actualDataNotFoundNexrad', {site: this.layer.SSR})
            }
            return;
        }
        if (this.lastFrame === frameTime) return;  // the layer is the same
//      this.setLayerSource(this.sourceName + '_' + frameTime);  // recreating layer is a bit slower than switching visibility
        if (this.layerId.includes('{SSR}')) {
            this.layer.map.fire('dataFoundNexrad', {site: this.layer.SSR})
        }
        this.layer.map.setLayoutProperty(this.layerId + '_' + frameTime, 'visibility', 'visible');
        this.lastFrame && this.lastFrame !== frameTime && this.layer.map.setLayoutProperty(this.layerId + '_' + this.lastFrame, 'visibility', 'none');
        this.lastFrame = frameTime;
    }

    remove() {
        this.isInitialized = false;
        this._clearLayers();
        this._clearSources();
        this._timeKeys = {};
    }
}