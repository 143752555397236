import React, {useState} from 'react'
import styles from './DashboardWidget.module.scss'
import {IconButton, Menu, MenuItem} from '@mui/material'
import MoreIcon from '../../../shared/assets/icons/More'
import PenIcon from '../../../shared/assets/icons/Pen'
import TrashIcon from '../../../shared/assets/icons/Trash'
import AgreeModal from '../../../shared/ui/AgreeModal'
import CopyIcon from "../../../shared/assets/icons/Copy";
import MapMarkerIcon from "../../../shared/assets/icons/MapMarker";
import {useDashboardStore} from "../../../app/store/DashboardStore";

const DashboardWidget = ({title, children, location, widget}) => {

    const {
        toggleDuplicationWidget,
        toggleEditingDashboardWidget,
        deleteDashboardWidget
    } = useDashboardStore((state) => state)

    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = Boolean(anchorEl)

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(null)

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const handleEditWidget=()=>{
        toggleDuplicationWidget(false)
        toggleEditingDashboardWidget(widget)
    }

    const handleCopyWidget=()=>{
        toggleDuplicationWidget(true)
        toggleEditingDashboardWidget(widget)
    }

    const handleDeleteWidget = () => {
        setIsOpenDeleteModal(true)
    }

    const deleteWidget = (agree) =>{
        if (agree) {
            deleteDashboardWidget(widget.id)
        }
        setIsOpenDeleteModal(null)
        setAnchorEl(null)
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.header}>

                    <div className={styles.headerTitles}>
                        <div className={styles.title}>
                            {title}
                        </div>
                        <span className={styles.pinWrapper}>
                            <span style={{whiteSpace: 'nowrap'}}>
                                <MapMarkerIcon className={styles.pin}/>
                                {location.label}
                            </span>
                        </span>
                    </div>

                    <div>
                        <IconButton
                            id="basic-button"
                            aria-controls={isOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isOpen ? 'true' : undefined}
                            onClick={handleClick}
                            variant={'outlined'}
                            className={styles.menuButton}
                        >
                            <MoreIcon/>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose('')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                onClick={handleEditWidget}
                                key="edit"
                            >
                                <PenIcon size={'small'}/>
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={handleCopyWidget}
                                key="copy"
                            >
                                <CopyIcon size={'small'}/>
                                Copy
                            </MenuItem>
                            <MenuItem
                                onClick={handleDeleteWidget}
                                key="delete"
                                color={'error'}
                            >
                                <TrashIcon size={'small'}/>
                                Remove widget
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className={styles.body}>
                    {children}
                </div>
            </div>

            {isOpenDeleteModal && <AgreeModal
                data={{
                    message: <>
                    Are you sure you want to delete widget
                        {' '}
                        <span className={styles.boldFont}>
"
                            {title}
"
                        </span>
?
                    </>,
                    title: 'Delete widget',
                    agreeMsg: 'Delete',
                    mode: 'deleting',
                    agreeFunc: deleteWidget,
                }}
            />}
        </>
    )
}

export default DashboardWidget
