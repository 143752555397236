import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import DateAdapter from "../shared/libs/DateAdapter";
import theme from "./Theme";
import './Reset.css';
import './App.css';
import 'app/styles/index.scss'
import {StyledEngineProvider} from '@mui/material/styles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.Ls.en.weekStart = 1;

export function AppWrapper({children}) {
    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider
                dateAdapter={DateAdapter}
                adapterLocale="en"
            >
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    {children}
                </ThemeProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    )
}