import {createSvgIcon} from '@mui/material/utils'

export default function WindDirectionArrowIcon(props) {
    const Icon = createSvgIcon(
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            style={{padding: '6px 0 0 6px'}}
        >
            <path
                stroke="null"
                d="m6.01589,0.39442c0.03876,-0.09746 0.10594,-0.18109 0.19281,-0.24003c0.0868,-0.05887 0.18931,-0.09036 0.29425,-0.09036c0.10494,-0.00001 0.20745,0.03148 0.29428,0.09037c0.08684,0.0589 0.15395,0.14255 0.19276,0.24002l5.93059,14.82652c0.03955,0.09876 0.04807,0.20714 0.02455,0.31085c-0.02358,0.10364 -0.07807,0.19775 -0.15631,0.26973c-0.07831,0.07192 -0.17656,0.11845 -0.28185,0.13326c-0.1053,0.01481 -0.21258,-0.00272 -0.30771,-0.05035l-5.69633,-2.84816l-5.69562,2.84892c-0.09517,0.04797 -0.2027,0.06579 -0.30826,0.05108c-0.10556,-0.01471 -0.20413,-0.06124 -0.28256,-0.13339c-0.07844,-0.07215 -0.13301,-0.16651 -0.15646,-0.27047c-0.02344,-0.10396 -0.01464,-0.21261 0.02524,-0.31145l5.93061,-14.82654l0.00001,0z"
                fill="#677A8E"
                id="svg_3"
                transform="rotate(180 6.502661228179931,8.00265884399414) "
            />
        </svg>, 'WindDirectionArrow')
    return (
        <Icon
            {...props}
            data-cy="WindDirectionArrow-icon"
        />
    )
}
