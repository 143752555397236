import maplibregl from '!maplibre-gl' // ! is important here
import {Box, Button} from '@mui/material'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'pages/map/ui/css/map.css'
import 'pages/map/ui/css/conds.css'
import 'pages/map/ui/css/style.css'
import CircularProgress from '@mui/material/CircularProgress'
import {DistanceTool, PinPointTool, PointQueryTool} from 'features/mapTools'
import {MapSidebar} from 'pages/map/ui/MapSidebar/MapSidebar'
import {TimeLineMap} from 'pages/map/ui/timeline/timeline'
import React, {useEffect, useRef, useState} from 'react'
import {useLocationsStore} from '../../../../app/store/LocationsStore'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'
import {useViewsStore} from '../../../../app/store/ViewsStore'
import {clamp} from '../../../../shared/libs/Utils'
import {fetchRealAlertByAid} from '../../../alerts/api/FetchAlerts'
import {TEXT_PRODUCTS_CODES} from '../../model/Tile/ProductsController'
import {Legends} from '../legends/legends'
import MapToolbar from '../MapToolbar/MapToolbar'
import styles from "./MapPage.module.scss"
import {clsx} from "clsx";

export const NON_STANDARD_PROJECTION = {
    'north-american-radar': 'Mask1-Mercator',
    'baron-hires-maxreflectivity-dbz-all': 'Mask1-Mercator',
}

export const MapPage = () => {
    const map = useMapStore((state) => state.map)
    const productsController = useMapStore((state) => state.productsController)
    const updateDefaultMapView = useMapStore((state) => state.updateDefaultMapView)

    const selectedProducts = useMapStore((state) => state.selectedProducts)
    const setSelectedProducts = useMapStore((state) => state.setSelectedProducts)
    const products = useMapStore((state) => state.products)
    const fetchProducts = useMapStore((state) => state.fetchProducts)

    const selectedDateTime = useMapStore((state) => state.selectedDateTime)
    const dateTimeRange = useMapStore((state) => state.dateTimeRange)

    const fetchDefaultMapView = useViewsStore((state) => state.fetchDefaultMapView)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const [clearPQ, setClearPQ] = useState(false)
    const [activeTool, setActiveTool] = useState(null)

    const mapElementRef = useRef(null)

    useEffect(() => {
        window.requester.isready(() => {
            const {map} = useMapStore.getState().initialize(mapElementRef)
            map.on('movestart', () => {
                setIsPlaying(false)
            })
            map.on('moveend', () => {
                updateDefaultMapView()
            })

            const urlParams = new URLSearchParams(window.location.search)
            const alertId = urlParams.get('alert_id')

            if (alertId) {
                const promises = [fetchLocations(), fetchProducts(), fetchRealAlertByAid(alertId)]

                Promise.all(promises)
                    .then((responses) => {
                        const alert = responses[2]
                        const dateTimeRange = useMapStore.getState().loadViewFromAlert(alert, alertId)

                        useMapStore.setState({
                            selectedDateTime: dateTimeRange.startDate,
                            dateTimeRange,
                        })
                    })
            } else {
                const promises = [fetchLocations(), fetchProducts(), fetchDefaultMapView(locations)]
                Promise.all(promises)
                    .then((responses) => {
                        const defaultMapView = responses[2]
                        useMapStore.getState().loadView(defaultMapView)
                    })
            }
        })

        return () => {
            useMapStore.getState().destroy()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(updateDataIfAvailable, 60000)

        return () => {
            clearInterval(interval)
        }
    }, [isLoading, isPlaying, selectedProducts])

    // remove later
    useEffect(() => {
        productsController?.setTimeRange(dateTimeRange)
    }, [dateTimeRange])

    useEffect(() => {
        if (selectedProducts.length) {
            setIsPlaying(false)
            // reEnableWmsIfNeed()
        } else {
            setIsPlaying(false)
        }
    }, [selectedProducts])

    const updateDataIfAvailable = () => {
        if (!productsController) {
            return
        }

        const {nowDateTime, selectedDateTime, dateTimeRange, timelinePosition} = useMapStore.getState()
        let nowPosition = dateMsToPosition(nowDateTime, dateTimeRange.startDate, dateTimeRange.endDate)
        nowPosition = clamp(0, nowPosition, 100)

        const productCodes = getProductCodes()
        const newRange = productsController.calculateTimeRange(productCodes)
        const selectedTimePosition = dateMsToPosition(selectedDateTime, newRange.startDate, newRange.endDate)

        const {mapMode} = useMapStore.getState()
        const isSelectedTimeOutOfRange = selectedTimePosition < -2 || 100 < selectedTimePosition
        const isTimelinePositionCloseToNow = timelinePosition <= nowPosition && nowPosition <= (timelinePosition + 2)
        const isAvailableToUpdate = (isSelectedTimeOutOfRange || isTimelinePositionCloseToNow)
            && !isPlaying
            && !isLoading
            && mapMode === MapMode.live

        if (isAvailableToUpdate) {
            updateData()
            preloadTiles(true)
        }
    }

    const dateMsToPosition = (date, start, end) => (date - start) * 100 / (end - start)

    const updateData = () => {
        const productCodes = getProductCodes()
        const range = productsController.calculateTimeRange(productCodes)
        const timelinePosition = dateMsToPosition(range.now, range.startDate, range.endDate)

        useMapStore.setState({
            nowDateTime: range.now,
            timelinePosition,
            selectedDateTime: range.now,
            dateTimeRange: {
                startDate: range.startDate,
                endDate: range.endDate,
            }
        })
        productsController.setTime(range.now)
        productsController.setTimeRange(range)
    }

    const getProductCodes = () => {
        const productCodes = []

        selectedProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p.id))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
                productCodes.push(productCode)
            }
        })
        return productCodes
    }

    const reEnableWmsIfNeed = () => {
        if (!productsController) {
            return
        }

        const isNeedRePlay = isPlaying
        productsController.switchToTmsMode()

        setIsPlaying(false)
        setIsLoading(false)

        if (isNeedRePlay) {
            setIsLoading(true)
            productsController.switchToWmsMode(() => {
                setIsLoading(false)
                isNeedRePlay && setIsPlaying(true)
            })
        }
    }

    const preloadTiles = (force = false) => {
        const isNeedRePlay = isPlaying
        if (productsController.mode && !force) {
            return
        }

        setIsLoading(true)
        productsController.switchToWmsMode(() => {
            setIsLoading(false)
            isNeedRePlay && setIsPlaying(true)
        })
    }

    const clearPQPopups = () => {
        setClearPQ(!clearPQ)
    }

    const _handleProductSwitch = (prods) => {
        setSelectedProducts(prods)
    }

    const handlePlay = (isPause) => {
        if (!isPause) {
            setIsLoading(true)
            productsController.switchToWmsMode(() => {
                setIsLoading(false)
            })
        }
    }

    const handleChangeTime = (selectedTime, value) => {
        useMapStore.setState({
            selectedDateTime: selectedTime,
            timelinePosition: value,
        })
        useMapStore.getState().updateDefaultMapView()
        productsController?.setTime(selectedTime)
        map?.fire('selectedTime')
    }

    const handleNowClick = () => {
        setIsLoading(true)
        setIsPlaying(false)

        const productCodes = getProductCodes()
        const range = productsController.calculateTimeRange(productCodes)
        const timelinePosition = dateMsToPosition(range.now, range.startDate, range.endDate)

        useMapStore.setState({
            mapMode: MapMode.live,
            nowDateTime: range.now,
            timelinePosition,
            selectedDateTime: range.now,
            dateTimeRange: {
                startDate: range.startDate,
                endDate: range.endDate,
            }
        })

        productsController.setTime(range.now)
        productsController.setTimeRange(range)
        productsController.setProducts2(useMapStore.getState().selectedProducts)

        setIsLoading(false)
    }

    return (
        <>
            {(!locations || !products || locations.length < 1 || products.length < 1) &&
                <div className={styles.progressScreen}>
                    <CircularProgress/>
                    <div>
                        Loading...
                    </div>
                </div>
            }
            <Box className={'row gap0 fullHeight fullWidth'}>
                <MapSidebar
                    onSwitchProduct={_handleProductSwitch}
                    reEnableWmsIfNeed={reEnableWmsIfNeed}
                    products={products}
                    locations={locations}
                />
                <Box className={clsx("MapPage spacer", styles.wrapper)}>
                    <MapToolbar
                        onClearPqPopups={() => clearPQPopups()}
                    />
                    <div
                        className={styles.mapContainer}
                    >
                        <div
                            className={styles.map}
                            ref={mapElementRef}
                        />
                        <TimeLineMap
                            preloadTiles={preloadTiles}
                            isLoading={isLoading}
                            startDate={dateTimeRange.startDate}
                            endDate={dateTimeRange.endDate}
                            onChangeTime={handleChangeTime}
                            onPlayBtn={handlePlay}
                            onChangePlaying={setIsPlaying}
                            isPlaying={isPlaying}
                            selectedTime={selectedDateTime}
                            onNow={handleNowClick}
                        />
                        <div className={styles.toolsWrapper}>
                            <DistanceTool
                                activeTool={activeTool}
                                setActiveTool={() => setActiveTool('DistanceTool')}
                            />
                            <PinPointTool
                                activeTool={activeTool}
                                setActiveTool={() => setActiveTool('PinPointTool')}
                            />
                            <PointQueryTool
                                clear={clearPQ}
                                activeTool={activeTool}
                                setActiveTool={() => setActiveTool('PointQuery')}
                            />
                        </div>
                    </div>
                    <Legends
                        nonStandartProjection={NON_STANDARD_PROJECTION}
                    />
                </Box>
            </Box>
        </>
    )
}
