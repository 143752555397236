import {createSvgIcon} from "@mui/material/utils";

export default function ClockIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM17 12C17 12.553 16.552 13 16 13H12C11.448 13 11 12.553 11 12V7C11 6.447 11.448 6 12 6C12.552 6 13 6.447 13 7V11H16C16.552 11 17 11.447 17 12Z"
                fill="currentColor"/>
        </svg>, 'Clock')
    return <Icon {...props} data-cy="clock-icon"/>
}