import {Button, IconButton} from '@mui/material'
import React, {useRef, useState, useEffect} from 'react'
import MinusIcon from '../../../../shared/assets/icons/Minus'
import PauseIcon from '../../../../shared/assets/icons/PauseIcon'
import PlayIcon from '../../../../shared/assets/icons/PlayIcon'
import PlusIcon from '../../../../shared/assets/icons/Plus'
import DateRangePicker2 from "../../../../shared/ui/datePickers/DateRangePicker2/DateRangePicker2";
import styles from './Timeline.module.scss'
import {useStoreWithEqualityFn} from 'zustand/traditional'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'

const dayjs = require('dayjs')
const formatTime = 'ddd MMM D, YYYY h:mm A'

/**
 * TimeLineMap component for managing and displaying a timeline for map-based animations.
 *
 * @param {boolean} isPlaying - Indicates if the timeline animation is currently playing.
 * @param {boolean} isLoading - Indicates if the WMS (Web Map Service) is currently loading.
 * @param {number} startDate - The start date of the timeline, represented as a timestamp in milliseconds.
 * @param {number} endDate - The end date of the timeline, represented as a timestamp in milliseconds.
 * @param {number} selectedTime - The currently selected time on the timeline, represented as a timestamp in milliseconds.
 * @param {function} onChangeTime - Callback function invoked when the timeline value changes.
 * @param {function} onPlayBtn - Callback function invoked when the play button is toggled.
 * @param {function} onChangePlaying - Callback function invoked to change the playing state of the timeline.
 */
export function TimeLineMap({
    preloadTiles,
    isPlaying,
    isLoading,
    startDate,
    endDate,
    onChangeTime,
    onPlayBtn,
    onChangePlaying,
    selectedTime,
    onNow,
}) {
    const mapMode = useMapStore((state) => state.mapMode)
    const timelinePosition = useMapStore((state) => state.timelinePosition)
    const dateTimeRange = useMapStore((state) => state.dateTimeRange)

    const speedValues = [1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100]
    const animationIndex = useRef()
    const stepAnimation = useRef(1)

    const [currentSpeed, setCurrentSpeed] = useState(5)

    useEffect(() => {
        if (isPlaying && !isLoading) {
            onChangeTimelineValue(timelinePosition)
            animationIndex.current = window.setTimeout(_animateStep, (timelinePosition === 99 ? 2000 : speedValues[currentSpeed]))
        } else {
            clearTimeout(animationIndex.current)
        }
    }, [timelinePosition, isPlaying, isLoading])

    useEffect(() => {
        if (isPlaying) {
            return
        }

        const currentPosition = (selectedTime - startDate) * 100 / (endDate - startDate)
        onChangeTimelineValue(currentPosition)
    }, [selectedTime, startDate, endDate])

    function changeSpeed(step) {
        const newValue = currentSpeed + step
        if (newValue < 0) return
        if (newValue === speedValues.length) return
        setCurrentSpeed(newValue)
    }

    function handleSwitch() {
        if (timelinePosition > 97 && !isPlaying) {
            onChangeTimelineValue(0)
        }
        onPlayBtn(isPlaying)
        onChangePlaying(!isPlaying)
    }

    function _onChangeTimeLineValyByVal(event) {
        const val = event.target.value
        let currentPosition = parseInt(val)
        if (isNaN(currentPosition)) {
            return
        }
        if (currentPosition > 99) {
            currentPosition -= 100
        }
        if (currentPosition < 0) {
            currentPosition += 100
        }
        onTimeLineClick()
        onChangeTimelineValue(currentPosition)
    }

    function _animateStep() {
        let newVal = timelinePosition
        if (timelinePosition === 99) {
            newVal = 0
        } else {
            newVal += stepAnimation.current
            newVal = Math.min(99, newVal)
        }
        onChangeTimelineValue(newVal)
    }

    const handleTimelineMouseDown = () => {
        preloadTiles()
        onTimeLineClick()
    }

    function onTimeLineClick() {
        onChangePlaying(false)
        clearTimeout(animationIndex.current)
    }

    function onChangeTimelineValue(val) {
        if (isNaN(val) || !endDate || !startDate) return
        const timeInMs = startDate + (val / 100) * (endDate - startDate)
        onChangeTime(timeInMs, val)
    }

    function calculateScrubberThumbStyles(percent) {
        const timelineColor = 'rgba(255, 255, 255, 0)'
        return 'linear-gradient(to right,' + timelineColor + (percent - 0.3) + '%, var(--palette-primary-dark) ' + (percent - 1) + '% ' + (percent + 1) + '%,' + timelineColor + (percent + 0.3) + '% 100%)'
    }

    function calculateFutureOrPastStyles() {
        const currentTime = dayjs()
        const percentage = ((currentTime - startDate) / (endDate - startDate)) * 100
        return 'linear-gradient(to right, rgba(2, 28, 55, 0.075)' + percentage + '%, rgba(255, 255, 255, 0) ' + percentage + '%)'
    }

    function getDateValue() {
        const timeInMs = startDate + (timelinePosition / 100) * (endDate - startDate)
        return dayjs(timeInMs).format(formatTime)
    }

    const handleDateTimeRangeChange = dateTimeRange => {
        useMapStore.getState().setDateTimeRange(dateTimeRange)
    }

    return (
        <div className={styles.timeline}>
            <div className={styles.speedButtonsContainer}>
                <IconButton
                    size={'tiny'}
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => changeSpeed(1)}
                    disabled={currentSpeed >= speedValues.length - 1}
                >
                    <PlusIcon size={'small'}/>
                </IconButton>
                <IconButton
                    size={'tiny'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => changeSpeed(-1)}
                    disabled={currentSpeed <= 0}
                >
                    <MinusIcon size={'small'}/>
                </IconButton>
            </div>
            <IconButton
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={handleSwitch}
            >
                {isPlaying
                    ? <PauseIcon/>
                    : <PlayIcon/>
                }
            </IconButton>
            <div className={styles.scrubberContainer}>
                <input
                    className={styles.scrubber}
                    disabled={isLoading}
                    type="range"
                    min="0"
                    max="99"
                    style={{background: calculateScrubberThumbStyles(timelinePosition)}}
                    onMouseDown={handleTimelineMouseDown}
                    onChange={_onChangeTimeLineValyByVal}
                    value={timelinePosition}
                />
                <div
                    className={styles.futureOrPast}
                    style={{background: calculateFutureOrPastStyles()}}
                />
                <div className={styles.scrubberDetails}>
                    <div className={styles.datetime}>
                        {isLoading ? 'Loading...' : getDateValue()}
                    </div>
                    <div className={styles.mapMode}>
                        {mapMode} data
                    </div>
                </div>
            </div>
            <DateRangePicker2
                value={[dayjs(dateTimeRange.startDate), dayjs(dateTimeRange.endDate)]}
                onClick={() => onChangePlaying(false)}
                onChange={handleDateTimeRangeChange}
            />
            <Button
                onClick={onNow}
                color={'secondary'}
                variant={'outlined'}
            >
                Now
            </Button>
        </div>
    )
}

