import {Box, Button, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {isEmpty} from 'shared/libs/Utils'
import AgreeModal from 'shared/ui/AgreeModal'
import {SearchInput} from 'shared/ui/SearchInput'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import {WeatherDefinitionDetails} from '../../../../../entities/weatherDefinition/ui/WeatherDefinitionDetails/WeatherDefinitionDetails'
import {SeveritySelector} from '../../../../../features/weatherDefinition/SeveritySelector/SeveritySelector'
import EmptyStateDefinitions from '../../../../../shared/ui/emptyStates/EmptyStateDefinitions'
import ItemsSelectTopBar from '../../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import ProgressModal from '../../../../../shared/ui/ProgressModal'
import WeatherDefinitionLine from '../WeatherDefinitionLine/WeatherDefinitionLine'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'
import styles from './WeatherDefinitionsSettings.module.scss'
import {clsx} from 'clsx'

export default function WeatherDefinitionsSettings({editAlertConfig = false}) {
    const {
        alertConfigurations,
        alertConfigGroups,
        fetchAllInfoAboutAlertConfigurations,
    } = useAlertConfigurationsStore((state) => state)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const {
        weatherDefinitionIcons,
        openedWeatherDefinition,
        editingWeatherDefinition,
        weatherDefinitions,
        selectedWeatherDefinitions,
        isLoading,
        openWeatherDefinition,
        toggleEditingWeatherDefinition,
        fetchWeatherDefinitions,
        fetchWeatherDefinitionIcons,
        deleteSelectedWeatherDefinitions,
    } = useWeatherDefinitionsStore((state) => state)

    const [searchString, setSearchString] = useState('')
    const [severity, setSeverity] = useState('All')
    const [update] = useState(0)
    const [isDeleteWeatherDefinitionAgreeModalOpen, setIsDeleteWeatherDefinitionAgreeModalOpen] = useState(false)

    const allDefinitionsSelected = weatherDefinitions.length === selectedWeatherDefinitions.length
    const someDefinitionsSelected = !!weatherDefinitions.length && !allDefinitionsSelected

    const [query] = useSearchParams()

    useEffect(() => {
        fetchLocations()
        fetchWeatherDefinitionIcons()
        fetchWeatherDefinitions().then((data) => {
            const weatherDefinitionArg = query.get('weather_definition_id')
            if (weatherDefinitionArg) {
                const definitionId = parseInt(weatherDefinitionArg)
                const openDefinition = data.find((e) => e.id === definitionId)
                if (openDefinition) {
                    openWeatherDefinition(openDefinition)
                }
            }
        })
    }, [])

    useEffect(() => {
        fetchAllInfoAboutAlertConfigurations()
    }, [update])

    useEffect(() => () => {
        const weatherDefinitionArg = query.get('weather_definition_id')
        if (weatherDefinitionArg) {
            const url = new URL(window.location.href)
            url.searchParams.delete('weather_definition_id')
            window.history.pushState({}, null, url)
        }
    }, [])

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value)
    }

    useEffect(() => {
        if (!weatherDefinitions || !weatherDefinitionIcons) return
        const icons_obj = {}
        weatherDefinitionIcons.forEach((icon) => {
            icons_obj[icon.id] = icon
        })
        const definitions_obj = {}
        weatherDefinitions.forEach((definition) => {
            definition.icon_url = icons_obj[definition.icon]
            definitions_obj[definition.id] = definition
            definition.configurations = {}
        })
        const locations_obj = {}
        locations.forEach((location) => {
            locations_obj[location.id] = location
        })
        const configs_obj = {}
        alertConfigurations.forEach((config) => {
            configs_obj[config.id] = config
        })
        alertConfigGroups.forEach((relation) => {
            const config = configs_obj[relation[1]]
            if (!config) return
            const definition = definitions_obj[config.weather_definition_id]
            const location = locations_obj[config.location_id]
            if (!definition || !location) return
            if (!definition.configurations[relation[0]]) definition.configurations[relation[0]] = []
            definition.configurations[relation[0]].push(location)
        })
    }, [weatherDefinitions, weatherDefinitionIcons, locations, alertConfigurations, alertConfigGroups])

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim())
    }

    const handleActionsWithTopBar = (wd, action) => {
        if (action === 'delete') {
            setIsDeleteWeatherDefinitionAgreeModalOpen(true)
        }
    }

    const handleNewWeatherDefinition = () => {
        toggleEditingWeatherDefinition({})
    }

    const confirmDeleteWeatherDefinitions = async (agree) => {
        if (agree) {
            await deleteSelectedWeatherDefinitions()
        }
        setIsDeleteWeatherDefinitionAgreeModalOpen(false)
    }

    const s = searchString.toLocaleLowerCase()
    return (
        <div
            data-cy={'weather-definition-settings'}
            className={styles.wrapper}
        >
            <div
                style={{display: openedWeatherDefinition || editingWeatherDefinition ? 'none' : 'flex'}}
                className="settings"
            >
                <div className={clsx('settings-toolbar', styles.headerWrapper)}>
                    <h3>
                        Weather definitions
                    </h3>
                    <SearchInput
                        value={searchString}
                        onChange={searchStringChanged}
                        placeholder={'Search'}
                    />

                    <SeveritySelector
                        severity={severity}
                        onChange={handleSeverityChange}
                    />

                    <Button
                        data-cy={'new-definition-button'}
                        variant='contained'
                        sx={{marginLeft: 'auto'}}
                        onClick={handleNewWeatherDefinition}
                    >
                        Create custom definition
                    </Button>
                </div>

                <ItemsSelectTopBar
                    onAction={handleActionsWithTopBar}
                    visible={!!selectedWeatherDefinitions.length}
                    allSelected={allDefinitionsSelected}
                    indeterminate={someDefinitionsSelected}
                />

                {isEmpty(Object.values(weatherDefinitions))
                    ? <Box sx={{width: '100%', height: '100%'}}>
                        <EmptyStateDefinitions
                            title={'Weather definitions will appear here'}
                            text={'You will see weather definitions for alerts here once they are created'}
                        />
                    </Box>
                    : <Box className={styles.weatherDefinitionList}>
                        {weatherDefinitions.map((wd) => {
                            if ((!s || wd.name.toLocaleLowerCase().indexOf(s) >= 0) && (wd.severity === severity || severity === 'All')) {
                                return (
                                    <WeatherDefinitionLine
                                        key={wd.id}
                                        definition={wd}
                                        selected={selectedWeatherDefinitions.includes(wd)}
                                    />
                                )
                            }
                            return undefined
                        })}
                    </Box>}
            </div>
            {openedWeatherDefinition && !editingWeatherDefinition && !editAlertConfig &&
                <WeatherDefinitionDetails
                    definition_id={openedWeatherDefinition.id}
                    editable={true}
                />
            }
            {/* // TODO: add queue mechanism to avoid long conditions.
                Faced with a problem two (or more) EditWeatherDefinitionPage were rendered at the same time
                and tests won't pass. Because of multiple indendical buttons and inputs
            */}
            {isDeleteWeatherDefinitionAgreeModalOpen &&
                <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{alignContent: 'stretch', overflow: 'hidden', '&.MuiBox-root': {width: '100%'}}}
                        >
                            <Typography sx={{fontSize: '18px'}}>
                                {`Are you sure you want delete ${selectedWeatherDefinitions.length} weather definitions and all alert configurations and action items linked to them?`}
                            </Typography>
                            <Box
                                sx={{
                                    maxHeight: '120px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    overflow: 'auto',
                                    gap: '0 16px',
                                }}
                            >
                                {selectedWeatherDefinitions.map((definition) => (
                                    <span>
                                •
                                        {' '}
                                        {definition.name}
                                        <br/>
                                    </span>))}
                            </Box>
                        </Box>,
                        title: 'Delete multiple weather definitions',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: confirmDeleteWeatherDefinitions,
                    }}
                />
            }
            <ProgressModal visible={isLoading}/>
        </div>
    )
}
