import React, { useEffect } from 'react';
import './MapLibreLocation.css';
import './style.css';
import 'maplibre-gl/dist/maplibre-gl.css'

//const maplibregl = require('maplibre-gl');
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';      // ! is important here
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';

maplibregl.workerClass = maplibreglWorker;

function _transformRequestMutator(url, type) {
    if ((url.indexOf('cdnmaps.velocityweather') > -1) || (url.indexOf('vmaps.velocityweather.com') > -1)) {
        const headers = {
            'X-VW-key': window.requester.getKey(),
            'X-VW-sig': window.requester.sign.sig,
            'X-VW-ts': window.requester.sign.ts
        }
        return { url, type, headers };
    }
}

let marker = new maplibregl.Marker({
    color: "#e50112",
});
let map;
let markersForLocations = {};
let markersForSelectedLocations = {};

const defaultZoom = 4;

export const MapLibreLocationStatic = ({onChange, onSelectLocation = () => {}, coordinates, editMode, locations, selectedLocations, chosenCoords, mapPositionRef, lastCreatedLocations}) => {
    useEffect(() => {
        window.requester.isready(() => {
            componentDidMount();
        });
        return () => {
            if (map && mapPositionRef) {
                map.off('zoomend');
                map.off('moveend');
                map.remove()
                map = null
            }
        };
    }, [])

    useEffect(()=>{
        window.requester.isready(() => setTimeout(() => {
            coordinates && marker.setLngLat(coordinates);
            coordinates && map.panTo(coordinates);
        }, 10));
    },[coordinates]);

    useEffect(()=>{
        window.requester.isready(() => setTimeout(() => {
            if (editMode===1 && map){
                if (chosenCoords) {
                    marker.setLngLat(coordinates).addTo(map);
                }
                else marker.remove();
                map.on('click', onClickMap);
            }else{
                marker.remove();
                map.off('click', onClickMap);
            }
        }, 100));
    }, [editMode, chosenCoords]);

    useEffect(() => {
        window.requester.isready(() => {
            const updateMarkers = (locations, markers, color, lastCreated, eventType) => {
                let mm = {};
                //Removing markers that are no longer needed
                for (let id in markers) {
                    if (!locations.some(loc => loc.id === id)) {
                        markers[id].remove();
                        delete markers[id];
                    }
                }

                //Updating or creating markers
                locations?.forEach((loc) => {
                    const { id, coordinates, label } = loc;
                    const scale = lastCreated?.some(lastLoc => lastLoc.id === id) ? 0.7 : 0.4;
                    let marker = markers[id];

                    if (marker) {
                        marker.setLngLat(coordinates);
                        delete markers[id];
                    } else {
                        marker = new maplibregl.Marker({ color, scale });
                        const element = marker.getElement();
                        element.setAttribute('data-cy', 'location-marker');
                        element.addEventListener('click', (event) => {
                            event.stopPropagation();
                            map.fire(eventType, { location: loc });
                        });
                        marker.setLngLat(coordinates).addTo(map);
                    }

                    marker.getElement().setAttribute("title", label);
                    mm[id] = marker;
                });

                //Removing remaining old markers
                for (let id in markers) {
                    markers[id].remove();
                }

                return mm;
            };

            markersForLocations = updateMarkers(
                locations,
                markersForLocations,
                'var(--palette-primary-dark)',
                lastCreatedLocations,
                'selectLocation'
            );

            markersForSelectedLocations = updateMarkers(
                selectedLocations,
                markersForSelectedLocations,
                'var(--palette-primaryRed-light)',
                lastCreatedLocations,
                'selectLocation'
            );
        });
    }, [locations, selectedLocations, lastCreatedLocations]);

    function onClickMap(event){
        onChange(event.lngLat.toArray());
    }

    function componentDidMount() {
        markersForSelectedLocations = {};
        map = new maplibregl.Map({
            container: 'map',
            style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
            hash: false,
            transformRequest: _transformRequestMutator,
            minZoom: 2,
            zoom:  mapPositionRef?.current.zoom || defaultZoom,
        });
        map.on('selectLocation', ({location}) => {
            onSelectLocation(location)
        })
        map.setCenter(coordinates);

        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();

        if(mapPositionRef) {
            map.on('zoomend', (e) => {
                mapPositionRef.current.zoom = e.target.getZoom();
            });
            map.on('moveend', () => {
                mapPositionRef.current.position = map.getCenter();
            });
        }

        map.addControl(new maplibregl.NavigationControl({
            showCompass: false
        }), 'bottom-left');
    }

    return (
        <div style={{width: '100%', 'height': '100%'}}>
            <div data-cy={'map'} id="map" style={{borderRadius: '8px'}}></div>
        </div>
    );

};
