import React, {useState} from "react";
import {Typography, IconButton, Divider, Popover, Button} from "@mui/material";
import dayjs from "dayjs";
import CalendarIcon from "../../../assets/icons/Calendar";
import DateTimeRangeCalendar from "../DateTimeRangeCalendar/DateTimeRangeCalendar";
import InfoCircleIcon from "../../../assets/icons/InfoCircle";

export default function DateRangePicker2({
                                                checkError = (range) => {
                                                    if (range[0] && range[1]) {
                                                        const diffInHours = range[1].diff(range[0], 'hour');
                                                        return {
                                                            value: diffInHours > 72,
                                                            message: 'The time range must not exceed 72 hours.',
                                                        };
                                                    }
                                                    return { value: false, message: '' };
                                                },
                                                value = [null, null],
                                                onClose = () => {},
                                                onChange = () => {},
                                                onOpen = () => {},
                                                onClick = () => {},
                                            }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [error, setError] = useState({ value: false, message: '' })

    const [dateRange, setDateRange] = useState(value || [
        dayjs().subtract(1, "month"),
        dayjs(),
    ])

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
        onOpen()
        onClick()
    }

    const handleClose = () => {
        setAnchorEl(null)
        onClose()
    }

    const handleChange = (newRange) => {
        const errorResult = checkError(newRange)
        setError(errorResult)

        if (!errorResult.value) {
            setDateRange(newRange)
        }
    }

    const handleSubmit = () => {
        if (!error.value) {
            onChange(dateRange)
            handleClose()
        }
    }

    const open = Boolean(anchorEl)
    const id = open ? 'calendar-popover' : undefined

    return (
        <div>
            <IconButton
                aria-describedby={id}
                onClick={handleOpen}
                variant="outlined"
                color="secondary"
            >
                <CalendarIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: {
                        mt: -2,
                        background: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <div className="dateTimeRangePicker">
                    {error.value && <Typography color="error" style={{maxWidth:"250px"}}>{error.message}</Typography>}
                    <div className="row gap16" style={{ alignItems: 'flex-start', maxHeight: '529px' }}>
                        <DateTimeRangeCalendar
                            value={value}
                            onChange={handleChange}
                            showDaysOutsideCurrentMonth={false}
                            defaultValue={value[0] || dayjs().subtract(1, 'month')}
                        />
                    </div>
                    <Divider />
                    <div className="dateTimeRangePicker-toolbar">
                        <Button
                            onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            size="small"
                            disabled={error.value || dateRange.some(date=> date === null)}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

