import React, {useEffect, useRef, useState} from 'react'
import {SingleSiteRadar} from '../../../features/textProducts'
import BaronStormTracksGroup from '../../../features/textProducts/ui/baronStormTracks/BaronStormTracksGroup'
import MapOverlays from '../../../features/textProducts/ui/MapOverlays/MapOverlays'
import LocationGroup from './LocationGroup/LocationGroup'
import ProductGroup from './ProductGroup/ProductGroup'

export const KEYS = {
    products: {
        groupKey: 'product_group',
        nameLine: 'name',
    },
    locations: {
        groupKey: 'location_group',
        nameLine: 'label',
    },
}

const _grouping = (objects, FIELDS) => {
    const g = {}
    objects && objects.forEach((obj) => {
        g[obj[FIELDS.groupKey]]
            ? g[obj[FIELDS.groupKey]].push(obj)
            : g[obj[FIELDS.groupKey]] = [obj]
    })
    return g
}

export function GroupedFilter(
    {
        reEnableWmsIfNeed,
        objects,
        typeFilter = 'productsFilter',
    }) {

    const [groups, setGroups] = useState({})
    const FIELDS = (typeFilter === 'locations') ? KEYS.locations : KEYS.products
    const scrollContainerRef = useRef(null)

    useEffect(() => {
        setGroups(_grouping(objects, FIELDS))
    }, [objects])

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem(`scrollPosition-${typeFilter}`)

        const observer = new MutationObserver(() => {
            if (scrollPosition) {
                scrollContainerRef.current.scrollTop = Number(scrollPosition)
                observer.disconnect()
            }
        })

        observer.observe(scrollContainerRef.current, {childList: true, subtree: true})

        const saveScroll = () => {
            sessionStorage.setItem(`scrollPosition-${typeFilter}`, scrollContainerRef.current.scrollTop)
        }

        scrollContainerRef.current.addEventListener('scroll', saveScroll)

        return () => {
            observer.disconnect()
            scrollContainerRef.current?.removeEventListener('scroll', saveScroll)
        }
    }, [typeFilter])

    return (
        <div
            ref={scrollContainerRef}
            style={{overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '8px'}}
        >
            {Object.keys(groups).map((groupName) => {
                const group = groups[groupName]

                if (groupName === 'Single Site Radar') {
                    return (
                        <SingleSiteRadar
                            reEnableWmsIfNeed={reEnableWmsIfNeed}
                            key={groupName}
                            group={group}
                            scrollContainerRef={scrollContainerRef}
                        />
                    )
                } else if (groupName === 'Map Overlays') {
                    return (
                        <MapOverlays
                            key={groupName}
                            FIELDS={FIELDS}
                            groupName={groupName}
                            group={group}
                        />
                    )
                } else if (groupName === 'Baron Storm Tracks') {
                    return (
                        <BaronStormTracksGroup
                            key={groupName}
                            groupName={groupName}
                            group={group}
                        />
                    )
                } else if (typeFilter === 'productsFilter') {
                    return (
                        <ProductGroup
                            key={groupName}
                            FIELDS={FIELDS}
                            groupName={groupName}
                            group={group}
                            groups={groups}
                        />
                    )
                }

                return (
                    <LocationGroup
                        key={groupName}
                        groupName={groupName}
                        group={group}
                        groups={groups}
                        FIELDS={FIELDS}
                    />
                )
            })}
        </div>
    )
}
