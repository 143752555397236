//params { signature - Signature from requester3}
export async function textProductsRequester({ path, signature, params}) {
    if (!signature) return;

    const url = signature.makeUrl({ path, params });
    return fetch(url).then(response => response.json()).
        then(d =>{
            const keysParams = Object.keys(d);
            if (!keysParams.length) return;
            d = d[keysParams[0]];
            if (!d || !d.data || !d.meta) return;
            if (d.meta.pages === 1 || !d.meta.pages) {
                return d.data;
            }
            let arrPrm = [];
            let totalArr = [];
            if (d.meta.pages>1 && d.meta.from) {
                params.from = d.meta.from;
            }
            for (let page = 2; page <= d.meta.pages; page++ ){
                arrPrm.push(getDataPage(path, params, page, signature))
            }
            return Promise.all(arrPrm).then((pages) => {
                pages.forEach((data) => {
                    if(data){
                        data.forEach((d)=>{totalArr.push(d)})
                    }
                })
                return totalArr;
            });
        }).catch(console.log);
}

async function getDataPage(path, params, page, signature) {
    const url = signature.makeUrl({ path, params: Object.assign({page}, params) });
    return fetch(url).
        then(response => response.json()).
        then(data => {
            const keysParams = Object.keys(data);
            if (!keysParams.length) return;
            data = data[keysParams[0]];
            return data ? data.data : undefined;
        }).catch(console.log);
}