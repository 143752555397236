import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Typography} from '@mui/material'
import React from 'react'
import {useStoreWithEqualityFn} from 'zustand/traditional'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'
import {CheckBox} from '../../../../shared/ui/CheckBox'
import {HistoricalProductCodes} from "../../model/Tile/ProductsController";
import styles from "./ProductGroup.module.scss"
import {clsx} from "clsx";

const ProductGroup = ({groupName, group, groups, FIELDS}) => {
    const mapMode = useMapStore((state) => state.mapMode)
    const selectedProducts = useStoreWithEqualityFn(
        useMapStore,
        (state) => state.selectedProducts,
        (prev, next) => {
            const isPrevGroup = prev.some((product) => product.product_group === groupName)
            const isNextGroup = next.some((product) => product.product_group === groupName)

            return !(isPrevGroup || isNextGroup)
        },
    )
    const toggleProduct = useMapStore((state) => state.toggleProduct)

    const isProductSelected = (product) => !!selectedProducts.find((enabledProduct) => enabledProduct.id === product.id)

    const isProductEnabled = (product) => mapMode === MapMode.historical ? product.isHistorical : true

    return (
        <Accordion
            variant={'dark'}
            defaultExpanded
            key={groupName}
            data-cy={groupName}
        >
            <AccordionSummary
                variant={'dark'}
                sx={{height: '32px'}}
            >
                <Box className={'row gap4 fullWidth'}>
                    <Typography className={styles.accordionSummaryContent}>
                        {`${groupName} (${group.length})`}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails variant={'dark'}>
                {groups[groupName].map((product) =>
                    <Box
                        className={clsx('row gap4 fullWidth', styles.accordionDetailsContent)}
                        key={`${FIELDS.nameLine}${product.id}`}
                    >
                        <FormControlLabel
                            disabled={!isProductEnabled(product)}
                            label={product[FIELDS.nameLine]}
                            control={
                                <CheckBox
                                    checked={isProductSelected(product)}
                                    onChange={() => toggleProduct(product)}
                                />
                            }
                        />
                    </Box>,
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default ProductGroup
