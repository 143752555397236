import {Button} from "@mui/material";
import React from "react";
import ArrowDownSmallIcon from "../../../assets/icons/ArrowDownSmall";
import {Spacer} from "../../Spacer";
import {CalendarView} from "../DateTimeRangeCalendar/DateTimeRangeCalendar";

const DropdownButton = ({onClick, open, ...other}) => {
    return (
        <Button
            {...other}
            variant={''}
            color={''}
            size={'small'}
            onClick={onClick}
            endIcon={
                <ArrowDownSmallIcon
                    pointerEvents="none"
                    size={'small'}
                    sx={{transform: open ? 'rotate(180deg)' : 'rotate(0deg)'}}
                />
            }
            sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginRight: '-6px',
            }}
        />
    )
}

export default function CalendarToolbar({view, value, slotProps = {}}) {
    return (
        <div className={'row'} style={{width: '100%', gridColumn: 2}}>
            <DropdownButton
                {...slotProps.month?.button}
                open={view === CalendarView.month}
                data-cy={'month-selector-button'}
            >
                <div className={'paragraph medium'}>
                    {value?.format('MMM')}
                </div>
            </DropdownButton>
            <Spacer/>
            <DropdownButton
                {...slotProps.year?.button}
                open={view === CalendarView.year}
                data-cy={'year-selector-button'}
            >
                <div className={'paragraph medium'}>
                    {value?.format('YYYY')}
                </div>
            </DropdownButton>
        </div>
    )
}