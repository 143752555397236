import {Box, Button} from '@mui/material';
import React from 'react';
import {Spacer} from "shared/ui/Spacer";
import cloud from '../../shared/assets/svg/cloud.svg';
import cloud_1 from '../../shared/assets/svg/cloud_1.svg';
import moon from '../../shared/assets/svg/moon.svg';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import 'pages/Auth.css';
import 'pages/notFound/NotFoundPage.css';
import '../login/Login.css';


export default function NotFoundPage() {
    return (
        <div className={'background'}>
            <div className={'dawnGradient'}>
                <img className={'cloud_0'} src={cloud} alt={'cloud'}/>
                <div className={'cloudMoonArea'}>
                    <img className={'moon'} src={moon} alt={'moon'}/>
                    <img className={'cloud_1'} src={cloud_1} alt={'cloud_1'}/>
                </div>
                <img className={'cloud_2'} src={cloud} alt={'cloud'}/>
                <img className={'cloud_3'} src={cloud} alt={'cloud'}/>
                <div className={'container'}>
                    <div className={'leftContainer'}>
                        <div className={'alreadySignedUpContainer'}>
                            <div className={'notFoundContainer'}>
                                <div className={'error_404'}>
                                    404
                                </div>
                                <div>
                                    <div className={'title'}>
                                        Something`s missing
                                    </div>
                                    <div className={'description'}>
                                        This page is missing or you assembled the link incorrectly
                                    </div>
                                </div>
                                <Spacer/>
                                <Button
                                    href={'/alerts'}
                                    variant={'outlined'}
                                    startIcon={<ArrowLeftSmallIcon size={'medium'}/>}
                                    sx={{width: "200px"}}
                                >
                                    Back to home
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}