import React, {useState} from 'react'
import {Box, Divider, FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup} from '@mui/material'
import InfoPanel from '../../../../../../shared/ui/InfoPanel/InfoPanel'
import ActionItemsAdvancedList from '../../../../../../pages/settings/ui/actionItems/ActionItemsAdvancedList/ActionItemsAdvancedList'
import AdvancedReportingStepScreen from '../AdvancedReportingStepScreen/AdvancedReportingStepScreen'
import styles from "./ReportingStepScreen.module.scss"

function ReportingStepScreen({isIncludeReport, includeReportOnChange, chosenActionItems, onChangeActionItem, forecastInReport, onChangeForecastInReport}) {
    const [viewMode, setViewMode] = useState('general') // general or advanced

    const handleChangeViewMode = (event, newVal) => {
        if (!newVal) {
            return
        }
        setViewMode(newVal)
    }

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.upperBlockWrapper}>
                <Box className={styles.reportIncludeBlock}>
                    <FormGroup>
                        <FormControlLabel
                            label={<div className={styles.reportIncludeBlockLabel}>Include a detailed report with notification</div>}
                            control={<Switch
                                onChange={includeReportOnChange}
                                checked={isIncludeReport}
                                     />}
                        />
                    </FormGroup>
                </Box>
                <InfoPanel
                    title={'Report settings'}
                    text={'These report settings are global for the selected locations and weather definitions. Each notification for this alert will include a report link.'}
                    isOpen={isIncludeReport}
                />
            </Box>
            {isIncludeReport &&
                <>
                    <Divider/>
                    <Box className={styles.reportSettingsWrapper}>
                        <ToggleButtonGroup
                            className={styles.reportSettings}
                            value={viewMode}
                            exclusive
                            size={'small'}
                            onChange={handleChangeViewMode}
                        >
                            <ToggleButton
                                className={styles.reportSettingsValue}
                                variant={'secondary'}
                                value={'general'}
                                size={'small'}
                            >
                                General settings
                            </ToggleButton>
                            <ToggleButton
                                className={styles.reportSettingsValue}
                                variant={'secondary'}
                                value={'advanced'}
                                size={'small'}
                                disabled
                            >
                                Advanced settings
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {viewMode==='general'
                            ? <ActionItemsAdvancedList
                                chosenActionItems={chosenActionItems}
                                onChangeActionItem={onChangeActionItem}
                              />
                            : <AdvancedReportingStepScreen forecastInReport={forecastInReport} onChangeForecastInReport={onChangeForecastInReport}/>}
                    </Box>
                </>
            }

        </Box>
    )
}

export default ReportingStepScreen
