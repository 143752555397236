import {rasterMetaData} from "shared/libs/mapProducts/RasterMeta";

const PREFIX_SOURCE = 'source-tiles';
const DELTA_OLD_DATA = 3; // 2 times range for mark data is not match to exist layers

export class TileLayer {

    constructor(options) {
        /*
            id: string,
            map: map,
            startDate: timeInMs,
            endDate: timeInMs,
            signature: new Signature (requester3),
            [opacity]: [0,...,1],
            [firstLabelLayerId]: string
        */
        this.signature = options.signature;
        this.map = options.map;
        this.id = options.id;
        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.time = options.time;
        this.opacity = options.opacity || 1;
        this.options = options
        this.firstLabelLayerId = options.firstLabelLayerId || 'building-top';
        this.meta = {
            forecastTime: undefined,
            times: []
        };
        this.isShowing = true;
        this.onInit = options.onInit;
        this._initialized = false;
        this._updateMetaData();
    }

    update() {
        this._initialized = false
        this._updateMetaData()
    }

    _onUpdateMeta() {
        if (!this.meta.times || !this.meta.times.length) {
            return false;
        }
        if (!this._initialized) {
            this._initialized = true;
            this.time = this._getClosestTime(this.time);
            if (!this.time) {
                return false
            }
            this._render();
            this.onInit && this.onInit();
        }
        if (this.meta.times.length <= 1) {
            return false;
        }
        const t1 = new Date(this.meta.times[this.meta.times.length - 1]).getTime();
        const t2 = new Date(this.meta.times[0]).getTime();
        const increasedMediumDelta = Math.round(1.1 * Math.abs((t1 - t2) / (this.meta.times.length - 1)));
        this._updaterIndex = window.setTimeout(() => {
            this._updateMetaData();
        }, increasedMediumDelta);

        return true
    }

    _updateMetaData() {
        window.clearTimeout(this._updaterIndex);
        rasterMetaData({
            productId: this.id,
            signature: this.signature,
            startDate: this.startDate,
            endDate: this.endDate,
        }).then(meta => {
            if (!meta) {
                console.log('Update meta data failed. Product: ', this.id);
                return;
            }
            this.meta = meta;
            if (this._onUpdateMeta()) {
                this.map.fire('layerLoaded')
            }
        });
    }

    _render() {
        this._clearLayer();
        this._clearSource();
        if (!this.time || !this.isShowing) return;
        this._enable(this.meta.forecastTime ?
            `{baron_tile}${this.id.replace("/", "+")}+${this.meta.forecastTime}/{z}/{x}/{y}.png?valid_time=${this.time}&` :
            `{baron_tile}${this.id.replace("/", "+")}+${this.time}/{z}/{x}/{y}.png?`);
    }

    _enable(url) {
        const sourceId = PREFIX_SOURCE + this.id;

        this.map.addSource(sourceId, createSourceObjectMapLibre(url));

        this.map.addLayer(
            createLayerObjectMapLibre({id: this.id, opacity: 0}),
            this.firstLabelLayerId
        );

        const handleIdle = () => {
            this.handleLayerLoaded(sourceId, handleIdle)
        }

        this.map.on("idle", handleIdle)
    }

    _getClosestTime(time) {
        time = new Date(time).getTime(); // just normalize all time formats
        if (!this.meta.times || !this.meta.times.length) {
            return;
        }
        if (this.meta.times.length === 1) {
            return this.meta.times[0];
        }
        const tolerance = (this.endDate - this.startDate) / 99; // 100 is step counts on timeline
        const actualDate = new Date().getTime();
        if (this.meta.forecastTime) {
            if (actualDate > time + tolerance) {
                return; // not forecast date for forecast product
            }
        } else {
            if (actualDate < time - tolerance) {
                return; // not current date for current product
            }
        }

        let delta = Number.MAX_VALUE;
        let selectedTime;
        this.meta.times.forEach(t => {
            const currentDelta = Math.abs(new Date(t).getTime() - time);
            if (currentDelta < delta) {
                delta = currentDelta;
                selectedTime = t;
            }
        });

        const t1 = new Date(this.meta.times[this.meta.times.length - 1]).getTime();
        const t2 = new Date(this.meta.times[0]).getTime();
        const increasedMediumDelta = Math.round(DELTA_OLD_DATA * Math.abs((t1 - t2) / (this.meta.times.length - 1)));
        if (increasedMediumDelta < Math.abs(new Date(selectedTime).getTime() - time)) {
            return; // not actual match
        }

        return selectedTime;
    }

    _clearLayer() {
        const l = this.map.getLayer(this.id);
        l && this.map.removeLayer(this.id);
    }

    _clearSource() {
        const s = this.map.getSource(PREFIX_SOURCE + this.id);
        s && this.map.removeSource(PREFIX_SOURCE + this.id);
    }

    hide() {
        this.isShowing = false;
        this._clearLayer();
    }

    show() {
        this.isShowing = true;
        const sourceId = PREFIX_SOURCE + this.id;
        const s = this.map.getSource(sourceId);
        if (!s) return;
        const l = this.map.getLayer(this.id);
        if (l) return;

        this.map.addLayer(
            createLayerObjectMapLibre({id: this.id, opacity: 0}),
            this.firstLabelLayerId
        );

        const handleIdle = () => {
            this.handleLayerLoaded(sourceId, handleIdle)
        }

        this.map.on("idle", handleIdle)
    }

    handleLayerLoaded(sourceId, handler) {
        const source = this.map.getSource(sourceId);
        if (!source) {
            return
        }

        const layer = this.map.getLayer(this.id)
        if (!layer) {
            return
        }

        if (this.map.isSourceLoaded(sourceId)) {
            this.map.setPaintProperty(this.id, "raster-opacity", this.opacity);
            this.map.off("idle", handler);
        }
    }

    disable() {
        window.clearTimeout(this._updaterIndex);
        this.hide();
        this._clearSource();
    }

    setOpacity(opacity) {
        this.opacity = opacity;
        const layer = this.map.getLayer(this.id);
        layer && this.map.setPaintProperty(this.id, 'raster-opacity', this.opacity);
    }

    setTimeRange({startDate, endDate}) {
        this.endDate = endDate || this.endDate;
        if ((this.startDate !== startDate) && startDate) {
            this.startDate = startDate;
            this._updateMetaData();
        }
    }

    setTime(time) {
        if (!this._initialized) {
            this.time = time;
            return;
        }
        const prevTime = this.time;
        this.time = this._getClosestTime(time);
        if (prevTime !== this.time && this.isShowing) {
            this._render();
        }
    }

    async getPointQuery(lat, lon, timeRequest) {
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `point/${this.id}/${time}.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ? closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
        return fetch(url)
            .then(response => response.json())
            .then(data => {
                return {
                    id: this.id,
                    data
                }
            }).catch(() => {
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }

    async getPointQueryForWatchesAndWarningsText(lat, lon, timeRequest) {
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `reports/alert/all-poly/point.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ? closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
        return fetch(url)
            .then(response => response.json())
            .then(data => {
                return {
                    id: this.id,
                    data: {type: data.alert.data[0].type, text: data.alert.data[0].text}
                }
            }).catch(() => {
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }

}

function createLayerObjectMapLibre({id, opacity}) {
    return {
        "id": id,
        "type": "raster",
        "source": PREFIX_SOURCE + id,
        "minzoom": 2,
        "maxzoom": 22,
        "scheme": "tms",
        "paint": {
            "raster-opacity": opacity
        }
    }
}

function createSourceObjectMapLibre(url) {
    return {
        "type": "raster",
        "tiles": [url],
        "tileSize": 256,
        "attribution": "",
        "scheme": "tms",
        "tilejson": "1.0.0"
    }
}

export class SSRTileLayer extends TileLayer {

    constructor(options) {
        super(options);
        this.firstLabelLayerId = 'tunnel-service-track-casing';
        this.isSSRLayer = true;
        this.SSR = map.customSettingSSR;
        this.onStationChange = this.onStationChange.bind(this)

        if (this.SSR) {
            this._updateMetaData();
        } //TODO: solve refresh on dragend*
        options.map.on('change_customSettingSSR', this.onStationChange);
    }

    onStationChange() {
        this.meta = {
            forecastTime: undefined,
            times: []
        };
        this.SSR = map.customSettingSSR;
        this._initialized = false;
        this._clearLayer()
        this.time = this.options.time

        this._updateMetaData();
    }

    disable() {
        super.disable();
        window.map.off('change_customSettingSSR', this.onStationChange)
    }

    _updateMetaData() {
        if (!this.SSR || this.SSR !== window.map.customSettingSSR) return;
        window.clearTimeout(this._updaterIndex);
        rasterMetaData({
            productId: this.id.replace('{SSR}', this.SSR),
            signature: this.signature,
            startDate: this.startDate
        }).then(meta => {
            if (!meta) {
                window.map.fire('actualDataNotFoundNexrad', {site: this.SSR})
                return;
            }
            this.meta = meta;
            const updated = this._onUpdateMeta()
            if (updated && this.time) {
                window.map.fire('dataFoundNexrad', {site: this.SSR})
            } else {
                window.map.fire('actualDataNotFoundNexrad', {site: this.SSR})

            }
        });
    }

    show() {
        if (!this.isShowing) {
            this.isShowing = true;
            this._render();
        }
    }

    _render() {
        if (!this.SSR) return;
        this._clearLayer();
        this._clearSource();
        if (!this.time || !this.isShowing) return;
        this._enable(this.meta.forecastTime ?
            `{baron_tile}${this.id.replace('{SSR}', this.SSR).replace("/", "+")}+${this.meta.forecastTime}/{z}/{x}/{y}.png?valid_time=${this.time}&` :
            `{baron_tile}${this.id.replace('{SSR}', this.SSR).replace("/", "+")}+${this.time}/{z}/{x}/{y}.png?`);
    }

    async getPointQuery(lat, lon, timeRequest) {
        if (!this.SSR) {
            return {
                id: this.id,
                data: undefined
            }
        }
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `point/${this.id.replace('{SSR}', this.SSR)}/${time}.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ? closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
        return fetch(url)
            .then(response => response.json())
            .then(data => {
                return {
                    id: this.id,
                    data
                }
            }).catch(() => {
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }
}