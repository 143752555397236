import React, {useEffect, useState} from 'react'
import styles from './DashboardHourlyTable.module.scss'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {
    fetchHourlyForecast,
    fetchWavePeriod,
    fetchWavePeriodProductInstances,
    fetchWaveHeight,
    fetchWaveHeightsProductInstances,
    fetchVisibility,
    fetchBaronHiresVisibilityProductInstances,
    fetchSignificantWaveHeightsProductInstances,
    fetchSignificantWaveHeight,
} from '../../alerts/api/FetchAlerts'
import {clsx} from 'clsx'
import WindDirectionArrowIcon from '../../../shared/assets/icons/WindDirectionArrow'
import CircularProgress from "@mui/material/CircularProgress";

function createData(weatherConditions, temperature, dewPoint, averageWindSpeed, maxWindSpeed, gustSpeed, significantWaveHeight, maxWaveHeight, wavePeriod, precipitationProbability, cloudCover, visibility) {
    return {
        weatherConditions,
        temperature,
        dewPoint,
        averageWindSpeed,
        maxWindSpeed,
        gustSpeed,
        significantWaveHeight,
        maxWaveHeight,
        wavePeriod,
        precipitationProbability,
        cloudCover,
        visibility,
    }
}

export default function DashboardHourlyTable({location, units}) {
    const [weatherDataByDate, setWeatherDataByDate] = useState({})
    const [isMetric] = useState(units==="metric")

    let temperature = isMetric ? "Temperature (°C)" : "Temperature (°F)"
    let dewPoint = isMetric ? "Dew point temperature (°C)" : "Dew point temperature (°F)"
    let averageWindSpeed = isMetric ? "Average wind speed (km/h)" : "Average wind speed (mph)"
    let maxWindSpeed = isMetric ? "Max wind speed (km/h)" : "Max wind speed (mph)"
    let gustSpeed = isMetric ? "Gust speed (km/h)" : "Gust speed (mph)"
    let significantWaveHeight = isMetric ? "Significant Wave Height (m)" : "Significant Wave Height (ft)"
    let maxWaveHeight = isMetric ? "Max wave height (m)" : "Max wave height (ft)"

    const headers = [
        'Weather Conditions',
        temperature,
        dewPoint,
        averageWindSpeed,
        maxWindSpeed,
        gustSpeed,
        significantWaveHeight,
        maxWaveHeight,
        'Wave period (s)',
        'Precipitation probability (%)',
        'Cloud cover (%)',
        'Visibility (NM)'
    ]

    const ALLOWED_HOURS = ['00:00:00', '03:00:00', '06:00:00', '09:00:00', '12:00:00', '15:00:00', '18:00:00', '21:00:00']

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    forecastResponse,
                    visibilityResponse,
                    wavePeriodResponse,
                    waveHeightResponse,
                    significantWaveHeightResponse
                ] = await Promise.all([
                    fetchHourlyForecast({ lat: location.coordinates[1], lng: location.coordinates[0] }),
                    fetchBaronHiresVisibilityProductInstances()
                        .then((res) => fetchVisibility(res.data[0].time, '*', { lat: location.coordinates[1], lng: location.coordinates[0] })),
                    fetchWavePeriodProductInstances()
                        .then((res) => fetchWavePeriod(res.data[0].time, '*', { lat: location.coordinates[1], lng: location.coordinates[0] })),
                    fetchWaveHeightsProductInstances()
                        .then((res) => fetchWaveHeight(res.data[0].time, '*', { lat: location.coordinates[1], lng: location.coordinates[0] })),
                    fetchSignificantWaveHeightsProductInstances()
                        .then((res) => fetchSignificantWaveHeight(res.data[0].time, '*', { lat: location.coordinates[1], lng: location.coordinates[0] })),
                ])

                const hourlyData = (forecastResponse.data.pointforecast_hourly.data)
                    .filter(forecast => forecast.wind && typeof forecast.wind.speed !== 'undefined')
                const visibilityObj = filterAndMapData(visibilityResponse.data)
                const wavePeriodObj = filterAndMapData(wavePeriodResponse.data)
                const waveHeightObj = filterAndMapData(waveHeightResponse.data)
                const significantWaveHeightObj = filterAndMapData(significantWaveHeightResponse.data)

                const windSpeedObj = hourlyData.reduce((acc, forecast) => {
                    acc[forecast.valid_begin] = forecast.wind.speed
                    return acc
                }, {})

                const filteredHourlyData = hourlyData.filter((forecast) =>
                    ALLOWED_HOURS.includes(forecast.valid_begin.substr(11, 8))
                );

                const groupedData = filteredHourlyData.reduce((acc, forecast) => {
                    const utcTimestamp = forecast.valid_begin
                    const localDate = new Date(utcTimestamp)
                    const localDateString = localDate.toLocaleDateString('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).replace(/\//g, '-')
                    const localTimestamp = localDate.toISOString()

                    if (!acc[localDateString]) {
                        acc[localDateString] = []
                    }
                    const formattedRow = formatForecastRow(
                        forecast,
                        visibilityObj,
                        wavePeriodObj,
                        waveHeightObj,
                        windSpeedObj,
                        significantWaveHeightObj,
                        utcTimestamp
                    );
                    acc[localDateString].push({
                        ...formattedRow,
                        utcTimestamp,
                        timestamp: localTimestamp,
                    });
                    return acc
                }, {})

                setWeatherDataByDate(groupedData);
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        };

        fetchData()
    }, [location])

    const filterAndMapData = (data) => {
        return data
            .filter((entry) => ALLOWED_HOURS.includes(entry.valid_time.substr(11, 8)))
            .reduce((acc, entry) => {
                acc[entry.valid_time] = entry.value
                return acc
            }, {})
    }

    const getAdjacentWindSpeeds = (windSpeedObj, currentTime) => {
        const datePart = currentTime.substring(0, 11)
        const hourPart = parseInt(currentTime.substring(11, 13))
        const restPart = currentTime.substring(13)

        const prevTime = hourPart - 1 >= 0 ? `${datePart}${String(hourPart - 1).padStart(2, '0')}${restPart}` : null
        const nextTime = hourPart + 1 < 24 ? `${datePart}${String(hourPart + 1).padStart(2, '0')}${restPart}` : null

        return [
            prevTime ? windSpeedObj[prevTime] || null : null,
            windSpeedObj[currentTime] || null,
            nextTime ? windSpeedObj[nextTime] || null : null,
        ].filter((v) => v !== null)
    };

    const getAverageWindSpeed = (windSpeedObj, currentTime) => {
        const speeds = getAdjacentWindSpeeds(windSpeedObj, currentTime)
        return speeds.length ? speeds.reduce((a, b) => a + b, 0) / speeds.length : 0
    }

    const getMaxWindSpeed = (windSpeedObj, currentTime) => {
        const speeds = getAdjacentWindSpeeds(windSpeedObj, currentTime)
        return speeds.length ? Math.max(...speeds) : 0
    }

    const formatForecastRow = (forecast, visibilityObj, wavePeriodObj, waveHeightObj, windSpeedObj, significantWaveHeightObj, utcTime) => {
        return createData(
            {icon: forecast.weather_code.value, daylight: forecast.daylight},
            isMetric ? forecast.temperature.value.toFixed(1) : convertToFahrenheit(forecast.temperature.value),
            isMetric ? forecast.temperature.dew_point.toFixed(1) : convertToFahrenheit(forecast.temperature.dew_point),
            {
                speed: isMetric ? convertMStoKMH(getAverageWindSpeed(windSpeedObj, utcTime)) : convertMStoMPH(getAverageWindSpeed(windSpeedObj, utcTime)),
                direction: forecast.wind.dir,
            },
            isMetric ? convertMStoKMH(getMaxWindSpeed(windSpeedObj, utcTime)) : convertMStoMPH(getMaxWindSpeed(windSpeedObj, utcTime)),
            isMetric ? convertMStoKMH(forecast.wind.gust) : convertMStoMPH(forecast.wind.gust),
            isMetric ? convertFeetToMeters(significantWaveHeightObj[utcTime]) : (significantWaveHeightObj[utcTime] !== undefined ? significantWaveHeightObj[utcTime] : "NA"),
            isMetric ? convertFeetToMeters(waveHeightObj[utcTime]) : (waveHeightObj[utcTime] !== undefined ? waveHeightObj[utcTime] : "NA"),
            wavePeriodObj[utcTime] !== undefined ? wavePeriodObj[utcTime] : "NA",
            `${forecast.precipitation.probability.value}%`,
            `${forecast.cloud_cover.value}%`,
            visibilityObj[utcTime] !== undefined ? (visibilityObj[utcTime] * 0.868976).toFixed(1) : '10'
        )
    }

    const convertTo12HourFormat = (timeString) => {
        const localDate = new Date(timeString)
        const hours = localDate.getHours()
        const hours12 = ((hours % 12) || 12).toString()
        const period = hours < 12 ? 'AM' : 'PM'
        return `${hours12} ${period}`
    }

    const convertDateToReadableFormat = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number)
        const date = new Date(year, month - 1, day)
        const options = {weekday: 'long', month: 'short', day: 'numeric', year: 'numeric'}
        return date.toLocaleDateString('en-US', options)
    }

    const convertToFahrenheit = (celsius) => Number(celsius * 1.8 + 32).toFixed(1)
    const convertFeetToMeters = (ft) => typeof ft === 'number' && !isNaN(ft) ? Number(ft * 0.3048).toFixed(1) : 'NA'
    const convertMStoKMH = (ms) => Number(ms * 3.6).toFixed(1)
    const convertMStoMPH = (ms) => Number(ms * 2.237).toFixed(1)

    const isDataLoaded = Object.keys(weatherDataByDate).length > 0

    return (
        <TableContainer component={Paper} className={styles.tableWrapper}>
            {!isDataLoaded && (
                <div className={styles.progressScreen}>
                    <CircularProgress />
                    <div>Loading...</div>
                </div>
            )}
            {isDataLoaded && (
                <Table stickyHeader className={styles.tableContentWrapper}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={1} className={styles.tableHeaderCell} />
                            {Object.entries(weatherDataByDate).map(([date, forecasts]) => (
                                <TableCell
                                    align="center"
                                    colSpan={forecasts.length}
                                    key={date}
                                    className={clsx(styles.tableCell, styles.headerBold)}
                                >
                                    {convertDateToReadableFormat(date)}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" className={clsx(styles.tableHeaderCell, styles.headerSecond)} />
                            {Object.entries(weatherDataByDate).map(([_, forecasts]) =>
                                forecasts.map((forecast, index) => {
                                    const isLastInGroup = index === forecasts.length - 1 // Checking if this is the last column in the current date group
                                    return (
                                        <TableCell
                                            align="center"
                                            key={`${forecast.timestamp}-${index}`}
                                            className={clsx(styles.tableCell, styles.headerThin, {
                                                [styles.borderRightInclude]: isLastInGroup, // Add a border only to the last column in the group
                                                [styles.borderRightNone]: !isLastInGroup,
                                            })}
                                        >
                                            {convertTo12HourFormat(forecast.timestamp)}
                                        </TableCell>
                                    );
                                })
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {headers.map((header, headerIndex) => {
                            let lastGroupIndex = -1 // index of last element in day
                            return (
                                <TableRow key={headerIndex}>
                                    <TableCell className={clsx(styles.tableHeaderCell, styles.tableBodyTitle)}>
                                        {header}
                                    </TableCell>
                                    {Object.entries(weatherDataByDate).map(([_, forecasts]) =>
                                        forecasts.map((forecast, columnIndex) => {
                                            lastGroupIndex += 1;
                                            const isLastInGroup = columnIndex === forecasts.length - 1 // Checking if this is the last column in the current date group
                                            return (
                                                <TableCell
                                                    key={`${headerIndex}-${lastGroupIndex}`}
                                                    align="center"
                                                    className={clsx(styles.tableCell, styles.tableBodyContentCell, {
                                                        [styles.borderRightInclude]: isLastInGroup, // Add a border only to the last column in the group
                                                        [styles.borderRightNone]: !isLastInGroup,
                                                        ...(header === temperature && {
                                                            [styles.temperatureVeryCold]:  Number(forecast.temperature) <= (isMetric ? -36 : -32.8),
                                                            [styles.temperatureCold]: Number(forecast.temperature) <= (isMetric ? -21 : -5.8),
                                                            [styles.temperatureChilly]: Number(forecast.temperature) <= (isMetric ? -6 : 21.2),
                                                            [styles.temperatureAroundZero]: Number(forecast.temperature) > (isMetric ? -6 : 21.2) && Number(forecast.temperature) < (isMetric ? 6 : 42.8),
                                                            [styles.temperatureCool]: Number(forecast.temperature) >= (isMetric ? 6 : 42.8),
                                                            [styles.temperatureWarm]: Number(forecast.temperature) >= (isMetric ? 21 : 69.8),
                                                            [styles.temperatureHeat]: Number(forecast.temperature) >= (isMetric ? 36 : 96.8),
                                                        }),
                                                    })}
                                                >
                                                    {header === 'Weather Conditions' ? ( // Add icon for "Average wind speed" row
                                                        <div className={styles.weatherIconWrapper}>
                                                            <img
                                                                src={forecast.weatherConditions.daylight ? `/forecastIcons/${forecast.weatherConditions.icon}.svg` : (`/forecastIcons/${forecast.weatherConditions.icon}-night.svg`)}
                                                                className={styles.weatherIcon}
                                                                alt="forecast_icon"
                                                            />
                                                        </div>
                                                    ) : header === temperature ? ( // Add symbol for "Temperature" row
                                                        `${forecast.temperature}°`
                                                    ) : header === dewPoint ? ( // Add symbol for "Dew point" row
                                                        `${forecast.dewPoint}°`
                                                    ) : header === averageWindSpeed ? ( // Add direction arrow for "Average wind speed" row
                                                        <div className={styles.averageWindSpeedCell}>
                                                            <span>{forecast.averageWindSpeed.speed}</span>
                                                            <WindDirectionArrowIcon
                                                                sx={{transform: `rotate(${forecast.averageWindSpeed.direction}deg)`}}
                                                            />
                                                        </div>
                                                    ) : (
                                                        Object.values(forecast)[headerIndex]
                                                    )}
                                                </TableCell>
                                            );
                                        })
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}