import {isEmpty} from 'shared/libs/Utils'
import React, {useState, useEffect, useCallback} from 'react'
import {
    Accordion,
    MenuItem,
    Button,
    Box,
    Typography, IconButton,
} from '@mui/material'
import {CheckBox} from 'shared/ui/CheckBox'
import DraggableContactLine from 'entities/contact/ui/DraggableContactLine/DraggableContactLine'
import {Select} from 'shared/ui/Select/Select'
import Contacts from '../Contacts/Contacts'
import {SearchInput} from 'shared/ui/SearchInput'
import AgreeModal from 'shared/ui/AgreeModal'
import ItemsSelectTopBar from '../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import AccordionSummaryUserGroup from '../../../shared/ui/Accordion/AccordionSummaryUserGroup'
import AccordionDetailsUserGroup from '../../../shared/ui/Accordion/AccordionDetailsUserGroup'
import TrashIcon from '../../../shared/assets/icons/Trash'
import PenIcon from '../../../shared/assets/icons/Pen'
import {DraggableCore} from 'react-draggable'
import EmptyStateContacts from '../../../shared/ui/emptyStates/EmptyStateContacts'
import {EntityGroupSelectorPopup} from 'features/entityGroupSelector/EntityGroupSelectorPopup'
import ProgressModal from '../../../shared/ui/ProgressModal'
import {EditGroupName} from '../../../pages/settings/ui/savedLocations/EditLocationGroupName/EditLocationGroupName'
import {useContactsStore} from '../../../app/store/ContactsStore'
import ArrowDownSmallIcon from '../../../shared/assets/icons/ArrowDownSmall'
import styles from './UsersAndContacts.module.scss'
import {clsx} from 'clsx'

export default function UsersAndContacts({setShowSidebar}) {
    const {
        contacts,
        groups,
        noGroupUsers,
        selectedContacts,
        isLoading,
        fetchContacts,
        selectContacts,
        deleteSelectedContacts,
        deleteGroup,
        editContact,
        fetchNewestContactsIds,
    } = useContactsStore((state) => state)

    const [deleteUserGroup, setDeleteUserGroup] = useState(false)
    const [isOpenEditContactGroup, setIsOpenEditContactGroup] = useState(false)

    // const [openModalInviteUsers, setOpenModalInviteUsers] = useState(false);
    const [filterSubscription, setFilterSubscription] = useState('All')
    const [filterSearchUsers, setFilterSearchUsers] = useState('')
    const [agreeModalMassDelete, setAgreeModalMassDelete] = useState(false)

    const [dragPosition, setDragPosition] = useState({position: {x: 0, y: 0}, contactId: null})
    const [draggableContact, setDraggableContact] = useState(null)
    const [isDragging, setIsDragging] = useState(false)

    const [moveToGroupModalOpen, setMoveToGroupModalOpen] = useState(false)

    const [moveContactsState, setMoveContactsState] = useState({
        // items: [],
        original: '',
        new: '',
    })

    const [showContacts, setShowContacts] = useState(false)

    const idsToDelete = Object.entries(selectedContacts).filter(([key, value]) => value).map(([key, value]) => +key)
    const contactsToDelete = contacts.filter((contact) => idsToDelete.includes(contact.id))

    const allContactsSelected = contacts.length > 0 && contacts.every((contact) => selectedContacts[contact.id])
    const someContactsSelected = Object.values(selectedContacts).some((val) => val) && !allContactsSelected

    useEffect(() => {
        fetchContacts()
        fetchNewestContactsIds()
    }, [])

    const isAnyIdSelectedInGroup = (groupName) => {
        const group = groups.find((group) => group.groupName === groupName)
        if (!group) return false
        return group.contacts.some((contact) => selectedContacts[contact.id])
    }

    const areAllIdsSelectedInGroup = (groupName) => {
        const group = groups.find((group) => group.groupName === groupName)
        if (!group) return false
        const groupIds = group.contacts.map((contact) => contact.id)
        return groupIds.every((id) => selectedContacts[id])
    }

    // Handler for show Contacts
    const handleShowContacts = () => {
        setShowSidebar(true)
        setShowContacts(true)
        selectContacts({})
    }
    const handleHideContacts = () => {
        fetchNewestContactsIds()
        setShowSidebar(false)
        setShowContacts(false)
    }

    const handleActionsWithTopBar = (contact, action, value) => {
        console.log('onAction', action, contact, value)

        const actions = {
            delete: () => setAgreeModalMassDelete(true), // mass deleting
            openMovePopup: () => {
                const selectedContactsNew = contacts.filter((contact) => selectedContacts[contact.id])
                const originalGroupNames = [...new Set(selectedContactsNew.map((contact) => contact.group))]
                const originalGroupName = originalGroupNames.length === 1 ? originalGroupNames[0] : ''

                setMoveContactsState({
                    // items: selectedContacts,
                    original: originalGroupName,
                    new: '',
                })
                setMoveToGroupModalOpen(true)
            },
        }

        const selectedAction = actions[action]
        if (selectedAction) {
            selectedAction(value)
        }
    }

    // make groups structure similar to locations
    const modifiedGroups = groups?.map((group) => ({
        group: group.groupName,
        // items: group.contacts.map(contact => ({
        //     ...contact,
        //     label: contact.name,
        //     group: group.groupName
        // }))
    }))

    const doDeleteUserGroup = async (agreed) => {
        if (agreed && deleteUserGroup) {
            deleteGroup(deleteUserGroup.group)
        }
        setDeleteUserGroup(false)
    }

    const handleDeleteGroup = (groupName) => {
        setDeleteUserGroup({group: groupName})
    }

    const handleEditGroup = (groupName) => {
        setIsOpenEditContactGroup({
            original: groupName,
            new: groupName,
        })
    }

    const filteredNoGroupContacts = noGroupUsers.filter((contact) =>
        ((filterSubscription === 'All') || (contact.status === filterSubscription)) &&
        ((filterSearchUsers === '')
            || (contact.name.toLowerCase().includes(filterSearchUsers.toLowerCase().trim()))
            || (contact.email.toLowerCase().includes(filterSearchUsers.toLowerCase().trim()))
            || (contact.phone && contact.phone.toString().toLowerCase().includes(filterSearchUsers.toLowerCase().trim()))),
    )

    const handleGroupCheckboxChange = (isChecked, groupName) => {
        const newCheckedContacts = {...selectedContacts}
        groups.find((group) => group.groupName === groupName)?.contacts.forEach((contact) => {
            newCheckedContacts[contact.id] = isChecked
        })
        if (Object.values(newCheckedContacts).every((value) => value === false)) {
            selectContacts({})
        } else {
            selectContacts(newCheckedContacts)
        }
    }

    const massDeleteContacts = async (agree) => {
        if (agree) {
            deleteSelectedContacts()
        }
        setAgreeModalMassDelete(false)
    }

    const handleDrag = useCallback((event, data, contact) => {
        if (!isDragging) return
        setDragPosition({position: data, contactId: contact.id})
        if (draggableContact !== contact) {
            setDraggableContact(contact)
        }
    }, [isDragging, draggableContact])

    const stopDrag = useCallback(() => {
        setIsDragging(false)
        setDragPosition({position: {x: 0, y: 0}, contactId: null})
        setTimeout(() => setDraggableContact(null), 0)
    }, [])

    const handleDrop = useCallback((groupName) => {
        if (!draggableContact) return
        const newData = {
            name: draggableContact.name,
            phone: draggableContact.phone,
            email: draggableContact.email,
            group: groupName || '',
        }
        editContact(draggableContact.id, newData)
        setDraggableContact(null)
        setIsDragging(false)
    }, [draggableContact])

    return (
        <Box className={'settings'}>
            {showContacts ? (<Contacts onHideContacts={handleHideContacts}/>)
                : (<>
                    <Box className={clsx('settings-toolbar', styles.header)}>
                        <h3>
                            Users and contacts
                        </h3>
                        <SearchInput
                            type='search'
                            placeholder='Search'
                            onChange={({target}) => setFilterSearchUsers(target.value)}
                        />
                        <Select
                            placeholder={''}
                            defaultValue={filterSubscription}
                            onChange={({target}) => setFilterSubscription(target.value)}
                            InputProps={{
                                startAdornment:
                                    <div className={clsx('paragraph', styles.select)}>
                                        Status:
                                    </div>,
                            }}
                        >
                            <MenuItem value='All'>
All
                            </MenuItem>
                            <MenuItem value='Active subscription'>
Active subscription
                            </MenuItem>
                            <MenuItem value='Invite sent'>
Invite sent
                            </MenuItem>
                            <MenuItem value='Subscription expired'>
Subscription expired
                            </MenuItem>
                            <MenuItem value='No subscription'>
No subscription
                            </MenuItem>
                        </Select>
                        {/* TODO: implement invite functionality */}
                        {/* <Button onClick={() => setOpenModalInviteUsers(true)}>Invite users</Button> */}
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleShowContacts}
                            sx={{marginLeft: 'auto'}}
                        >
                            Add contacts
                        </Button>
                    </Box>

                    <ItemsSelectTopBar
                        onAction={handleActionsWithTopBar}
                        visible={Object.keys(selectedContacts).length > 0}
                        allSelected={allContactsSelected}
                        indeterminate={someContactsSelected}
                        type={'usercontacts'}
                    />

                    {isEmpty(Object.values(contacts))
                        ? <Box sx={{width: '100%', height: '100%'}}>
                            <EmptyStateContacts
                                title={'Users and contacts will appear here'}
                                text={'You will see users and contacts here once they are created'}
                            />
                        </Box>
                        : <Box
                            className={'column gap16 fullWidth'}
                            style={{padding: '16px 24px', overflowX: 'hidden', height: '100%', position: 'relative'}}
                          >
                            {groups.map(({groupName, contacts}) => {
                                const filteredContacts = contacts
                                    .filter((c) => ((filterSubscription === 'All') || (c.status === filterSubscription)))
                                    .filter((c) => (
                                        (filterSearchUsers === '')
                                        || (c.name.toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)
                                        || (c.email.toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)
                                        || (c.phone && c.phone.toString().toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)
                                    ))

                                if (filteredContacts.length === 0) {
                                    return null // do not render empty groups
                                }

                                return (
                                    <>
                                        {contacts.some((contact) => filterSearchUsers === ''
                                                || (contact.name.toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)
                                                || (contact.email.toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)
                                                || (contact.phone && contact.phone.toString().toLowerCase().indexOf(filterSearchUsers.toLowerCase().trim()) > -1)) &&
                                            <Accordion
                                                key={groupName}
                                                onMouseUp={() => handleDrop(groupName)}
                                            >
                                                <AccordionSummaryUserGroup
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    expandIcon={
                                                        <IconButton
                                                            size='tiny'
                                                            variant='outlined'
                                                        >
                                                            <ArrowDownSmallIcon size={'small'}/>
                                                        </IconButton>}
                                                >
                                                    <Box className={'row gap8'}>
                                                        <CheckBox
                                                            id='aria-label'
                                                            onClick={(e) => e.stopPropagation()}
                                                            checked={areAllIdsSelectedInGroup(groupName)}
                                                            onChange={(e) => handleGroupCheckboxChange(e.target.checked, groupName)}
                                                            indeterminate={isAnyIdSelectedInGroup(groupName) && !areAllIdsSelectedInGroup(groupName)}
                                                        />
                                                        <Typography style={{fontWeight: '500', fontSize: '16px'}}>
                                                            {groupName}
                                                            {' '}
(
                                                            {contacts.length}
)
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummaryUserGroup>
                                                <AccordionDetailsUserGroup>
                                                    <Box className={'column gap8 fullWidth'}>
                                                        {filteredContacts.map((contact) => (
                                                            <DraggableCore
                                                                onDrag={(e, data) => handleDrag(e, data, contact)}
                                                                onStop={() => stopDrag()}
                                                            >
                                                                <Box
                                                                    sx={contact.id === dragPosition.contactId && isDragging ? {
                                                                        position: 'absolute',
                                                                        pointerEvents: 'none',
                                                                        width: '98%',
                                                                        zIndex: '1',
                                                                        transform: `translate(0px, ${dragPosition.position.y}px)`,
                                                                        left: '15px',
                                                                        top: '-20px',
                                                                    } : undefined}
                                                                >
                                                                    <DraggableContactLine
                                                                        key={contact.id}
                                                                        contact={contact}
                                                                        isChecked={selectedContacts && selectedContacts[contact.id]}
                                                                        onDrag={() => {
                                                                            setIsDragging(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </DraggableCore>
                                                        ))}
                                                    </Box>
                                                    <Box
                                                        className={'row gap8'}
                                                        sx={{justifyContent: 'flex-end'}}
                                                    >
                                                        <Button
                                                            size={'small'}
                                                            variant={'outlined'}
                                                            onClick={() => handleEditGroup(groupName)}
                                                            startIcon={<PenIcon size={'small'}/>}
                                                        >
                                                            Edit group name
                                                        </Button>
                                                        <Button
                                                            size={'small'}
                                                            variant={'outlined'}
                                                            color={'error'}
                                                            onClick={() => handleDeleteGroup(groupName)}
                                                            startIcon={<TrashIcon size={'small'}/>}
                                                        >
                                                            Delete group
                                                        </Button>
                                                    </Box>
                                                </AccordionDetailsUserGroup>
                                            </Accordion>
                                        }
                                    </>
                                )
                            })}

                            <Box
                                className={'column gap8 fullWidth'}
                                style={{minWidth: '800px', overflow: 'initial', height: '100%'}}
                                onMouseUp={() => handleDrop()}
                            >
                                {filteredNoGroupContacts.map((contact) => (
                                    <DraggableCore
                                        onDrag={(e, data) => handleDrag(e, data, contact)}
                                        onStop={() => stopDrag()}
                                    >
                                        <Box
                                            sx={contact.id === dragPosition.contactId && isDragging ? {
                                                position: 'absolute',
                                                pointerEvents: 'none',
                                                width: '97.3%',
                                                zIndex: '1',
                                                transform: `translate(0px, ${dragPosition.position.y}px)`,
                                                left: '20px',
                                                top: '-20px',
                                            } : undefined}
                                        >
                                            <DraggableContactLine
                                                key={contact.id}
                                                contact={contact}
                                                isChecked={selectedContacts && selectedContacts[contact.id]}
                                                onDrag={() => setIsDragging(true)}
                                            />
                                        </Box>
                                    </DraggableCore>
                                ))}
                            </Box>
                        </Box>}

                    {deleteUserGroup &&
                        <AgreeModal
                            data={{
                                message: <span>
Are you sure you want delete
                                    <span className={styles.deleteUserGroupTitle}>
                                        {' '}
                                        {deleteUserGroup.group}
                                    </span>
                                    {' '}
user group?
                                    <br/>
All users and contacts will remain in the system.
                                </span>,
                                title: 'Delete user group',
                                agreeMsg: 'Delete',
                                mode: 'deleting',
                                agreeFunc: doDeleteUserGroup,
                            }}
                        />
                    }

                    {moveToGroupModalOpen && (
                        <EntityGroupSelectorPopup
                            moveToGroupState={moveContactsState}
                            onChange={() => {
                                setMoveToGroupModalOpen(false)
                            }}
                            groups={modifiedGroups}
                            type={'contacts'}
                        />
                    )}

                    {isOpenEditContactGroup &&
                        <EditGroupName
                            editGroupState={isOpenEditContactGroup}
                            onChange={() => setIsOpenEditContactGroup(false)}
                            isContact={true}
                        />
                    }

                    {agreeModalMassDelete &&
                        <AgreeModal
                            data={{
                                message: <Box
                                    className='column'
                                    sx={{
                                        alignContent: 'stretch',
                                        overflow: 'hidden',
                                        '&.MuiBox-root': {width: '100%'},
                                    }}
                                         >
                                    <Typography
                                        sx={{fontSize: '18px'}}
                                    >
                                        {(Object.keys(selectedContacts).length === 1) ? 'Are you sure you want to delete this contact?' : `Are you sure you want delete ${Object.keys(selectedContacts).length} contacts?`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            maxHeight: '120px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexWrap: 'wrap',
                                            overflow: 'auto',
                                            gap: '0 16px',
                                        }}
                                    >
                                        {contactsToDelete.map((contact) =>
                                            (<span>
•
                                                {' '}
                                                {contact.name}
                                                <br/>
                                            </span>))}
                                    </Box>
                                </Box>,
                                title: 'Delete multiple contacts',
                                agreeMsg: 'Delete',
                                mode: 'deleting',
                                agreeFunc: massDeleteContacts,
                            }}
                        />}
                </>)}
            <ProgressModal visible={isLoading}/>
        </Box>
    )
}
