import OpenAlertMenuButton from 'features/alert/OpenAlertMenuButton'
import dayjs from 'dayjs'
import React, {useState, useEffect} from 'react'
import {Box, Chip, IconButton, Popover, Link, Divider, Badge} from '@mui/material'
import theme from 'app/Theme'
import {Spacer} from 'shared/ui/Spacer'
import CloseIcon from 'shared/assets/icons/Close'
import MapPinIcon from 'shared/assets/icons/MapPin'
import ModalComponent from 'shared/ui/ModalComponent'
import {WeatherDefinitionDetails} from 'entities/weatherDefinition/ui/WeatherDefinitionDetails/WeatherDefinitionDetails'
import {ShowReportButton} from 'features/alert/ShowReportButton'
import RenderIfVisible from 'react-render-if-visible'
import {useNotificationsStore} from '../../../app/store/NotificationsStore'
import styles from './NotificationsPopup.module.scss'
import {clsx} from 'clsx'

const formatDate = (dateString) => dayjs(dateString).format('D MMM, YYYY')

const formatTime = (dateString) => dayjs.utc(dateString).local().format('h:mm A')

const renderCompact = (n, format) => {
    if (!n.subalerts) return []
    const alerts = n.subalerts
    const issueUtcIso = n.issue_utc_iso
    const result = []
    const dayFormat = format === 'date' ? 'MMM D ' : 'ddd ' // Jul 04 or Sun
    alerts.forEach((alert) => {
        if (alert.type !== 'c' && alert.end_date <= issueUtcIso) {
            return
        }
        const start = dayjs.utc(alert.start_date).local()
        let startDate = start.format(dayFormat)
        let startTime = start.format('h:mm A').replace(':00', '') // 2:50 PM
        let endDate = 'Ongoing'
        let endTime = ''
        if (alert['type'] !== 'c') {
            let end = dayjs.utc(alert.end_date).local()
            if (end.minute() === 59) {
                end = end.add(1, 'minute')
            }
            endDate = end.format(dayFormat)
            endTime = end.format('h:mm A').replace(':00', '') // 3:10 PM.
            if (startDate === endDate) {
                endDate = ''
                startDate = startDate.slice(0, -1) + ': '
                if (startTime.slice(-2) === endTime.slice(-2)) {
                    startTime = start.format('h:mm').replace(':00', '')
                } // 2:50
            }
        }
        const values = alert['conditions']
            .map((c) => ((c.type === 'c') ? c.value : c.value))
            .join(', ') // WL-528: 50 F, 5 mph
        result.push(startDate + startTime + '-' + endDate + endTime + ': ' + values)
    })
    if (!result.length) {
        result.push('Alert: Cleared')
    }
    return result
}

// overcomplicated function for wrap tokens into <b> tags
const renderNotificationTemplate = (data) => {
    let template = data.template
    data.alert_name = data.title
    data.start_time = formatTime(data.start_utc)
    data.start_date = formatDate(data.start_utc)
    data.end_time = formatTime(data.end_utc)
    data.end_date = formatDate(data.end_utc)
    data.issue_start_time = formatTime(data.issue_utc_iso)
    data.issue_start_date = formatDate(data.issue_utc_iso)

    const regex = /\{\{(.*?)}}/g
    let match
    let lastIndex = 0
    const elements = []

    template = template.split('{{email_template}}')[0] // WL-466 new section for email template added
    //    ['{{ellipsis}}'].forEach(token => {  // WL-466 remove unsupported tokens
    //        template = template.replaceAll(token, '');
    //    });
    if (template.indexOf('{{ellipsis}}') >= 0) {
        const alertsNum = data.subalerts.length
        const parts = template.split('{{ellipsis}}').map((p) => {
            const part = p.trim()
            if (part.slice(-'{{date_value_compact}}'.length) !== '{{date_value_compact}}' &&
                part.slice(-'{{day_value_compact}}'.length) !== '{{day_value_compact}}' &&
                part.slice(-'{{date_value_verbose}}'.length) !== '{{date_value_verbose}}'
            ) {
                return part
            }
            if (alertsNum <= 1) {
                return part
            }
            const token = '{{' + part.split('{{').pop()
            return part + '\n' + Array(alertsNum - 1).fill(token).join('\n')
        })
        template = parts.join('')
    }
    if (template.indexOf('{{date_value_compact}}') >= 0) {
        data['date_value_compact'] = renderCompact(data, 'date')
        data['disable_triggered'] = true
    }
    if (template.indexOf('{{day_value_compact}}') >= 0) {
        data['day_value_compact'] = renderCompact(data, 'day')
        data['disable_triggered'] = true
    }
    if (template.indexOf('{{date_value_verbose}}') >= 0) {
        data['date_value_verbose'] = renderCompact(data, 'date')
        data['disable_triggered'] = true
    }
    let dayValIdx = 0
    while ((match = regex.exec(template)) !== null) {
        const text = template.slice(lastIndex, match.index)
        if (text) elements.push(text)

        const variable = match[1].trim()
        if (variable === 'date_value_compact'
            || variable === 'day_value_compact'
            || variable === 'date_value_verbose'
        ) {
            if (data[variable][dayValIdx]) {
                if (!dayValIdx) elements.push(<br/>)
                elements.push(<>
                    {data[variable][dayValIdx]}
                    <br/>
                </>)
            }
            dayValIdx += 1
        } else if (data[variable] !== undefined) {
            elements.push(<b key={variable}>
                {data[variable]}
            </b>)
        } else {
            elements.push(<b key={variable}>
                {match[0]}
            </b>)
        }

        lastIndex = regex.lastIndex
    }

    const remainingText = template.slice(lastIndex)
    if (remainingText) elements.push(remainingText)

    return elements
}

const formatTimeAgo = (timestamp) => {
    const now = new Date()
    const updatedTime = new Date(timestamp)
    const timeDiff = now - updatedTime

    const minutesAgo = Math.floor(timeDiff / (1000 * 60))
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60))
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

    if (minutesAgo < 1) {
        return '<1 min ago'
    } else if (minutesAgo < 60) {
        return `${minutesAgo}min ago`
    } else if (hoursAgo < 24) {
        return `${hoursAgo}hour${hoursAgo === 1 ? '' : 's'} ago`
    } else {
        return `${daysAgo}day${daysAgo === 1 ? '' : 's'} ago`
    }
}

export function NotificationsPopup({isOpen, onClose, onMarkAllAsRead, onNewNotifications, anchorBellEl}) {
    const {notifications, fetchNotifications, markAllNotificationsAsRead} = useNotificationsStore((state) => state)
    const [openDefinition, setOpenDefinition] = useState(null)

    const handleFetchNotifications = async () => {
        const notifications = await fetchNotifications()
        if (notifications?.data) {
            onNewNotifications(notifications.data)
        }
    }

    useEffect(() => {
        if (isOpen) {
            markAllNotificationsAsRead().then(() => onMarkAllAsRead())
        } else {
            handleFetchNotifications()
        }

        const intervalId = setInterval(handleFetchNotifications, 60000)

        return () => {
            clearInterval(intervalId)
        }
    }, [isOpen])

    return (
        <Popover
            data-cy={'notifications-popup'}
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorBellEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiPaper-root': {
                    background: 'none',
                    padding: 0,
                },
            }}
        >
            <Box className={clsx('column', styles.wrapper)}>
                <div className={styles.header}>
                    <div className={'subtitle medium'}>
                        Alerts
                    </div>
                    <Link
                        href={'/alerts'}
                        className={styles.link}
                    >
                        View all &gt;
                    </Link>
                    <Spacer/>
                    <IconButton
                        data-cy={'close-notifications-popup'}
                        id={'basic-button'}
                        variant={'outlined'}
                        size={'small'}
                        onClick={onClose}
                        sx={{marginLeft: 'auto'}}
                    >
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </div>
                <Divider className={'fullWidth'}/>
                <div className={clsx('column', styles.contentWrapper)}>
                    {notifications.length === 0 ? (
                        <div className='no-notifications-text'>
                            No notifications available.
                        </div>
                    ) : (
                        notifications.map((notification, notificationKey) => (
                            <RenderIfVisible
                                key={notificationKey}
                                defaultHeight={200}
                            >
                                <div
                                    data-cy={'notification-card'}
                                    key={notificationKey}
                                    className={clsx('column fullWidth', styles.content)}
                                >
                                    <div className={'row'}>
                                        <div className={'subtitle medium'}>
                                            {notification.title}
                                        </div>
                                        <div
                                            className={'paragraph'}
                                            style={{color: theme.palette.notification.status[notification.status]}}
                                        >
                                            {notification.status}
                                        </div>
                                        <Spacer/>
                                        <label className={styles.label}>
                                            {formatTimeAgo(notification.timestamp)}
                                        </label>
                                        {!notification.seen &&
                                            <Badge
                                                badgeContent={' '}
                                                color={'error'}
                                                variant={'dot'}
                                            ></Badge>
                                        }
                                        <OpenAlertMenuButton
                                            alert={notification}
                                            onOpen={setOpenDefinition}
                                            isNotification={true}
                                        />
                                    </div>
                                    <Box className={clsx('paragraph', styles.contentBlock)}>
                                        {renderNotificationTemplate(notification)}
                                        <div className={styles.contentListWrapper}>
                                            {!notification.disable_triggered &&
                                                <div className={styles.contentList}>
                                                    {notification.triggered_products.map((triggered, index) => (
                                                        <div key={index}>
                                                            <p
                                                                key={index}
                                                                className={styles.contentListItem}
                                                            >
                                                                {triggered[0]}
                                                                :
                                                                {triggered[1]}
                                                            </p>
                                                            {triggered[2] &&
                                                                <p
                                                                    key={index}
                                                                    className={styles.contentListItem}
                                                                >
                                                                    Min value for forecast period:
                                                                    {' '}
                                                                    {triggered[2]}
                                                                </p>}
                                                            {triggered[3] &&
                                                                <p
                                                                    key={index}
                                                                    className={styles.contentListItem}
                                                                >
                                                                    Max value for forecast period:
                                                                    {' '}
                                                                    {triggered[3]}
                                                                </p>}
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {notification.report &&
                                                <ShowReportButton
                                                    reportLink={notification.report}
                                                    style={{width: 'max-content', border: 'none'}}
                                                />
                                            }
                                        </div>
                                    </Box>
                                    <div className={'row'}>
                                        <Chip
                                            icon={<MapPinIcon size={'small'}/>}
                                            label={notification.location}
                                            size={'small'}
                                        />
                                        {notification.severity && (
                                            <Chip
                                                label={notification.severity}
                                                variant={theme.palette.weather.variant[notification.severity]}
                                                size={'small'}
                                            />
                                        )}
                                        <Spacer/>
                                    </div>
                                    <Divider className={'fullWidth'}/>
                                </div>
                            </RenderIfVisible>
                        ))
                    )}
                    <ModalComponent
                        visible={!!openDefinition}
                        style={{p: 0}}
                    >
                        <WeatherDefinitionDetails
                            definition_id={openDefinition?.id}
                            onBack={() => setOpenDefinition(null)}
                        />
                    </ModalComponent>
                </div>
            </Box>
        </Popover>
    )
}
