import React, {useEffect} from 'react'
import styles from './DashboardBody.module.scss'
import DashboardHourlyTable from '../DashboardHourlyTable/DashboardHourlyTable'
import DashboardWidget from '../DashboardWidget/DashboardWidget'
import EmptyStateWidgets from "../../../shared/ui/emptyStates/EmptyStateWidgets";
import {useDashboardStore} from "../../../app/store/DashboardStore";

const DashboardBody = ({ currentView }) => {
    const { dashboardWidgets, fetchDashboardWidgets } = useDashboardStore((state) => state);

    useEffect(() => {
        if (currentView?.widgets?.length) {
            const widgetIds = currentView.widgets
            fetchDashboardWidgets(widgetIds)
        }
    }, [currentView])

    const getWidgetById = (widgetId) => dashboardWidgets.find((w) => w.id === widgetId)

    const widgetNameDefinition = (widgetId) => {
        const widget = getWidgetById(widgetId)
        if (!widget) return null
        if (widget.type === "Table") {
            return "Hourly forecast"
        }
    }

    const widgetTypeDefinition = (widgetId) => {
        const widget = getWidgetById(widgetId)
        if (!widget) return null
        if (widget.type === "Table") {
            return <DashboardHourlyTable location={widget.location} units={widget.options.chosenUnits}/>;
        }
    }

    return (
        <div className={styles.wrapper}>
            {!currentView?.widgets || currentView.widgets.length < 1 ? (
                <div className={styles.emptyStatesWrapper}>
                    <EmptyStateWidgets
                        title={"Widgets will appear here"}
                        text={"Create a view and select widgets to customize your dashboard"}
                    />
                </div>
            ) : (
                currentView.widgets
                    .sort((a, b) => a - b) //sorting by id, remove when layouts will add
                    .map((widgetId) => {
                    const widget = getWidgetById(widgetId);
                    if (!widget) return null;

                    return (
                        <DashboardWidget
                            key={widgetId}
                            title={widgetNameDefinition(widgetId)}
                            location={widget.location}
                            widget={widget}
                        >
                            {widgetTypeDefinition(widgetId)}
                        </DashboardWidget>
                    );
                })
            )}
        </div>
    );
};

export default DashboardBody
