import React, {useEffect, useState} from 'react'
import {Box, IconButton, ClickAwayListener, MenuItem, Button, Typography} from '@mui/material'
import DesktopLoadIcon from '../../../shared/assets/icons/DesktopLoad'
import ArrowRightSmallIcon from 'shared/assets/icons/ArrowRightSmall'
import DragVerticalIcon from '../../../shared/assets/icons/DragVertical'
import TrashIcon from '../../../shared/assets/icons/Trash'
import PenIcon from '../../../shared/assets/icons/Pen'
import DesktopIcon from '../../../shared/assets/icons/Desktop'
import DesktopSaveIcon from '../../../shared/assets/icons/DesktopSave'
import SaveViewModal from '../../../shared/ui/SaveViewModal/SaveViewModal'
import AgreeModal from '../../../shared/ui/AgreeModal'
import {useViewsStore} from '../../../app/store/ViewsStore'
import styles from "./TimeLineElipsis.module.scss"
import {clsx} from "clsx";

function TimeLineElipsis({chartRef, timelinePos, period, selectedConfigs, locations, selectedLocation, setUpdated}) {
    // onViewAction - return to props if Download CVS will back

    const {
        timelineViews,
        createTimelineView,
        editTimelineView,
        deleteTimelineView,
        fetchTimelineViews,
    } = useViewsStore((state) => state)

    const [showMenu, setShowMenu] = useState(false)
    const [showLoadMenu, setShowLoadMenu] = useState(false)

    const [saveViewModal, setSaveViewModal] = useState(false)
    const [editViewModalID, setEditViewModalID] = useState(null)
    const [deleteViewModalID, setDeleteViewModalID] = useState(null)
    const checkBoxesInSaveModal = [
        {key: 'scale', text: 'Period'},
        {key: 'position', text: 'Timeline date'},
        {key: 'configs', text: 'Alerts filter'},
        {key: 'location', text: 'Selected location'},
    ]

    const newView = {
        id: '',
        title: '',
        includes: {scale: true, position: true, location: true, configs: true},
        data: {
            configs: selectedConfigs.configs?Object.values(selectedConfigs.configs).filter((c) => c.selected).map((c) => c.group.groupId):null,
            scale: period,
            position: timelinePos.current,
            location: selectedLocation?.id,
        },
    }

    const loadMenuItems = timelineViews || []
    const loadMenuItemsObj = {}
    for (const idx in timelineViews) {
        loadMenuItemsObj[timelineViews[idx].id] = timelineViews[idx]
    }

    const editedViews = Object.fromEntries( // Old views with values from newView
        Object.keys(loadMenuItemsObj).map((id) => [
            id,
            {
                ...newView,
                id: loadMenuItemsObj[id].id,
                title: loadMenuItemsObj[id].title,
            },
        ]),
    )

    useEffect(() => {
        fetchTimelineViews()
    }, [])

    const hideMenu = () => {
        setShowMenu(false)
        setShowLoadMenu(false)
    }

    const onClickLoadView = (e) => {
        hideMenu()
        const id = e.target.parentElement.dataset.id
        const view = loadMenuItemsObj[id]
        if (!view) return // WL-624
        const newTimelinePos = view['includes'].position? view.data.position : timelinePos.current
        const newPeriod = view['includes'].scale? view.data.scale : period
        const newSelectedLocation = view['includes'].location? locations[view.data.location] : selectedLocation
        let newSelectedConfigs = selectedConfigs
        const configs = selectedConfigs.configs
        if (view['includes'].configs && configs) {
            for (const key in configs) configs[key].selected = false
            view.data.configs.forEach((key) => configs[key].selected = true)
            newSelectedConfigs = {configs: configs}
        }
        setUpdated(newPeriod, newTimelinePos, newSelectedConfigs, newSelectedLocation)
    }

    /*    const onClickDownloadCsv = () => {
        chartRef.current.downloadCSV();
        hideMenu();
    }*/

    const handleSaveNewView = (view) =>{
        setSaveViewModal(false)
        setEditViewModalID(null)

        if (!view) return

        const apiView = {
            name: view.title,
            order: view.order,
            data_json: view,
        }
        delete view.name
        delete view.order

        if (!view.id) {
            delete view.id
            createTimelineView(apiView)
        } else {
            apiView.id = view.id
            delete view.id
            editTimelineView(apiView)
        }
    }

    const handleDeleteView = (agree, id) =>{
        setDeleteViewModalID(null)

        const view = loadMenuItemsObj[id]

        if (!view) return
        if (!loadMenuItemsObj[view.id]) return
        if (agree) {
            deleteTimelineView(view.id)
        }
    }

    const onClickSaveView = (e) => {
        e.stopPropagation()
        setSaveViewModal(true)
    }

    const onClickEditView = (e) => {
        e.stopPropagation()
        setEditViewModalID( e.currentTarget.parentElement.dataset.id)
    }

    const onClickDeleteView = (e) => {
        e.stopPropagation()
        setDeleteViewModalID(e.currentTarget.parentElement.dataset.id)
    }

    return (
        <Box className={styles.wrapper}>
            <Button
                variant={'outlined'}
                color={'secondary'}
                startIcon={<DesktopIcon/>}
                onClick={() => {
                    setShowMenu(!showMenu)
                }}
            >
                    Views
            </Button>
            {/* <IconButton className={'timeline-shadow'} sx={{padding: '0', width: '40px', height: '40px', margin: 'auto 0px'}}
                    onClick={() => {setShowMenu(!showMenu)}}>
                    <img src={moreIcon} style={{width: '20px'}} alt={"moreIcon"}/>
                </IconButton>*/}
            { showMenu &&
                <ClickAwayListener
                    onClickAway={() => {
                        setShowMenu(false); setShowLoadMenu(false)
                    }}
                >
                    <Box
                        className={clsx('timeline-shadow', styles.menuWrapper)}
                        id='timeline-elipsis-menu'
                    >
                        {/* Commited for future.
                           Ticket WL-551 "We are waiting to see if any customers actually even need it"
                        */}
                        {/* <MenuItem onClick={onClickDownloadCsv}>
                            <DownloadIcon size={'small'}/>
                            Download CSV
                        </MenuItem>*/}
                        <MenuItem onClick={onClickSaveView}>
                            <DesktopSaveIcon size={'small'}/>
                            <Box className={'spacer'}>
                                Save view
                            </Box>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setShowLoadMenu(!showLoadMenu)
                            }}
                        >
                            <DesktopLoadIcon size={'small'}/>
                            <Box className={'spacer'}>
                                Load view
                            </Box>
                            <ArrowRightSmallIcon size={'small'}/>
                        </MenuItem>
                        { showLoadMenu &&
                        <Box
                            className={clsx('timeline-shadow', styles.loadMenuWrapper)}
                            id='timeline-load-view-menu'
                        >
                            {timelineViews.length ? (loadMenuItems.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    data-id={item.id}
                                    className={styles.loadMenuItem}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.children[2].style.opacity='1'
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.children[2].style.opacity='0'
                                    }}
                                    onClick={onClickLoadView}
                                >
                                    <DragVerticalIcon size={'small'}/>
                                    <Box className={'spacer'}>
                                        {item.title}
                                    </Box>
                                    <Box
                                        sx={{opacity: '0'}}
                                        data-id={item.id}
                                    >
                                        <IconButton
                                            size={'tiny'}
                                            onClick={onClickEditView}
                                        >
                                            <PenIcon size={'small'}/>
                                        </IconButton>
                                        <IconButton
                                            size={'tiny'}
                                            onClick={onClickDeleteView}
                                        >
                                            <TrashIcon
                                                size={'small'}
                                                className={styles.trashIconColor}
                                            />
                                        </IconButton>
                                    </Box>
                                </MenuItem>
                            ))) : 'No views have been saved yet'}
                        </Box>
                        }
                    </Box>
                </ClickAwayListener>
            }
            {saveViewModal &&
                <SaveViewModal
                    headerText='Save current view'
                    saveText='Save view'
                    saveFunc={handleSaveNewView}
                    view={newView}
                    checkBoxes={checkBoxesInSaveModal}
                />
            }
            {editViewModalID &&
                <SaveViewModal
                    headerText='Edit view'
                    saveText='Save changes'
                    saveFunc={handleSaveNewView}
                    view={editedViews[editViewModalID]}
                    checkBoxes={checkBoxesInSaveModal}
                />
            }
            {deleteViewModalID &&
                <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{alignContent: 'stretch', overflow: 'hidden', '&.MuiBox-root': {width: '100%'}}}
                        >
                            <Typography sx={{fontSize: '18px'}}>
                                {`Are you sure you want to delete "${loadMenuItemsObj[deleteViewModalID].title}" view?`}
                            </Typography>
                        </Box>,
                        title: 'Delete view',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => handleDeleteView(agree, deleteViewModalID),
                    }}
                />
            }
        </Box>
    )
}

export default TimeLineElipsis
