import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from '@mui/material'
import classNames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import {MapMode, useMapStore} from '../../../../app/store/MapStore'

import {useLocalStorageState} from '../../../../shared/libs/hooks/storage/useLocalStorageState'
import './SingleSiteRadar.scss'
import Nexrad from "./index";

export default function SingleSiteRadar({
    reEnableWmsIfNeed,
    group,
    scrollContainerRef,
}) {
    const map = useMapStore((state) => state.map)
    const singleSiteRadar = useMapStore((state) => state.singleSiteRadar)
    const mapMode = useMapStore((state) => state.mapMode)
    const toggleProduct = useMapStore((state) => state.toggleProduct)
    const setSelectedProducts = useMapStore((state) => state.setSelectedProducts)
    const selectedProducts = useMapStore((state) => state.selectedProducts)

    const ssrLayerIds = useRef(group.map((layer) => layer.id))

    const [enabled, setEnabled] = useLocalStorageState(false, 'isSingleSiteRadarEnabled')
    const [stationName, setStation] = useLocalStorageState('', 'site')
    const [lastSelectedLayerId, setLastSelectedLayer] = useLocalStorageState(ssrLayerIds.current[0], 'lastSelectedSingleSiteRadarLayer')
    const [enabledProduct, setEnabledProduct] = useState(null)

    const stateRef = useRef({
        lastSelectedLayerId: ssrLayerIds.current[0],
    })
    const accordionRef = useRef(null)

    useEffect(() => {
        if (!singleSiteRadar) {
            return
        }

        singleSiteRadar.createMarkers()
        if (enabled) {
            singleSiteRadar.enable()
        }

        if (enabled && stationName) {
            singleSiteRadar.handleStationChange({
                stationName: stationName,
                selected: true,
            })
            map.customSettingSSR = stationName
            map.fire('change_customSettingSSR')

            handleLayerChange(null, lastSelectedLayerId)
        }

        map.on('stationClick', handleStationClick)

        return () => {
            map.off('stationClick', handleStationClick)
        }
    }, [singleSiteRadar, enabled])

    useEffect(()=>{
        if (!singleSiteRadar) {
            return
        }

        const isSomeProductSelected = selectedProducts.some(selectedProduct => {
            return Nexrad.ids.includes(selectedProduct.id)
        })

        if (!isSomeProductSelected) {
            singleSiteRadar.disable()
            setEnabled(false)
        }
    }, [singleSiteRadar, selectedProducts])

    useEffect(() => {
        stateRef.current.lastSelectedLayerId = lastSelectedLayerId
    }, [lastSelectedLayerId])

    useEffect(() => {
        if (stationName) {
            reEnableWmsIfNeed()
        }
    }, [stationName])


    const handleToggleProduct = (event, value) => {
        event.stopPropagation()
        setEnabled(value)

        if (!value) {
            clear()
            singleSiteRadar.disable()
            return
        }

        singleSiteRadar.enable()
        if (stationName) {
            handleLayerChange(null, lastSelectedLayerId)
        }
    }

    const handleStationClick = ({station, selected}) => {
        setStation(station.name)
        if (!selected) {
            handleDeselectStation()
            return
        }

        if (!enabledProduct) {
            handleLayerChange(null, stateRef.current.lastSelectedLayerId)
        }

        map.customSettingSSR = station.name
        map.fire('change_customSettingSSR')
    }

    const handleLayerChange = async (event, productId) => {
        const product = group.find((product) => product.id === Number(productId))
        setLastSelectedLayer(product.id)
        const {toggled} = toggleProduct(
            product,
            (state) => ({
                ...state,
                selectedProducts: removeSsrLayers(state.selectedProducts),
            }),
        )

        setEnabledProduct(toggled ? {...product} : null)
    }

    const removeSsrLayers = (selectedProducts) => selectedProducts.filter((product) => !ssrLayerIds.current.includes(product.id))

    const handleDeselectStation = async () => {
        if (enabledProduct) {
            setLastSelectedLayer(enabledProduct.id)
        }

        setStation('')
        clear()
        map.customSettingSSR = ''
        map.fire('stationStateChange', {stationName: stationName, selected: false})
        map.fire('change_customSettingSSR')
    }

    const clear = () => {
        setSelectedProducts((prevState) => removeSsrLayers(prevState))
        setEnabledProduct(null)
    }

    const scrollToBeVisible = () => {
        if (enabled && scrollContainerRef.current && accordionRef.current) {
            const scrollContainer = scrollContainerRef.current
            const accordionElement = accordionRef.current

            const accordionRect = accordionElement.getBoundingClientRect()
            const containerRect = scrollContainer.getBoundingClientRect()

            const offsetTop = accordionRect.top - containerRect.top + scrollContainer.scrollTop

            scrollContainer.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            })
        }
    }

    return (
        <Accordion
            onTransitionEnd={scrollToBeVisible}
            ref={accordionRef}
            key={'single-site-radar'}
            data-cy={'single-site-radar-switch'}
            variant={'dark'}
            expanded={enabled}
            onChange={handleToggleProduct}
            disabled={mapMode !== MapMode.live}
        >
            <AccordionSummary
                variant={'dark'}
                sx={{height: '32px'}}
            >
                <Box className={'row gap4'}>
                    <Switch checked={enabled}/>
                    <Typography>
                        Single Site Radar
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                variant={'dark'}
            >
                <Typography color={'var(--palette-grey-500)'}>
                    Select a site from the map to apply layers
                </Typography>
                <Box className={classNames('radio-group', {visible: !!stationName})}>
                    <Chip
                        variant={'white'}
                        label={stationName}
                        onDelete={handleDeselectStation}
                    />
                    <Box>
                        <RadioGroup
                            value={enabledProduct?.id ?? NaN}
                            onChange={handleLayerChange}
                            sx={{paddingLeft: '24px'}}
                        >
                            {group.map((layer) => (
                                <FormControlLabel
                                    data-cy={`${layer.name.toLowerCase()}-radio-button`}
                                    key={layer.id}
                                    control={<Radio size={'small'}/>}
                                    label={layer.name}
                                    value={layer.id}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
