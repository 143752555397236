import { styled } from '@mui/material/styles';
import {isEmpty} from "shared/libs/Utils";
import React, {useState, useEffect} from 'react';
import {
    Box,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MapPinIcon from '../../../shared/assets/icons/MapPin';
import dayjs from "dayjs";
import EmptyStateTimeline from "../../../shared/ui/emptyStates/EmptyStateTimeline";
import {Spacer} from "../../../shared/ui/Spacer";
import {SearchInput} from "../../../shared/ui/SearchInput";
import ArrowRightSmallIcon from "../../../shared/assets/icons/ArrowRightSmall";
import styles from "./TimeLineSidebar.module.scss"
import {clsx} from "clsx";

export function TimeLineSidebar({
                                 // minDate,
                                 // maxDate,
                                 groupList,
                                 selectedLocation,
                                 setSelectedLocation,
                                 severityFilter,
                                 setSeverityFilter,
                                 timelineRange,
                                 chartRef,
                                 timezone,
                             }) {
    const menuStateRef = React.useRef({groups: {}});
    const [menuUpdate, setMenuUpdate] = React.useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [severityCount, setSeverityCount] = useState({
        Critical: 0,
        Severe: 0,
        Moderate: 0,
    });

    const [visibleAlerts, setVisibleAlerts] = useState(new Set());

    const chart = chartRef.current;
    const startDate = timelineRange.startDate;
    const endDate = timelineRange.endDate;

    const formattedStartDate = dayjs(startDate).tz(timezone).format("MMM D 'YY");
    const formattedEndDate = dayjs(endDate).tz(timezone).format("MMM D 'YY");


    useEffect(() => {
        const visAlerts = new Set();
        const series = chart?.series;
        if (series) {
            series[0].data.forEach(e => {
                if ((startDate <= e.end_date && endDate >= e.start_date) ||
                    (startDate >= e.start_date && startDate <= dayjs() && e.options.triggered)) {
                    visAlerts.add(e);
                } else {
                    visAlerts.delete(e);
                }
            });
            setVisibleAlerts(visAlerts);
        }
    }, [timelineRange]);

    useEffect(() => {
        const sev = {...severityCount};
        for (const severity in severityCount) {
            sev[severity] = [...visibleAlerts].filter(e => e.severity === severity).length;
        }
        setSeverityCount(sev);
    }, [visibleAlerts]);


    const handleExpanded = (group) => (event, newExpanded) => {
        menuStateRef.current.groups[group] = newExpanded;
        setMenuUpdate(!menuUpdate);
    };

    // let maxDateLabel = new Date(maxDate).toLocaleDateString();
    // let minDateLabel = new Date(minDate).toLocaleDateString();

    const toggleSeverityFilter = (event, newVal) => {
        if (!newVal) {
            return
        }
        setSeverityFilter(newVal);
    };

    if (groupList.length && !selectedLocation) {
        setSelectedLocation(groupList[0].locations[0]);
    }

    useEffect(() => {
        if (selectedLocation) {
            groupList.forEach(group => {
                const findIndex = group.locations.findIndex((e) => e.id === selectedLocation.id && e.label === selectedLocation.label)
                if (findIndex >= 0) {
                    menuStateRef.current.groups[group.group] = true;
                }
            });
            setMenuUpdate(!menuUpdate);
        }
    }, [selectedLocation]);

    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    const filterLocation = (loc) => {
        if (!filterName(loc.label)) return false;
        if (severityFilter !== 'critical') return true;
        if (loc.Critical) return true;
        return false;
    }
    const MuiAccordionSummary = styled((props) => (
        <AccordionSummary
            expandIcon={
                <IconButton
                    size='tiny'
                    variant='outlined'
                >
                    <ArrowRightSmallIcon size={'small'}/>
                </IconButton>}
            {...props}
        />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
            padding: '0 30px',
        }
    }));

    const selectedBox = (loc) =>
        <Box
            key={loc.id}
            onClick={() => {
                setSelectedLocation({id: loc.id, label: loc.label})
            }}
            className={clsx(styles.locationBoxWrapper,{
                    [styles.selectedLocationBoxWrapper]: selectedLocation && selectedLocation.id === loc.id
            })}
        >
            <Box key={loc.id} className={styles.labelWrapper}
                 sx={{
                ...(selectedLocation?.id !== loc.id && {
                    borderRight: '1px solid transparent'
                })
            }}>
                {
                    (loc.Critical !== 0) &&
                    <Box className={styles.square}></Box>
                }
                <Typography
                    className={clsx(styles.label, {
                        [styles.selectedLabel]: selectedLocation && selectedLocation.id === loc.id,
                        [styles.unselectedLabel]: selectedLocation && selectedLocation.id !== loc.id,
                    })}
                >
                    {loc.label}
                </Typography>
                <Spacer />
                {(loc.type === 'point') &&
                    <MapPinIcon size={'small'} className={clsx({
                        [styles.selectedIcon]: selectedLocation && selectedLocation.id === loc.id,
                        [styles.unselectedIcon]: !selectedLocation && selectedLocation.id !== loc.id,
                    })}/>
                }
            </Box>
            {selectedLocation && selectedLocation.id === loc.id &&
                <Box className={styles.selectedLocationContentWrapper}>
                    <label className={styles.subTitle}>
                        Alerts for {' '}
                        {
                            formattedStartDate + ' - ' + formattedEndDate
                        }
                    </label>

                    <Box className={styles.selectedLocationContent}>
                        <Box flexDirection={'row'} className={styles.severityWrapper}>
                            <Box className={clsx(styles.severityItem, styles.criticalColor)} sx={{
                                flexGrow: severityCount.Critical,
                            }}></Box>
                            <Box className={clsx(styles.severityItem, styles.severeColor)} sx={{
                                flexGrow: severityCount.Severe,
                            }}></Box>
                            <Box className={clsx(styles.severityItem, styles.moderateColor)} sx={{
                                flexGrow: severityCount.Moderate,
                            }}></Box>
                        </Box>

                        <Box className={styles.severityCountWrapper}>
                            <Box className={clsx(styles.severityCount, styles.criticalColor)}></Box>
                            <Typography className={styles.severityCountTitle}>
                                {severityCount.Critical} critical
                            </Typography>
                        </Box>

                        <Box className={styles.severityCountWrapper}>
                            <Box className={clsx(styles.severityCount, styles.severeColor)}></Box>
                            <Typography className={styles.severityCountTitle}>
                                {severityCount.Severe} severe
                            </Typography>
                        </Box>
                        <Box className={styles.severityCountWrapper}>
                            <Box className={clsx(styles.severityCount, styles.moderateColor)}></Box>
                            <Typography className={styles.severityCountTitle}>
                                {severityCount.Moderate} moderate
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>;

    return (
        <Box data-cy={'timeline-sidebar'} className={clsx('column gap0 fullHeight', styles.wrapper)}>
            <Box className={clsx('fullWidth', styles.header)}>
                <SearchInput
                    className={styles.searchInput}
                    placeholder={'Search for location'}
                    onChange={({target}) => setSearchFilter(target.value)}
                />
                <ToggleButtonGroup
                    data-cy={'severity-filter'}
                    className={'fullWidth'}
                    exclusive
                    value={severityFilter}
                    size={'small'}
                    onChange={toggleSeverityFilter}
                >
                    <ToggleButton value={'all'} variant={'secondary'} size={'small'}>
                        All
                    </ToggleButton>
                    <ToggleButton value={'critical'} variant={'secondary'} size={'small'}>
                        Critical
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box className={clsx('fullWidth specific', styles.contentWrapper)}>
                {isEmpty(Object.values(groupList)) ?
                    <Box className={styles.emptyScreen}>
                        <EmptyStateTimeline title={"No locations here yet"} text={"Create locations and alerts to see weather events on the timeline when triggered"}/>
                    </Box> :
                <Box className={'column gap8 fullWidth fullHeight'}>
                    {(searchFilter === '' && severityFilter !== 'critical') &&
                        groupList.map(group => (
                            <Accordion
                                className={'fullWidth'}
                                variant={'dark'}
                                key={group.id}
                                expanded={menuStateRef.current.groups[group.group] === true}
                                onChange={handleExpanded(group.group)}
                            >
                                <MuiAccordionSummary
                                    className={'accordion-summary'}
                                    aria-controls={`locgroup-${group.group}-content`}
                                    id={`locgroup-${group.group}-header`}
                                    variant={'dark'}
                                    sx={{padding: '0 20px'}}
                                >
                                    <Typography style={{
                                        overflowWrap: 'break-word',
                                        transition: "0.2s",
                                        fontWeight: menuStateRef.current.groups[group.group] === true ? "500" : "400",
                                        }}>
                                        {group.group} ({group.locations.length})
                                    </Typography>
                                </MuiAccordionSummary>
                                <AccordionDetails variant={'dark'}>
                                    {group.locations.map(loc => {
                                        return selectedBox(loc);
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                    {(searchFilter !== '' || severityFilter === 'critical') &&
                        <Box sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                            {groupList.map(group => (
                                group.locations.map(loc => (filterLocation(loc) && selectedBox(loc)))
                            ))}
                        </Box>
                    }
                </Box>}
            </Box>
        </Box>
    );
}