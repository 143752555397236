import {BaronStormTracks, Nexrad} from 'features/textProducts'


const AdditionalProducts = [
    ...Object.values(BaronStormTracks.products),
    {
        id: -1013,
        name: 'Current Conditions',
        product_id: 'metar',
        product_group: 'Map Overlays',
        icon: '/images/icons/radar.svg',
        api_product_code: 'metar',
        available: true,
        isHistorical: true,
    },
    {
        id: -1014,
        name: 'NHC Tropical Tracks',
        product_id: 'tropical/cone_forecast',
        product_group: 'Map Overlays',
        icon: '/images/icons/radar.svg',
        api_product_code: 'nhc_tropical_tracks',
        available: true,
        isHistorical: false,
    },
    {
        id: -1014.5,
        name: 'Local Storm Reports',
        product_id: 'lsr',
        product_group: 'Map Overlays',
        icon: '/images/icons/radar.svg',
        api_product_code: 'lsr',
        available: true,
        isHistorical: false,
    },
    ...Object.values(Nexrad.products),
]

export default AdditionalProducts