import {
    Alert,
    Autocomplete,
    Button,
    IconButton,
    InputAdornment,
    Snackbar,
    Typography,
    Divider, MenuItem
} from '@mui/material';
import {Spacer} from "shared/ui/Spacer";
import React, {useEffect, useState} from 'react';
import '../../../widgets/contact/Contacts.css';
import TextField from '@mui/material/TextField';
import {PhoneField} from "../../../shared/ui/PhoneField/PhoneField";
import CloseIcon from "../../../shared/assets/icons/Close";
import EnvelopeIcon from "../../../shared/assets/icons/Envelope";
import PhoneIcon from "../../../shared/assets/icons/Phone";
import UserIcon from "../../../shared/assets/icons/User";
import CopyTooltip from 'shared/ui/CopyTooltip';
import {Portal} from '@mui/base';
import {Box} from "@mui/system";
import PlusSquareIcon from "shared/assets/icons/PlusSquare";
import ContactStatus from "../../../widgets/contact/ContactStatus";
import {useContactsStore} from "../../../app/store/ContactsStore";
import styles from "./EditContactPopup.module.scss"
import {clsx} from "clsx";

const EditContactPopup = ({onClose, contactData}) => {

    const {
        editContact,
    } = useContactsStore((state) => state)

    const {groups, addNewGroup} = useContactsStore((state) => state)

    const [newGroups, setNewGroups] = useState(groups.map(group => group.groupName));
    const allGroupsOptions = newGroups.map((group, index) => ({
        label: group,
        id: index,
    }));

    const initialGroupObject = allGroupsOptions.find(group => group.label === contactData.group) || null;
    const [data, setData] = useState({
        ...contactData,
        group: initialGroupObject,
    });

    const [inputGroupValue, setInputGroupValue] = useState(data.group);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // 'error' or 'success'
    const [emailMassage, setEmailMassage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const CheckIsValidEmail = (emailAddress) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(emailAddress) || emailAddress === ''
    }

    useEffect(() => {
        if (data.userName.trim() !== '' && CheckIsValidEmail(data.email) && data.phone !== 'Invalid' && (data.email || data.phone)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [data]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && isAutocompleteFocused) {
                event.stopPropagation();
                handleAddGroupClick(inputGroupValue.label);
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [inputGroupValue, isAutocompleteFocused]);

    const handleAddGroupClick = (inputValue) => {
        if (inputValue && !newGroups.includes(inputValue)) {
            const newOption = {label: inputValue, id: allGroupsOptions.length + 1};
            addNewGroup(inputValue)
            setData({...data, group: newOption});
            setNewGroups([...newGroups, inputValue]);
            setInputGroupValue(newOption);
        }
    };

    const handleGroupChange = (event, newValue) => {
        setData({...data, group: newValue});
        setInputGroupValue(newValue);
    };

    const renderGroupOption = (props, option) => {

        // Add new group
        if (option.inputValue) {
            return (
                <Button
                    variant={'outlined'}
                    size={'small'}
                    startIcon={<PlusSquareIcon size={'small'}/>}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleAddGroupClick(option.inputValue);
                    }}
                    style={{width: 'max-content'}}
                >
                    {option.inputValue}
                </Button>
            );
        }

        // general option
        return <MenuItem {...props} style={{minHeight: "28px"}}>{option.label}</MenuItem>;
    };

    const filterOptions = (options, {inputValue}) => {
        const filtered = options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
        const newGroupArray = newGroups.map(group => group.toLowerCase());
        if (inputValue && !newGroupArray.includes(inputValue.toLowerCase())) {
            filtered.unshift({
                label: `${inputValue}`,
                inputValue,
            });
        }
        console.log("newGroups", newGroups)
        console.log("options", options)
        console.log("filtered", filtered)
        return filtered;
    };

    const openSnackbar = (message, severity = 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const onSaveHandle = () => {

        setEmailMassage('');
        setEmailError(false)

        const newData = {
            'name': data.userName,
            'phone': data.phone,
            'email': data.email,
            'group': data.group ? data.group.label : '',
        }

        editContact(data.id, newData)
            .then(()=>{
                openSnackbar("Contacts saved successfully", "success");
                setTimeout(() => {
                    onClose();
                }, 3000);
            })
            .catch(error => {
                console.error('Error saving contacts:', error);
                serverErrorMessage(error.response.data);
            });
    }

    const serverErrorMessage = (errorResponse) => {
        let errorMessage = 'An error occurred. Please try again.';
        if (errorResponse.errors) {
            const errorMessages = errorResponse.errors.map(error => Object.values(error).join(', ')).join(' ');
            errorMessage = errorMessages;
        }
        openSnackbar(errorMessage, 'error');
    };

    return (
        <Box className={'column gap24 fullWidth'} style={{width: '520px', alignItems: 'center', padding: "24px"}}>
            <div className={'row fullWidth'}>
                <h3>Edit contact</h3>
                <ContactStatus status={data.status}/>
                <Spacer/>
                <IconButton variant={'outlined'} onClick={() => onClose()}>
                    <CloseIcon size={'small'}/>
                </IconButton>
            </div>

            <Typography className={clsx('fullWidth', styles.subTitle)} variant={'label'}>
                Name
                <TextField
                    autoComplete={'on'}
                    defaultValue={data.userName}
                    onChange={e => {
                        if (e.target.value.trim() !== '') {
                            // setIsValid(true);
                            setData({...data, email: e.target.value});
                        } else {
                            setIsValid(false)
                        }
                        setData({...data, userName: e.target.value});
                        // setChanges({...changes, isNameChanged: true});
                        console.log('name changed: %s', e.target.value);
                    }}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.userName}/>,
                    }}>
                </TextField>
            </Typography>
            <Typography className={clsx('fullWidth', styles.subTitle)} variant={'label'}>
                Email address
                <TextField
                    autoComplete={'on'}
                    inputMode={'email'}
                    defaultValue={data.email}
                    onChange={e => {
                        setData({...data, email: e.target.value});
                    }}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <EnvelopeIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.email}/>,
                    }}
                    helperText={emailMassage}
                    error={emailError || ((!CheckIsValidEmail(data.email) || data.email === '' && (data.phone === 'Invalid' || data.phone === '')) || !CheckIsValidEmail(data.email))}
                />
            </Typography>
            <Typography className={clsx('fullWidth', styles.subTitle)} variant={'label'}>
                Phone number
                <PhoneField
                    inputMode={'tel'}
                    phone={data.phone}
                    onChange={(phoneInput) => {
                        setData({...data, phone: phoneInput});
                    }}
                    errorBorder={data.email.trim() === ''}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.phone}/>,
                    }}/>

            </Typography>

            <Typography className={clsx('fullWidth', styles.subTitle)} variant={'label'}>
                User group
                <Autocomplete
                    value={inputGroupValue}
                    onChange={handleGroupChange}
                    onInputChange={(event, newInputGroupValue) => {
                        setInputGroupValue({label: newInputGroupValue, id: allGroupsOptions.length + 1});
                    }}
                    onFocus={() => setIsAutocompleteFocused(true)}
                    onBlur={() => setIsAutocompleteFocused(false)}
                    options={allGroupsOptions}
                    getOptionLabel={(option) => option ? option.label : ''}
                    filterOptions={filterOptions}
                    renderOption={renderGroupOption}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder='No group'
                        />
                    )}
                    sx={{width: '100%'}}
                    renderTags={() => null}
                />
            </Typography>

            <Divider/>
            <Box className={'row fullWidth'}>
                <Button
                    className={'spacer'}
                    style={{width:"50%"}}
                    onClick={() => onSaveHandle()}
                    disabled={!isValid}
                >
                    Save contact
                </Button>
                <Button
                    className={'spacer'}
                    style={{width:"50%"}}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Portal>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Portal>
            </Box>
        </Box>
    );
}
export default EditContactPopup