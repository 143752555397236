import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './app/reportWebVitals';
import App from "./app/App";
import BaronSignaturedRequester from "./shared/libs/requester/baron-signature";

export const requester = window.requester = new BaronSignaturedRequester({
    url_prefix: 'cdn',
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // strict mode was disabled cause it breakes map tests via double rerendering.
    // Enable if needed
  // <React.StrictMode>
      <BrowserRouter>
          <App/>
      </BrowserRouter>
  // </React.StrictMode>
);


reportWebVitals();
