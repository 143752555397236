import {Box, IconButton, Menu, Typography, MenuItem, Chip} from '@mui/material'
import {Spacer} from 'shared/ui/Spacer'
import MoreIcon from 'shared/assets/icons/More'
import React, {useState} from 'react'
import theme from 'app/Theme'
import {CheckBox} from 'shared/ui/CheckBox'
import AgreeModal from 'shared/ui/AgreeModal'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import TrashIcon from '../../../../../shared/assets/icons/Trash'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import CopyIcon from '../../../../../shared/assets/icons/Copy'
import BellIcon from '../../../../../shared/assets/icons/Bell'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'
import styles from "./WeatherDefinitionLine.module.scss"

export default function WeatherDefinitionLine({definition, selected}) {
    const {
        toggleEditingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const {
        openWeatherDefinition,
        toggleEditingWeatherDefinition,
        deleteWeatherDefinition,
        selectWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false)

    const open = !!anchorEl
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleOpen = () => {
        openWeatherDefinition(definition)
    }

    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const handleNewAlertConfig = () =>{
        setAnchorEl(null)
        toggleEditingAlertConfiguration({definition: definition.id})
    }

    const handleSelect = (event) => {
        event.stopPropagation()
        selectWeatherDefinition(definition)
    }

    const handleCopy = ()=>{
        setAnchorEl(null)
        toggleEditingWeatherDefinition({
            ...definition,
            id: null,
            name: definition.name + ' Copy',
        })
    }

    const handleEdit = () => {
        setAnchorEl(null)
        toggleEditingWeatherDefinition(definition)
    }

    const handleDelete = (event) => {
        event.stopPropagation()
        setIsAgreeModalOpen(true)
    }

    const confirmDeleteWeatherDefinition = async (agreed) => {
        if (agreed) {
            await deleteWeatherDefinition(definition)
        }
        setAnchorEl(null)
        setIsAgreeModalOpen(false)
    }

    return (
        <>
            <div
                className={styles.weatherDefinitionLine}
                onClick={handleOpen}
                data-cy={'wd-line'}
            >
                <CheckBox
                    data-cy={'wd-line-checkbox'}
                    checked={selected}
                    onClick={handleSelect}
                />
                <Typography>
                    {definition.name}
                </Typography>
                <Spacer/>
                <Chip
                    label={definition.severity}
                    variant={theme.palette.weather.variant[definition.severity]}
                    size={'small'}
                />
                <div
                    style={{display: 'flex'}}
                    data-cy={'wd-line-menu'}
                >
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant={'outlined'}
                        size={'tiny'}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            data-cy={'wd-line-add-alert-button'}
                            onClick={handleNewAlertConfig}
                            key="add"
                        >
                            <BellIcon size={'small'}/>
                            Add alert
                        </MenuItem>
                        {definition.rules &&
                            <MenuItem
                                onClick={handleCopy}
                                data-cy={'wd-line-copy-button'}
                                key="duplicate"
                            >
                                <CopyIcon size={'small'}/>
                                Copy
                            </MenuItem>
                        }
                        {definition.user && definition.rules &&
                            <MenuItem
                                onClick={handleEdit}
                                data-cy={'wd-line-edit-button'}
                                key="edit"
                            >
                                <PenIcon size={'small'}/>
                                Edit
                            </MenuItem>
                        }
                        {definition.user && definition.rules &&
                            <MenuItem
                                color={'error'}
                                onClick={handleDelete}
                                data-cy={'wd-line-delete-button'}
                                key="delete"
                            >
                                <TrashIcon size={'small'}/>
                                Delete
                            </MenuItem>
                        }
                    </Menu>
                </div>
            </div>
            {isAgreeModalOpen &&
                <AgreeModal
                    data={{
                        message:
                            <>
                                Are you sure you want to delete
                                {' '}
                                <span className={styles.agreeModalText}>
                                    {' '}
                                    {definition.name}
                                </span>
                                {' '}
                                weather defintion and
                                {' '}
                                <span className={styles.agreeModalText}>
                                    {' '}
                                    all alert configurations and action items
                                </span>
                                {' '}
                                linked to this definition?
                            </>,
                        title: 'Delete weather definition',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: confirmDeleteWeatherDefinition,
                    }}
                />
            }
        </>
    )
}
