import {MapMode, useMapStore} from "../../../../app/store/MapStore";
import BaronStormTracks from "../../../../features/textProducts/ui/baronStormTracks/BaronStormTracks";
import {NON_STANDARD_PROJECTION} from "../../ui/MapPage/MapPage";
import {TileLayer, SSRTileLayer} from './Tilev2';
import {WmsLayer} from './WMSv2';
import {TextProductHandler} from "features/textProducts/lib/TextProductsHandler";
import {TimeRangeMap} from "./TimeRangeMap";

export const TEXT_PRODUCTS_CODES = [
    'lightning',
    'metar',
    'metar-advanced',
    'nexrad',
    'nhc_tropical_tracks',
    'lsr',
    'baron_storm_tracks',
    'baron_storm_tracks_tornado_high_probability',
    'baron_storm_tracks_tornado_low_probability',
    'baron_storm_tracks_high_winds',
    'baron_storm_tracks_extreme_hail',
    'baron_storm_tracks_hail',
    'baron_storm_tracks_storm',
];

export const HistoricalProductCodes = [
    'north-american-radar',           // Radar - Current
    'global-derived-radar',           // Global Radar - Current
    'dmg-wind-path',                  // Damaging Wind Path - Current
    'hail-current',                   // Hail Probability - Current
    'hail-size',                      // Hail Size - Current
    'pwr-outage-county-percent',      // Power Outages - County - Current
    'watches-and-warnings',           // Watches and Warnings
    'flash-flood-risk',               // Flash Flood Risk
    'precip-liquidaccum-1hr',         // Rainfall Accumulation - Current
    'snowaccum-current',              // Snow Accumulation - Current
    'precip-totalaccum-24hr',         // 24hr Total Accumulations
    'temperature-current',            // Surface Temperature - Current
    'heat-index-current',             // Heat Index - Current
    'wind-chill-current',             // Wind Chill - Current
    'temperature-change-24h',         // 24-hour Temperature Change
    'wind-speed',                     // Wind Speed - Current
    'waveheight',                     // Wave Heights
    'fog-current',                    // Fog - Current
    'pressure-current',               // Pressure - Current
    'relative-humidity-current',      // Relative Humidity - Current
    'road-condition-current-hires',   // Road Conditions - Current
    'C39-0x03A1-0',                   // Damaging Wind Path - Current
    'C39-0x038A-0',                   // Hail Probability - Current
    'C09-0x0396-0',                   // Smoke, Fire/Smoke/Ash
    'metar',                          // Current Conditions
    'extreme-weather-index-temp-min',         // Extreme Weather Index - Cold
    'extreme-weather-index-hail',             // Extreme Weather Index - Hail
    'extreme-weather-index-temp-max',         // Extreme Weather Index - Heat
    'extreme-weather-index-liquidaccum-24hr', // Extreme Weather Index - Rain
    'extreme-weather-index-snowaccum-24hr',   // Extreme Weather Index - Snow
    'extreme-weather-index-windspeed',        // Extreme Weather Index - Wind
    'baron_storm_tracks',                           // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_tornado_high_probability',  // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_tornado_low_probability',   // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_high_winds',                // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_extreme_hail',              // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_hail',                      // Baron Storm Tracks, stormvector/baron
    'baron_storm_tracks_storm',                     // Baron Storm Tracks, stormvector/baron
]

export class ProductsController {

    constructor(options) {
        this.tileLayers = [];
        this.wmsLayers = [];
        this.signature = options.signature;
        this.map = options.map

        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.time = options.time;

        this.opacity = options.opacity || 1;
        this.mode = false;
        this.textProductController = new TextProductHandler(this.map, options.signature);
    }

    calculateTimeRange(productCodes) {
        const times = productCodes.map(s => (s && s.split('/')[0])).map(h => TimeRangeMap[h]).filter(time => !isNaN(time))
        const min = Math.min(0, ...times) * 60 * 1000; // min <0 for current
        const max = Math.max(0, ...times) * 60 * 1000;
        const now = new Date().getTime()

        return {
            now,
            startDate: (min === max) ? now - 3600 * 1000 : now + min,
            endDate: now + max
        }
    }

    async setProducts2(newProducts) {
        const {products} = useMapStore.getState()
        const mapProducts = []
        const productObjects = [] // REALLY CRINGE!!! TODO: pass into entire product object, not only product code

        newProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p.id))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
                mapProducts.push(productCode)
                productObjects.push(prod)
            } else {
                console.error(`Product with id ${p.id} not found.`)
            }
        })

        this.setProducts(mapProducts, productObjects)
    }

    setProducts(productList = [], productObjects = []) {
        const newTileLayers = [];
        this.tileLayers.forEach(l => {
                l.disable();
        });
        this.wmsLayers.forEach(l => {
            l.remove()
        })
        this.wmsLayers=[]

        TEXT_PRODUCTS_CODES.forEach(p => {
            if (!productList.includes(p)) {
                this.textProductController.disableProduct(p);
            }
        });
        productList.forEach(p => {
                TEXT_PRODUCTS_CODES.includes(p) ?
                    this.textProductController.enableProduct(p, productObjects) :
                    newTileLayers.push(this._createLayer(p));
        })

        this.tileLayers = newTileLayers
    }

    switchToTmsMode(force=false) {
        if (!this.mode && !force) {
            return
        }

        this.mode = false
        this.wmsLayers.forEach(l => l.remove());
        this.wmsLayers = [];
        this.tileLayers.forEach(l => {
            l.update()
            l.show()
        });
    }

    switchToWmsMode(onLoad) {
        this.mode = true
        let count = this.tileLayers.length
        if (!count) {
            onLoad && onLoad()
            return
        }
        const steps = [];
        for (let t = this.startDate; t < this.endDate; t += Math.round((this.endDate - this.startDate) * 0.01)) {
            steps.push(t);
        }
        this.wmsLayers.forEach(l => l.remove());
        this.wmsLayers = this.tileLayers.map(layer => {
            layer.hide();
            return new WmsLayer({
                layer,
                steps,
                onReady: () => {
                    count--;
                    if (!count) {
                        onLoad && onLoad()
                        return;
                    }
                }
            });
        });
    }

    setOpacity(opacity = 1) {
        this.tileLayers.forEach(l => l.setOpacity(opacity));
        this.wmsLayers.forEach(l => l.setOpacity(opacity));
    }

    setTime(time) {
        this.time = time;
        this.tileLayers.forEach(l => l.setTime(time));
        this.wmsLayers.forEach(l => l.setTime(time));
        this.textProductController.setTime(time);
    }

    setTimeRange({startDate, endDate}) {
        this.startDate = startDate || this.startDate;
        this.endDate = endDate || this.endDate;
        this.tileLayers.forEach(l => l.setTimeRange({startDate, endDate}));
    }

    async getPQ({lat, lng}) {
        const promises = [];
        for (let key in this.tileLayers) {
            const layer = this.tileLayers[key];
            let promise;
            if (layer.id === "alert-all-poly/Standard-Mercator") {
                promise = layer.getPointQueryForWatchesAndWarningsText(lat, lng, this.time)
            } else {
                promise = layer.getPointQuery(lat, lng, this.time);
            }
            promises.push(promise);
        }

        return Promise.all(promises).then(values => {
            const response = {};
            values.forEach(v => {
                if (!v) return;
                response[v.id] = v.data;
            });
            return response;
        });
    }

    _createLayer(layerId) {
        const obj = {
            id: layerId,
            map: this.map,
            firstLabelLayerId: layerId.includes('radar') ? 'tunnel-service-track-casing' : 'building-top',
            startDate: this.startDate,
            endDate: this.endDate,
            time: this.time,
            signature: this.signature,
            onInit: () => {
            }
        };

        if (layerId.includes('{SSR}')) {
            return new SSRTileLayer(obj);
        }
        return new TileLayer(obj);
    }

    destroy() {
        this.tileLayers = []
        this.wmsLayers = []
        this.textProductController.destroy()
    }
}