import {useState} from 'react'
import 'react-phone-input-2/lib/material.css'
import 'shared/ui/PhoneField/PhoneField.css'
import {TextField, InputAdornment} from '@mui/material'

export function PhoneField({InputProps, slotProps, onChange = () => {}, errorBorder = true, phone, ['data-cy']: dataCy='phone-input', ...other}) {
    const [isValidPhone, setIsValidPhone] = useState(!errorBorder || phone !== '')
    const [phoneState, setPhoneState] = useState(formatPhoneNumber(phone || ''))

    function formatPhoneNumber(value) {
        const digits = value.replace(/\D/g, '')
        // check country code == "1", in future just delete digits.charAt(0) === '1' for all codes
        if (digits.length > 10 && digits.charAt(0) === '1') {
            return digits.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
        } else {
            return digits.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3')
        }
    }

    const handlePhoneChange = (event) => {
        const inputDigits = event.target.value.replace(/\D/g, '')
        let limitedDigits
        if (inputDigits.charAt(0) === '1') {
            limitedDigits = inputDigits.substring(0, 11)
        } else {
            limitedDigits = inputDigits.substring(0, 10)
        }

        const formattedValue = formatPhoneNumber(limitedDigits)
        setPhoneState(formattedValue)

        const isValid
            = (formattedValue.length === 14) // (XXX) XXX-XXXX
            || (formattedValue.length === 16) // 1 (XXX) XXX-XXXX

        setIsValidPhone(isValid)
        onChange(isValid ? formattedValue : 'Invalid')
    }

    return (
        <div style={{display: 'grid', ...slotProps?.root?.sx, padding: '0 1px'}}>
            <TextField
                data-cy={dataCy}
                value={phoneState}
                onChange={handlePhoneChange}
                error={!isValidPhone}
                placeholder="(702) 123-4567"
                InputProps={{
                    startAdornment: InputProps?.startAdornment ? (
                        <InputAdornment position="start">
                            {InputProps.startAdornment}
                        </InputAdornment>
                    ) : null,
                    endAdornment: InputProps?.endAdornment ? (
                        <InputAdornment position="end">
                            {InputProps.endAdornment}
                        </InputAdornment>
                    ) : null,
                    readOnly: InputProps?.readOnly,
                }}
                fullWidth
                {...other}
            />
        </div>
    )
}
