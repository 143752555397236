import {Box} from '@mui/material'
import React from 'react'

export function AlertCardGroup({children}) {
    return (
        <div className={'gridGroup'}>
            {children}
        </div>
    )
}
