import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {
    createDashboardView,
    deleteDashboardView, deleteWidget,
    fetchDashboardViews,
    fetchWidgets,
    patchDashboardView,
    postWidget,
    putWidget
} from "../../pages/alerts/api/FetchAlerts";

export const useDashboardStore = create(devtools((set, get) => ({
    dashboardViews: [],
    editingDashboardView: null,
    isDuplicatingView: false,

    dashboardWidgets: [],
    editingDashboardWidget: null,
    isDuplicatingWidget: false,

    fetchViews: async (ids = []) => {
        set({
            editingDashboardView: null,
            isDuplicatingView: false,
            editingDashboardWidget: null,
            isDuplicatingWidget: false,
        });

        const response = await fetchDashboardViews(ids)
        set((state) => ({
            dashboardViews: response
        }));
    },

    fetchDashboardWidgets: async (ids = []) => {
        set({
            editingDashboardWidget: null,
            isDuplicatingWidget: false
        });

        const response = await fetchWidgets(ids)
        set((state) => ({
            dashboardWidgets: response
        }));
    },

    createView: async (viewData) => {
        const {fetchViews} = get()
        try {
            const response = await createDashboardView({id: viewData.id, name: viewData.name, widgets: viewData.widgets || []})
            if (response && response.id) {
                fetchViews()
                return response
            } else {
                console.error("Error with creating view");
            }
        } catch (error) {
            console.error("Error with creating view:", error);
        } finally {
            set({
                isDuplicatingView: false,
            });
        }
    },

    createWidget: async (typeOfWidget, viewID, locationID, chosenUnits) => {
        const data = {
            name: typeOfWidget,
            type: typeOfWidget,
            view: viewID,
            location: locationID,
            options: {
                chosenUnits: chosenUnits
            },
        }
        try {
            const response = await postWidget(data)
            if (response && response.id) {
                set((state) => ({
                    dashboardWidgets: [
                        ...state.dashboardWidgets,
                        {
                            id: response.id,
                            name: typeOfWidget,
                            type: typeOfWidget,
                            view: viewID,
                            location: locationID,
                            options: {
                                chosenUnits: chosenUnits
                            },
                        }
                    ],
                    dashboardViews: state.dashboardViews.map((view) =>
                        view.id === viewID
                            ? { ...view, widgets: [...view.widgets, response.id] }
                            : view
                    ),
                }))
                return response
            } else {
                console.error("Error with creating widget")
            }
        } catch (error) {
            console.error("Error with creating widget:", error)
        } finally {
            set({
                editingDashboardWidget: null,
                isDuplicatingWidget: false
            });
        }
    },

    editView: async (id, name, order) => {
        try {
            const response = await patchDashboardView({id: id, name: name, order: order})
            if (response && response.id) {
                set((state) => ({
                    dashboardViews: state.dashboardViews.map((view) =>
                        view.id === response.id
                            ? {
                                ...view,
                                ...(name && { name: name }),
                                ...(order && { order: order }),
                            }
                            : view
                    )
                }));
                return response;
            } else {
                console.error("Error with editing view")
            }
        } catch (error) {
            console.error("Error during editing:", error)
        }
    },

    editWidget: async (id, typeOfWidget, viewID, locationID, chosenUnits) => {
        const data = {
            id: id,
            name: typeOfWidget,
            type: typeOfWidget,
            view: viewID,
            location: locationID,
            options: {
                chosenUnits: chosenUnits
            },
        }
        try {
            const response = await putWidget(data)
            if (response && response.id) {
                set((state) => ({
                    dashboardWidgets: state.dashboardWidgets.map((widget) =>
                        widget.id === id
                            ? { ...widget, name: typeOfWidget, type: typeOfWidget, view: viewID, location: locationID, options: {chosenUnits: chosenUnits}}
                            : widget
                    ),
                }))
                return response
            } else {
                console.error("Error with editing widget")
            }
        } catch (error) {
            console.error("Error with editing widget:", error)
        } finally {
            set({
                editingDashboardWidget: null,
                isDuplicatingWidget: false
            });
        }
    },

    toggleEditingDashboardView: (view) => {
        set((state) => ({
            editingDashboardView: view
        }))
    },

    toggleEditingDashboardWidget: (widget) => {
        set((state) => ({
            editingDashboardWidget: widget
        }))
    },

    toggleDuplicationView: (value) => {
        set((state) => ({
            isDuplicatingView: value
        }))
    },

    toggleDuplicationWidget: (value) => {
        set((state) => ({
            isDuplicatingWidget: value
        }))
    },

    deleteDashboardView: async (id) => {
        try {
            const response = await deleteDashboardView(id)
            if (response) {
                set((state) => ({
                    dashboardViews: state.dashboardViews.filter(view => view.id !== id)
                }))
            } else {
                console.error("Error with deleting view")
            }
        } catch (error) {
            console.error("Error during deletion:", error)
        }
    },

    deleteDashboardWidget: async (id) => {
        try {
            const response = await deleteWidget(id)
            if (response) {
                set((state) => ({
                    dashboardWidgets: state.dashboardWidgets.filter(widget => widget.id !== id),
                    dashboardViews: state.dashboardViews.map(view => ({
                        ...view,
                        widgets: view.widgets.filter(widgetId => widgetId !== id)
                    }))
                }))
            } else {
                console.error("Error with deleting widget")
            }
        } catch (error) {
            console.error("Error during deletion:", error)
        }
    },

})));