import React from 'react'
import styles from './ForecastInAdvancedSettings.module.scss'
import {CheckBox} from '../../../../../../../shared/ui/CheckBox'
import {Typography} from '@mui/material'

const ForecastInAdvancedSettings = ({forecastInReport, onChangeForecastInReport}) => {
    const handleSelect = (value)=>{
        if (value !== forecastInReport) {
            onChangeForecastInReport(value)
        } else {
            onChangeForecastInReport(null)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.button}>
                <CheckBox
                    checked={forecastInReport === '7day'}
                    size={'large'}
                    onClick={()=>handleSelect('7day')}
                />
                <Typography className={styles.buttonText}>
                    7-day forecast
                </Typography>
            </div>
            <div className={styles.button}>
                <CheckBox
                    checked={forecastInReport === 'hourly'}
                    size={'large'}
                    onClick={()=>handleSelect('hourly')}
                />
                <Typography className={styles.buttonText}>
                    Hourly forecast
                </Typography>
            </div>
        </div>
    )
}

export default ForecastInAdvancedSettings
