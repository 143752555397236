import React, {useEffect, useState} from 'react'
import styles from './AdvancedReportingStepScreen.module.scss'
import {FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup} from '@mui/material'
import ForecastInAdvancedSettings from './ForecastInAdvancedSettings/ForecastInAdvancedSettings'

const AdvancedReportingStepScreen = ({forecastInReport, onChangeForecastInReport}) => {
    const [selectedAdvancedSetting, setSelectedAdvancedSetting] = useState('forecast') // 'timeline' or 'map' or 'forecast' or 'weatherInspector' or 'weatherIndex'
    const [showForecast, setShowForecast] = useState(false)

    useEffect(() => {
        if (forecastInReport && selectedAdvancedSetting === 'forecast') {
            setShowForecast(true)
        }
    }, [selectedAdvancedSetting])

    const handleChangeAdvancedSetting = (event, newVal) => {
        if (!newVal) {
            return
        }
        setSelectedAdvancedSetting(newVal)
    }

    const handleChangeForecastVisible = (value)=>{
        setShowForecast(!showForecast)
        if (!value) {
            onChangeForecastInReport(null)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <ToggleButtonGroup
                    className={styles.menuWrapper}
                    value={selectedAdvancedSetting}
                    exclusive
                    size={'small'}
                    onChange={handleChangeAdvancedSetting}
                >
                    <ToggleButton
                        className={styles.menuItem}
                        value={'timeline'}
                        size={'small'}
                        disabled
                    >
                        Timeline
                    </ToggleButton>
                    <ToggleButton
                        className={styles.menuItem}
                        value={'map'}
                        size={'small'}
                        disabled
                    >
                        Map
                    </ToggleButton>
                    <ToggleButton
                        className={styles.menuItem}
                        value={'forecast'}
                        size={'small'}
                    >
                        Forecast
                    </ToggleButton>
                    <ToggleButton
                        className={styles.menuItem}
                        value={'weatherIndex'}
                        size={'small'}
                        disabled
                    >
                        Weather Index
                    </ToggleButton>
                </ToggleButtonGroup>

                {selectedAdvancedSetting === 'forecast' &&
                    <FormGroup className={styles.checkBoxMenu}>
                        <FormControlLabel
                            label={<div className={styles.checkBoxLabel}>
Show forecast
                            </div>}
                            control={<Switch
                                onChange={()=>handleChangeForecastVisible(!showForecast)}
                                checked={showForecast || !!forecastInReport}
                            />}
                        />
                    </FormGroup>
                }
            </div>

            {showForecast &&
                <ForecastInAdvancedSettings
                    forecastInReport={forecastInReport}
                    onChangeForecastInReport={onChangeForecastInReport}
                />
            }
        </div>
    )
}

export default AdvancedReportingStepScreen
