import {Button} from '@mui/material'

import React from 'react'

import ArrowDownSmallIcon from 'shared/assets/icons/ArrowDownSmall'
import {Spacer} from 'shared/ui/Spacer'

const DropdownButton = ({onClick, open, ...other}) => {
    return (
        <Button
            {...other}
            variant={''}
            color={''}
            size={'small'}
            onClick={onClick}
            endIcon={
                <ArrowDownSmallIcon
                    pointerEvents="none"
                    size={'small'}
                    sx={{transform: open ? 'rotate(180deg)' : 'rotate(0deg)'}}
                />
            }
            sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
        />
    )
}

export function CalendarHeader({slotProps, view, removeMonthPicker= false}) {
    return (
        <div className={'row'} style={{width: '100%', gridColumn: 2}}>
            {!removeMonthPicker &&
                <DropdownButton
                    {...slotProps?.month?.button}
                    open={view === 'month'}
                    sx={{marginLeft: '-6px'}}
                    data-cy={'month-selector-button'}
                >
                    <div className={'paragraph medium'}>{slotProps?.month?.value}</div>
                </DropdownButton>
            }
            <Spacer/>
            <DropdownButton
                {...slotProps?.year?.button}
                open={view === 'year'}
                sx={{marginRight: '-6px'}}
                data-cy={'year-selector-button'}
            >
                <div className={'paragraph medium'}>{slotProps?.year?.value}</div>
            </DropdownButton>
        </div>
    )
}