import {SvgIcon} from '@mui/material'
import {ReactComponent} from 'shared/assets/iconsSvg/play-icon.svg'

export default function PlayIcon(props) {
    return (
        <SvgIcon
            {...props}
            component={ReactComponent}
            inheritViewBox
        />
    )
}
