import {
    Lightnings,
    CurrentConditions,
    Nexrad,
    NhcTropicalTracks, BaronStormTracks,
    LocalStormReport
} from 'features/textProducts';
import maplibregl from 'maplibre-gl';


/**
 * properties from features should contains field "displayPopupInfo"
 * as object for display, e.g.
 * displayPopupInfo: { name: "name"}
 * 
 * 
 */

export class TextProductHandler{

    constructor(map, signature){
        this.map = map;
        this.signature = signature;
        this.popupElement = document.createElement('div');
        this.popupElement.className = 'impmarker';
        this.popupElement.setAttribute('data-cy', 'text-product-popup')
        this.popupElement.addEventListener('click',event=>{
            event.stopPropagation();
        });
        this.popupElement.addEventListener('wheel',event=>{
            event.stopPropagation();
        });
        this.popupElement.addEventListener('dblclick',event=>{
            event.stopPropagation();
        });
        this.popup = new maplibregl.Marker({
            element: this.popupElement,
            color: "#FF0000",
            anchor: 'top',
            draggable: true
        });
        this.selectedProducts = {};
        this._clickEventHandler = this._clickEventHandler.bind(this);
        this._popupOnMap = false;
        this._time = undefined;
        map.on('click', this._clickEventHandler);
    }

    _clickEventHandler(e, isRenew){
        this._lastClickEvent = e;

        let bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5]
        ];

        //queryRenderedFeatures is possible returns duplicates, then
        // for text data we can display only unique "displayPopupInfo", because 
        // it stored as string.
        let defaultInfoFeatures = this.map.queryRenderedFeatures(bbox);
        let htmlContent = `<div class="impmarker-header"><div class="impmarker-header-label">Information</div><div class="impmarker-closebtn">×</div></div><div class="impmarker-body">`,
            contentArr = [];
        defaultInfoFeatures.forEach(f=>{
            if (f.properties && f.properties.displayPopupInfo){
                if (!contentArr.includes(f.properties.displayPopupInfo)){
                    contentArr.push(f.properties.displayPopupInfo);
                }
            }
        });
        contentArr.forEach((str,index)=>{
                const data = JSON.parse(str);
                for (let key in data){
                    
                    if (!data[key]){
                        htmlContent += `<div class="impmarker-simplebody-line"><span>${key}</span></div>`;
                    }else{
                        htmlContent += `<div class="impmarker-simplebody-line"><span class="impmarker-simplebody-line-type">${key}: </span> <span class="impmarker-simplebody-line-value">${data[key]}</span></div>`;
                    }
                }
                if (index !== contentArr.length-1){
                    htmlContent += '<hr/>';
                }
        });
        htmlContent+='</div>';

        if (contentArr.length){
            this.popupElement.innerHTML = htmlContent;
            const anchorPopup = (isRenew && this.popup._lngLat) ?this.popup._lngLat : e.lngLat;
            this.popup.remove();
            this.popup.setLngLat(anchorPopup).addTo(this.map);
            this._popupOnMap = true;
            this.popupElement.querySelector('.impmarker-closebtn').addEventListener('click', event=>{
                this.popup.remove();
                this._popupOnMap = false;
                event.stopPropagation();
            });
        }else{
            if (isRenew){
                this.popup.remove();
                this._popupOnMap = false;
            }
        }
    }

    enableProduct(productName, productObjects){
        if ( this.selectedProducts[productName]){
            return;
        }
        const mappedProd = this._mapClasses[productName];
        if (!mappedProd || !mappedProd.constructorClass){
            return;
        }

        let product = productObjects.find(p => p.api_product_code === productName)
        let layerTextProduct = new mappedProd.constructorClass(Object.assign({
            map: this.map,
            signature: this.signature,
            productCode: productName,
            product: product
        }, mappedProd.options ? mappedProd.options : {}));
        layerTextProduct.enable();
        this._time && layerTextProduct.setTime && layerTextProduct.setTime(this._time);
        this.selectedProducts[productName] = layerTextProduct;
    }
    
    enableProduct2(product){
        if ( this.selectedProducts[product.name]){
            return;
        }
        const mappedProd = this._mapClasses[product.name];
        if (!mappedProd || !mappedProd.constructorClass){
            return;
        }

        let layerTextProduct = new mappedProd.constructorClass(Object.assign({
            map: this.map,
            signature: this.signature,
            productCode: product.name,
            product: product
        }, mappedProd.options ? mappedProd.options : {}));
        layerTextProduct.enable();
        this._time && layerTextProduct.setTime && layerTextProduct.setTime(this._time);
        this.selectedProducts[product.name] = layerTextProduct;
    }

    isEnabled(productName) {
         return !!this.selectedProducts[productName]
    }

    getProduct(productName) {
         return this.selectedProducts[productName]
    }

    disableProduct(productName){
        if (!this.selectedProducts[productName]){
            return;
        }
        this.selectedProducts[productName].disable();
        delete this.selectedProducts[productName];
    }

    renewPopup(){
        if (this._popupOnMap){
            this._clickEventHandler(this._lastClickEvent, true);
        }
    }

    setTime(time){
        this._time = time;
        for (let key in this.selectedProducts) {
            if (typeof(this.selectedProducts[key].setTime) === 'function') {
                this.selectedProducts[key].setTime(time);
            }
        }
    }

    _mapClasses = {
        "lightning":{
            constructorClass: Lightnings
        },
        "nhc_tropical_tracks": {
            constructorClass: NhcTropicalTracks
        },
        "lsr": {
            constructorClass: LocalStormReport,
        }
    }

    destroy() {
        for (const productKey in this.selectedProducts) {
            const product = this.selectedProducts[productKey]
            product.destroy()
        }
    }
}