import {IconButton} from '@mui/material'
import dayjs from 'dayjs'
import maplibregl from 'maplibre-gl'
import {fetchCityNearest, fetchForecast, fetchMetarNearest} from 'pages/alerts/api/FetchAlerts'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {TextProductsConverter} from 'shared/libs/TextProductsConverter'
import './PinPointTool.css'
import MapMarkerIcon from '../../../../shared/assets/icons/MapMarker'

export default function PinPointTool({activeTool, setActiveTool=()=>{}}) {
    const [isActive, setIsActive] = useState(false)
    const isActiveRef = useRef(false)
    const pinPoint = useRef(null)

    useEffect(() => {
        if (isActive) {
            setActiveTool()
            window?.map?.on?.('click', handleClickOnMap)
        } else {
            window?.map?.off?.('click', handleClickOnMap)
        }
        if (!isActive) {
            clearAll()
        }
    }, [isActive])

    useEffect(() => {
        if (activeTool !== 'PinPointTool') {
            setIsActive(false)
            clearAll()
        }
    }, [activeTool])

    const handleClickOnButton = () => {
        setIsActive((prevState) => {
            const newIsActive = !prevState
            isActiveRef.current = newIsActive
            if (newIsActive) {
                handleClickOnMap()
            }

            return newIsActive
        })
        setActiveTool()
    }

    const handleClickOnMap = useCallback((event = null) => {
        if (!isActiveRef.current) {
            return
        }

        removePinPoint()

        const coordinates = getCoordinates(event)
        const popup = createPopup(coordinates)
        pinPoint.current = createMarker(coordinates, popup)
    }, [])

    const getCoordinates = (clickEvent) => (clickEvent ? clickEvent.lngLat : window.map.getCenter())

    const createPopup = (coordinates) => {
        const popup = new maplibregl.Popup({closeOnClick: false})
            .setHTML('<div>Loading...</div>')
            .on('close', () => {
                if (pinPoint.current) {
                    pinPoint.current._popup = null
                    pinPoint.current.remove()
                }
            })
        popup._closeButton.setAttribute('data-cy', 'close-button')
        loadPopupInfo(coordinates, popup)

        return popup
    }
    const createMarker = (coordinates, popup) => {
        const marker = new maplibregl.Marker({draggable: true})
        marker.setLngLat(coordinates)
            .setPopup(popup)
            .addTo(window.map)
            .togglePopup()
            .on('dragend', (e) => {
                loadPopupInfo(e.target.getLngLat(), popup)
            })

        return marker
    }
    const loadPopupInfo = (coordinates, popup) => {
        Promise.all([
            fetchMetarNearest(coordinates).then((response) => response?.data?.metars?.data ?? {}),
            fetchCityNearest(coordinates).then((response) => response?.data?.city?.data?.[0] ?? {}),
            fetchForecast(coordinates).then((response) => setForecastData(response.data.pointforecast_basic.data)),
        ])
            .then(([currentConditions, city, forecast]) => {
                setPopupData(currentConditions, city, forecast) // Call foo with data from both fetches
            })
            .catch((error) => {
                console.error('Error fetching data:', error) // Handle errors if any
            })

        const setPopupData = (currentConditions, city, forecast) => {
            const products = formatProducts(currentConditions, city)
            const validatedProducts = validateProducts(products)
            const popupHtml = createPopupHtml(validatedProducts)
            popup.setHTML(popupHtml)
            popup._closeButton.setAttribute('data-cy', 'close-button')

            const mainPopup = pinPoint.current?.getPopup()?.getElement()
            if (!mainPopup) {
                return
            }
            const forecastButton = mainPopup.querySelector('.forecastButton')
            forecastButton.addEventListener('click', () => {
                const forecastPopupHtml = createForecastModal(forecast)
                showForecastModal(forecastPopupHtml)
            })
        }

        const setForecastData = (forecast) => forecast.map((day) => ({
            dayTemp: TextProductsConverter.temperature(day?.daytime?.temperature?.value)?.slice(0, -5),
            nightTemp: TextProductsConverter.temperature(day?.nighttime?.temperature?.value)?.slice(0, -5),
            precipitation: day.daytime.precipitation.probability.value,
            weatherCode: day.daytime.weather_code.text,
            weatherCodeValue: day.daytime.weather_code.value,
            date: dayjs(day.daytime.valid_begin.substring(0, 10)).format('dddd'),
        }))
    }

    const createPopupHtml = (products) => {
        let productsHtml = ''

        for (const productName in products) {
            const product = products[productName]
            productsHtml += `
                <div>
                    ${productName}:
                    <span class="custom-popup-text">${product}</span>
                </div>
            `
        }

        const headerHtml = `
            <div>
                ${products.Temperature} ${products.Name}
            </div>
        `

        return `
            <div data-cy="pin-point-tool-popup">
                ${headerHtml}
                <br/>
                ${productsHtml}
                <br/>
                <button class="forecastButton">
                    7-Day Forecast
                </button>
            </div>
        `
    }

    const createForecastModal = (data) => `
            <div class="modal">
                <div class="modal_inner">
                    <div class="modal_header">7 Day Forecast</div>
                    <div class="modal_content">
                        ${data.map((oneDay) => `
                                <div class="block_wrapper">
                                    <div class="block_header">
                                        <div class="block_line date">${oneDay.date}</div>
                                        <img src="/forecastIcons/${oneDay.weatherCodeValue}.svg" class="block_line forecast_image" alt="forecast_icon">
                                    </div>                                    
                                    <div class="block_temps">
                                        <div class="block_line day_temp">${oneDay.dayTemp}</div>
                                        <div class="block_line night_temp">${oneDay.nightTemp || '--'}</div>
                                    </div>  
                                    <div class="block_precipitation">                          
                                        <div class="block_line precipitation_percent">${oneDay.precipitation}%</div>
                                        <div class="block_line weatherCode">${oneDay.weatherCode}</div>
                                    </div>  
                                </div>`).join('')}
                    </div>
                </div>
            </div>
        `

    const updateFullscreenState = () => {
        return Boolean(document.fullscreenElement)
    }

    const showForecastModal = (html) => {
        const isFullscreenMap = updateFullscreenState()
        const mapContainer = window.map?.getContainer() // map container for fullscreen mode
        if (!mapContainer) {
            console.error('Map container not found!')
            return
        }

        const modal = document.createElement('div')
        modal.classList.add('modal_wrapper')
        modal.innerHTML = html

        const container = isFullscreenMap ? mapContainer : document.body // create modal in map container for fullscreen mode or in body
        container.appendChild(modal)

        const closeModal = (event) => {
            if (event.target === modal) {
                modal.remove()
                modal.removeEventListener('click', closeModal)
                document.removeEventListener('fullscreenchange', closeModal)
                document.removeEventListener('webkitfullscreenchange', closeModal)
            }
        }

        modal.addEventListener('click', closeModal)
        document.addEventListener('fullscreenchange', updateFullscreenState)
        document.addEventListener('webkitfullscreenchange', updateFullscreenState)
    }

    const clearAll = () => {
        removePinPoint()
    }

    const removePinPoint = () => {
        pinPoint.current?.remove()
        pinPoint.current = null
    }

    return (
        <IconButton
            data-cy={'pin-point-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={'small'}
            className={`productpqswitcher ${isActive ? 'active' : ''}`}
        >
            <MapMarkerIcon size={'small'}/>
        </IconButton>
    )
}

export const formatProducts = (data, city) => {
    // TODO: refactor this ugly code
    let feelsLike
    if (!isNaN(data.temperature?.wind_chill)) {
        feelsLike = data.temperature?.wind_chill
    } else {
        (!isNaN(data.temperature?.heat_index)) && (feelsLike = data.temperature?.heat_index)
    }
    feelsLike = feelsLike || data.temperature?.value

    return {
        Name: getCityName(city),
        Temperature: TextProductsConverter.temperature(data.temperature?.value),
        'Feels like': TextProductsConverter.temperature(feelsLike),
        'Dew Point': TextProductsConverter.temperature(data.temperature?.dew_point),
        Wind: TextProductsConverter.wind(data.wind),
        'Sky Conditions': getSkyConditionText(data),
        Visibility: getVisibilityText(data.visibility),
    }
}

export const validateProducts = (products) => {
    const validatedProducts = {}
    for (const key in products) {
        const product = products[key]
        if (product === null || product === undefined) {
            continue
        } else if (product === '') {
            validatedProducts[key] = 'Unknown'
        } else {
            validatedProducts[key] = product
        }
    }

    return validatedProducts
}

const getCityName = (data) => {
    if (!data?.city) {
        return ''
    }

    const city = data.city
    const country = data.country?.name ?? ''
    const countryCode = data.country?.code ?? ''
    const region = data.region?.name ?? ''

    return city + ', ' + ((countryCode === 'US') ? region : country)
}

const getSkyConditionText = (data) => {
    const isValidSkyCondition = function(t) {
        return (t !== 'Clear' && t !== 'Cloudy' && t !== 'Partly Cloudy' && t !== 'Mostly Clear')
    }
    let skyCondition
    if (data.weather_code !== undefined && data.weather_code.text !== undefined && isValidSkyCondition(data.weather_code.text)) {
        skyCondition = data.weather_code.text
    } else {
        const cloudCoverValue = (data.cloud_cover !== undefined && data.cloud_cover.value !== undefined) ? data.cloud_cover.value : undefined
        if (cloudCoverValue === undefined) {
            skyCondition = null
        } else {
            if (cloudCoverValue < 25) skyCondition = 'Clear'
            else if (cloudCoverValue < 50) skyCondition = 'Few Clouds'
            else if (cloudCoverValue < 75) skyCondition = 'Scattered Clouds'
            else if (cloudCoverValue < 100) skyCondition = 'Broken Clouds'
            else skyCondition = 'Overcast'
        }
    }
    return skyCondition
}
const getVisibilityText = (v) => {
    if (!v) return null
    if (v.units === '>m') return baron.isMetricalUnits ? '>9 km' : '>6 mi'
    return TextProductsConverter.range(v.value)
}
