import React, {useEffect, useState} from 'react'
import styles from './CreateWidgetPage.module.scss'
import {FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, Typography} from '@mui/material'
import ArrowLeftSmallIcon from '../../../shared/assets/icons/ArrowLeftSmall'
import {Spacer} from '../../../shared/ui/Spacer'
import Button from '@mui/material/Button'
import {useDashboardStore} from '../../../app/store/DashboardStore'
import AgreeModal from '../../../shared/ui/AgreeModal'
import FormControl from '@mui/material/FormControl'
import {SearchInput} from '../../../shared/ui/SearchInput'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import {clsx} from 'clsx'

const CreateWidgetPage = ({currentView}) => {
    const {
        editingDashboardWidget,
        isDuplicatingWidget,
        createWidget,
        editWidget,
        toggleEditingDashboardWidget,
        toggleDuplicationWidget,
    } = useDashboardStore((state) => state)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const [showLeaveModal, setShowLeaveModal] = useState(false)
    const [typeOfWidget, setTypeOfWidget] = useState(editingDashboardWidget.type || "Table")
    const [chosenLocation, setChosenLocation] = useState(editingDashboardWidget.location || null)
    const [chosenUnits, setChosenUnits] = useState(editingDashboardWidget.options?.chosenUnits || "metric") //metric or imperial

    const [locationFilter, setLocationFilter] = useState(null)
    const [isOpenSearching, setIsOpenSearching] = useState(false)

    useEffect(() => {
        fetchLocations()
    }, [])

    const handleClose = (agreed) => {
        if (agreed) {
            toggleEditingDashboardWidget(null)
            toggleDuplicationWidget(false)
        }
        setShowLeaveModal(false)
    }

    const handleTypeOfWidgetChange = (event) => {
        setTypeOfWidget(event.target.value)
    }

    const handleLocationFilter = (event) => {
        setLocationFilter(event.target.value)
    }

    const handleChangeChosenLocation = (loc) => {
        setChosenLocation(loc)
        setIsOpenSearching(false)
        setLocationFilter(loc.label)
    }

    const handleSaveWidget = () =>{
        if(!editingDashboardWidget) return

        if(isDuplicatingWidget){
            createWidget(typeOfWidget, currentView.id, chosenLocation.id, chosenUnits)
        }
        else {
            if(editingDashboardWidget.id){
                editWidget(editingDashboardWidget.id, typeOfWidget, currentView.id, chosenLocation.id, chosenUnits)
            }
            else{
                createWidget(typeOfWidget, currentView.id, chosenLocation.id, chosenUnits)
            }
        }
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <IconButton
                        onClick={() => setShowLeaveModal(true)}
                        variant={'outlined'}
                        size={'small'}
                    >
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3>
                        {(isDuplicatingWidget || !editingDashboardWidget.id) ? "Add" : "Edit"} widget
                    </h3>
                    <Spacer/>
                    <Button
                        disabled={!typeOfWidget || !chosenLocation}
                        onClick={handleSaveWidget}
                    >
                        Save widget
                    </Button>
                </div>

                <div className={styles.content}>
                    <div className={styles.contentItem}>
                        <div className={styles.title}>
Type of widgets
                        </div>
                        <FormControl>
                            <RadioGroup
                                value={typeOfWidget}
                                onChange={handleTypeOfWidgetChange}
                            >
                                <FormControlLabel
                                    value="Table"
                                    control={<Radio size={'small'}/>}
                                    label="Table"
                                    className={styles.radioTitle}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={styles.contentItem}>
                        <div className={styles.title}>
Widget parameters
                        </div>
                        <div className={styles.selector}>
                            <Typography className={styles.subTitle}>
                                Location
                            </Typography>
                            <SearchInput
                                defaultValue={chosenLocation?.label}
                                value={locationFilter}
                                className={styles.input}
                                placeholder='Select location'
                                onChange={handleLocationFilter}
                                onClick={() => setIsOpenSearching(!isOpenSearching)}
                            />
                            <div
                                className={clsx(styles.listWrapper, {
                                    productsVisible: isOpenSearching,
                                    productsInvisible: !isOpenSearching,
                                    [styles.isOpenSearching]: isOpenSearching,
                                })}
                            >
                                <Typography className={styles.listTitle}>
                                    {' '}
                                    Select an option
                                    {' '}
                                </Typography>

                                {locations && locations.map((loc) => {
                                    {
                                        if (loc.label.toLocaleLowerCase().indexOf(locationFilter) >= 0 || !locationFilter) {
                                            return (
                                                <div
                                                    key={loc.id}
                                                    className={styles.locItem}
                                                    onClick={() => handleChangeChosenLocation(loc)}
                                                >
                                                    {loc.label}
                                                </div>
                                            )
                                        }
                                    }
                                    return null
                                })}
                            </div>
                        </div>
                        <div className={styles.unitsWrapper}>
                            <Typography className={styles.subTitle}>
                                Select a measurement system
                            </Typography>
                            <FormControl>
                                <RadioGroup
                                    value={chosenUnits}
                                    onChange={(e) => setChosenUnits(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="metric"
                                        control={<Radio size={'small'}/>}
                                        label="Metric"
                                        className={styles.radioTitle}
                                    />
                                    <FormControlLabel
                                        value="imperial"
                                        control={<Radio size={'small'}/>}
                                        label="Imperial"
                                        className={styles.radioTitle}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>

            {showLeaveModal && <AgreeModal
                data={{
                    agreeFunc: handleClose,
                    message: 'Are you sure you want discard your changes?',
                    title: 'Discard widget',
                    agreeMsg: 'Discard',
                    disAgreeMsg: 'Go back',
                }}
            />}
        </>
    )
}

export default CreateWidgetPage
